import { Col, Row, Input, Select, Divider, Space } from "antd";
import React, { useEffect, useState } from "react";
import { getAllRecords } from "../../../../db/dexie-db/functions";
import { PAYMENT_TYPES } from "../../_CONSTANTS/constants";

export default function InfoTab(props) {
  const [suppliers, setSuppliers] = useState([]);

  let payTypes = [];
  for (const [key, value] of Object.entries(PAYMENT_TYPES)) {
    payTypes.push({ value, label: value });
  }

  useEffect(() => {
    getData();
  }, []);

  const onChangePayType = (value) => {
    props.setPayType(value);
  };

  const onChange = (value) => {
    props.setSupplierGuid(value);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const getData = async () => {
    const suppliersFromDb = await getAllRecords("Suppliers");
    const suppliersToSelect = getSuppliersForSelect(suppliersFromDb);
    setSuppliers(suppliersToSelect);
  };

  const getSuppliersForSelect = (suppliersFromDb) => {
    return suppliersFromDb.map((supplier) => {
      return { value: supplier.guid, label: supplier.title };
    });
  };

  return (
    <>
      <Row style={{ padding: "20px" }}>
        <Col offset={2} span={20}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Select
              style={{ width: "100%" }}
              size={"large"}
              showSearch
              placeholder="Виберіть клієнта"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={suppliers}
              value={props?.supplierGuid}
              disabled={props?.disabledFields}
            />
            <Select
              style={{ width: "100%" }}
              size={"large"}
              showSearch
              placeholder="Виберіть вид оплати"
              optionFilterProp="children"
              onChange={onChangePayType}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={payTypes}
              value={props?.payType}
              disabled={props?.disabledFields}
            />
          </Space>
          <Divider style={{ margin: "10px" }}></Divider>
          <Input
            style={{ width: "100%" }}
            size={"large"}
            placeholder="Коментар"
            value={props?.comment}
            disabled={props?.disabledFields}
            onChange={(value) => {
              props.setComment(value.target.value);
            }}
          ></Input>
        </Col>
      </Row>
    </>
  );
}
