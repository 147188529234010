import { message, notification } from "antd";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect, useMemo } from "react";
import Footer from "../../components/documents/documents-verification/verification/Footer";
import ModalProduct from "../../components/documents/documents-verification/verification/ModalProduct";
import Main from "../../components/documents/documents-verification/verification/Main";
import ModalBarcodeCameraReader from "../../components/documents/documents-verification/ModalBarcodeCameraReader";
import {
  addRecord,
  bulkPutDataToObjectStore,
  getAllRecords,
} from "../../db/dexie-db/functions";
import {
  getDocumentProductsWithoutDeleted,
  getRecordsForTable,
} from "../../db/documents-funtions";

import BottomPageTitle from "../../components/header/BottomPageTitle";
import { db } from "../../db/dexie-db/db";
import { getUserSetting } from "../../api/settings/settings";
import { useAuth } from "../../hook/useAuth";
import { TABLES } from "../../db/constants/tables";
import {
  searchProductInDb,
  searchProductInDbByTitle,
  updateIndexesInArray,
} from "../../db/products-functions";
import {
  DOCUMENTS_TITLES,
  DOCUMENT_OPEN_ACTIONS,
} from "../../components/documents/_CONSTANTS/constants";
import { servicesSettingsTitles } from "../../components/documents/_CONSTANTS/settings";
import { listenToBackgroundEnteredBarcode } from "../../utils/eventListeners";

const DOCUMENT_ACTION = DOCUMENT_OPEN_ACTIONS.CREATE;
const CURRENT_DOCUMENT_TABLE = TABLES.CURRENT_VERIFICATION;
let backgroundEnteredBarcode = "";

export default function Verification() {
  const { user } = useAuth();

  const digitalKeyboard = getUserSetting("digitalKeyboard", user.settings);
  const sounds = getUserSetting("sound", user.settings);
  const colorDifferents = getUserSetting("color_differents", user.settings);
  const additionalColorDifferents = getUserSetting(
    "additionalHighlightDifferents",
    user.settings
  );
  const showBarcodesInTable = getUserSetting(
    "showBarcodesInTable",
    user.settings,
    "verification"
  );
  const autoAddProduct = getUserSetting(
    "autoAddProduct",
    user.settings,
    servicesSettingsTitles.VERIFICATION
  );
  const forbidExceedQuantity = getUserSetting(
    "forbidExceedQuantity",
    user.settings,
    servicesSettingsTitles.VERIFICATION
  );
  const revisionSettings = {
    digitalKeyboard,
    sounds,
    colorDifferents,
    additionalColorDifferents,
    showBarcodesInTable,
    autoAddProduct,
    forbidExceedQuantity,
  };

  const location = useLocation();
  const docOrder = location?.state?.document;

  const [isModalProductOpen, setIsModalProductOpen] = useState(false);
  const [isModalCameraBarcodeReaderOpen, setIsModalCameraBarcodeReaderOpen] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [settings, setSettings] = useState(revisionSettings);

  const [productsFromSource] = useState(
    docOrder ? JSON.parse(JSON.stringify(docOrder.products)) : ""
  );
  const [backgroundEnteredBarcodeValue, setBackgroundEnteredBarcodeValue] =
    useState("");

  useEffect(() => {
    document.addEventListener("keydown", detectKeyDownEvent);
    return () => {
      document.removeEventListener("keydown", detectKeyDownEvent);
    };
  }, [isModalProductOpen]);

  useEffect(() => {
    getData();
  }, []);

  const searchProduct = async (enteredData) => {
    if (!Number(enteredData)) {
      const products = await searchProductInDbByTitle(enteredData);
      setLoading(false);
      if (!products.length) {
        message.error("Товари не знайдені");
        return false;
      }

      return products;
    }

    const product = await searchProductInDb(enteredData);
    if (!product) {
      message.error("Товар " + enteredData + " не знайдено!");
      return false;
    }
    return product;
  };

  const addProductToDocument = async (product, products) => {
    const existingProduct = products.find(
      (existingProduct) => product.guid === existingProduct.guid
    );

    if (existingProduct) {
      const existingProductQty = !existingProduct.qty
        ? 0
        : +existingProduct.qty;

      const newQty = +existingProductQty + +product.qty;

      // перевірка на заборону перевищувати к-ть
      if (settings?.forbidExceedQuantity) {
        if (newQty > existingProduct.qtyFromSource) {
          notification.error({
            description: `${product.title}`,
            message: `Помилка, кількість перевищує допустиму!`,
            duration: 3,
            placement: "top",
            closeIcon: false,
          });
          return false;
        }
      }

      existingProduct.qty = newQty;

      const arrayWithoutExistingProduct = updateIndexesInArray(
        products,
        existingProduct
      );

      existingProduct.index = arrayWithoutExistingProduct.length + 1;
      existingProduct.key = arrayWithoutExistingProduct.length + 1;

      const newProducts = [...arrayWithoutExistingProduct, existingProduct];

      const updated = await bulkPutDataToObjectStore(
        TABLES.CURRENT_VERIFICATION,
        newProducts
      );
      if (!updated) {
        message.error("Помилка оновлення даних в таблиці");
        return false;
      }
      return newProducts;
    }

    if (settings?.forbidExceedQuantity) {
      notification.error({
        description: `${product.title}`,
        message: `Помилка, кількість перевищує допустиму!`,
        duration: 3,
        placement: "top",
        closeIcon: false,
      });
      return false;
    }

    const existingProducts = JSON.parse(JSON.stringify(products));
    const newProduct = { ...product };
    newProduct.key = products.length + 1;
    newProduct.index = newProduct.key;
    await addRecord(TABLES.CURRENT_VERIFICATION, newProduct);
    const newProducts = [...existingProducts, newProduct];
    return newProducts;
  };

  const detectKeyDownEvent = (e) => {
    if (isModalProductOpen) return;
    backgroundEnteredBarcode = listenToBackgroundEnteredBarcode(
      e,
      setBackgroundEnteredBarcodeValue,
      backgroundEnteredBarcode
    );
  };

  const setDataFromSource = async () => {
    const productsFromSourceWithoutQty = productsFromSource.map((prod) => ({
      ...prod,
      qty: !prod?.qty ? 0 : prod?.qty,
      // qtyFromSource: prod.qty,
    }));
    setProducts(getRecordsForTable(productsFromSourceWithoutQty));
    await db.CurrentVerification.bulkPut(productsFromSourceWithoutQty);
  };

  const getData = async () => {
    const products = await getCurrentDocProducts();
    if (products.length > 0) {
      setProducts(products);
      return setLoading(false);
    }

    setDataFromSource();
    return setLoading(false);
  };

  const getCurrentDocProducts = async () => {
    try {
      return await getAllRecords(TABLES.CURRENT_VERIFICATION);
    } catch (error) {
      message.error("Помилка отримання даних з поточного замовлення");
      throw error;
    }
  };

  const removeProductFromDocument = async (product) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      product,
      CURRENT_DOCUMENT_TABLE,
      DOCUMENT_ACTION
    );
    setProducts(arrayWithoutDeletedProduct);
  };

  const afterChangeProductQty = async () => {
    const newProducts = await getAllRecords(CURRENT_DOCUMENT_TABLE);
    console.log(newProducts);
    setProducts(newProducts);
  };

  const FooterMemo = useMemo(() => {
    return (
      <Footer
        setIsModalProductOpen={setIsModalProductOpen}
        setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
        settings={settings}
        setSettings={setSettings}
      ></Footer>
    );
  }, [settings]);

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        document={docOrder}
        action={DOCUMENT_OPEN_ACTIONS.VERIFY}
        settings={settings}
        afterChangeProductQty={afterChangeProductQty}
        removeProductFromDocument={removeProductFromDocument}
        dbTable={CURRENT_DOCUMENT_TABLE}
      ></Main>
      {(isModalProductOpen || backgroundEnteredBarcodeValue) && (
        <ModalProduct
          backgroundEnteredBarcode={backgroundEnteredBarcodeValue}
          setBackgroundEnteredBarcodeValue={setBackgroundEnteredBarcodeValue}
          products={products}
          setProducts={setProducts}
          isModalProductOpen={isModalProductOpen}
          setIsModalProductOpen={setIsModalProductOpen}
          settings={settings}
          serviceTitle={DOCUMENTS_TITLES.VERIFICATION}
          searchProduct={searchProduct}
          addProductToDocument={addProductToDocument}
          dbTable={CURRENT_DOCUMENT_TABLE}
        ></ModalProduct>
      )}
      {isModalCameraBarcodeReaderOpen && (
        <ModalBarcodeCameraReader
          action={DOCUMENT_OPEN_ACTIONS.CREATE}
          products={products}
          setProducts={setProducts}
          addProductToDocument={addProductToDocument}
          isModalCameraBarcodeReaderOpen={isModalCameraBarcodeReaderOpen}
          focusInputQty={true}
          setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
          serviceTitle={DOCUMENTS_TITLES.VERIFICATION}
          settings={settings}
          dbTable={CURRENT_DOCUMENT_TABLE}
        ></ModalBarcodeCameraReader>
      )}
      {FooterMemo}
      <BottomPageTitle title={"Перевірка"}></BottomPageTitle>
    </>
  );
}
