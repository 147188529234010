import React from "react";
import MenuComponent from "../menu/Menu";
import Data from "../menu/Menu";
import BackButton from "../../../UI/BackButton";

function InvoiceFooter(props) {
  const dataMenu = Data;
  return (
    <>
      <MenuComponent
        data={dataMenu}
        settings={props.settings}
        setSettings={props.setSettings}
      ></MenuComponent>
      <BackButton></BackButton>
    </>
  );
}
export default InvoiceFooter;
