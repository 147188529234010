import { Button, Drawer, Space, Tabs } from "antd";
import { useState } from "react";
import { CloseCircleFilled, SettingOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import React from "react";

export default function Menu(props) {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const items = [
    {
      key: "1",
      label: (
        <span>
          <SettingOutlined /> Налаштування
        </span>
      ),
      children: props.settingsTab,
    },
  ];

  const operations = (
    <Button onClick={onClose}>
      <CloseCircleFilled />
    </Button>
  );

  return (
    <>
      <FloatButton
        icon={<SettingOutlined />}
        type="primary"
        style={{
          left: 24,
          bottom: 100,
        }}
        onClick={showDrawer}
      />
      <Drawer
        title={
          <Space>
            <Button onClick={onClose}>Закрити</Button>
          </Space>
        }
        placement={"top"}
        height={"60%"}
        onClose={onClose}
        closable={false}
        open={open}
        styles={{ body: { padding: "5px 10px" }, header: { display: "none" } }}
      >
        <Tabs
          defaultActiveKey="1"
          tabBarExtraContent={operations}
          items={props?.menuItems ? [...items, ...props?.menuItems] : items}
        />
      </Drawer>
    </>
  );
}
