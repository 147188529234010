import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { OrderedListOutlined } from "@ant-design/icons";
import ExportModal from "../../components/export/ExportModal";
import Footer from "../../components/documents/barcoding/list/Footer";
import TableDocuments from "../../components/documents/barcoding/list/TableDocuments";
import {
  getDocumentsBarcodingForExport,
  setDocumentsToTable,
} from "../../db/documents-funtions";
import { TABLES } from "../../db/constants/tables";
import { useAuth } from "../../hook/useAuth";
import DeleteDocumentsComponent from "../../components/documents/manage-documents/documents-header/DeleteDocumentsComponent";
import ExportDocumentsComponent from "../../components/documents/manage-documents/documents-header/ExportDocumentsComponent";

const DOCUMENT_TABLE = TABLES.BARCODING;

export default function Barcoding() {
  const { user } = useAuth();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingExportButton, setLoadingExportButton] = useState(false);
  const [isModalExportOpen, setIsModalExportOpen] = useState(false);
  const [documentsToExport, setDocumentsToExport] = useState([]);
  const [documentsToExportOriginal, setDocumentsToExportOriginal] = useState(
    []
  );

  useEffect(() => {
    setDocumentsToTable(
      setLoading,
      DOCUMENT_TABLE,
      user.settings,
      setDocuments,
      getFormattedDocuments
    );
  }, []);

  async function getFormattedDocuments(documents) {
    return await Promise.all(
      documents.map(async (doc, index) => {
        doc.key = index + 1;
        doc.index = index + 1;
        doc.rowsCount = doc.products.length;
        return doc;
      })
    );
  }

  const OperationsSlot = {
    left: (
      <DeleteDocumentsComponent
        dbTable={DOCUMENT_TABLE}
        documents={documents}
        getFormattedDocuments={getFormattedDocuments}
        setDocuments={setDocuments}
      ></DeleteDocumentsComponent>
    ),
    right: (
      <ExportDocumentsComponent
        dbTable={DOCUMENT_TABLE}
        setDocumentsToExport={setDocumentsToExport}
        setDocumentsToExportOriginal={setDocumentsToExportOriginal}
        setIsModalExportOpen={setIsModalExportOpen}
        getDocumentsForExport={getDocumentsBarcodingForExport}
        setLoadingExportButton={setLoadingExportButton}
        loadingExportButton={loadingExportButton}
      ></ExportDocumentsComponent>
    ),
  };

  const deftabs = [
    {
      label: (
        <span>
          <OrderedListOutlined /> Штрих-кодування
        </span>
      ),
      key: "1",
      children: <></>,
    },
  ];

  return (
    <>
      <Tabs
        tabBarStyle={{ marginBottom: "5px" }}
        centered
        tabBarExtraContent={OperationsSlot}
        defaultActiveKey="1"
        size={"large"}
        items={deftabs}
      />
      <TableDocuments
        loading={loading}
        documents={documents}
        setDocuments={setDocuments}
      ></TableDocuments>
      <ExportModal
        isModalExportOpen={isModalExportOpen}
        setIsModalExportOpen={setIsModalExportOpen}
        docTypeData={{ title: "Штрихкодування", value: "barcoding" }}
        documentsToExport={documentsToExport}
        setLoadingExportButton={setLoadingExportButton}
        documentsToExportOriginal={documentsToExportOriginal}
        DOCUMENT_TABLE={DOCUMENT_TABLE}
        setDocuments={setDocuments}
        getFormattedDocuments={getFormattedDocuments}
        documents={documents}
      ></ExportModal>
      <Footer></Footer>
    </>
  );
}
