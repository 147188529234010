import React, { useState, useEffect } from "react";
import CheckPriceFooter from "../../components/documents/check-price/check-price-create/CheckPriceFooter";
import Main from "../../components/documents/check-price/check-price-create/Main";
import ModalBarcodeCameraReader from "../../components/camera-barcode-reader/ModalBarcodeCameraReader";
import { getAllRecords } from "../../db/dexie-db/functions";
import {
  getDocumentProductsWithoutDeleted,
  getRecordsForTable,
} from "../../db/documents-funtions";
import { setNewProductToTable } from "../../db/products-functions";
import { TABLES } from "../../db/constants/tables";
import {
  DOCUMENTS_TITLES,
  DOCUMENT_OPEN_ACTIONS,
} from "../../components/documents/_CONSTANTS/constants";
import ModalEnterProducts from "../../components/documents/manage-documents/modal-enter-product/ModalEnterProducts";
import ModalEnterProductBodyAddon from "../../components/documents/check-price/ModalEnterProductBodyAddon";
import ModalEnterProductFooterAddon from "../../components/documents/check-price/ModalEnterProductFooterAddon";
import BottomPageTitle from "../../components/header/BottomPageTitle";
import { getServiceSettingsObject } from "../../api/settings/settings";
import { useAuth } from "../../hook/useAuth";
import { servicesSettingsTitles } from "../../components/documents/_CONSTANTS/settings";
import {
  isAnyModalOpen,
  listenToBackgroundEnteredBarcode,
} from "../../utils/eventListeners";
import { getCheckPriceColumns } from "../../components/documents/manage-documents/table-components/documents-columns";
import { getProductsToTable } from "../../components/documents/manage-documents/modal-enter-product/enter-products-functions";

const DOCUMENT_TITLE = DOCUMENTS_TITLES.CHECK_PRICE;
const DOCUMENT_ACTION = DOCUMENT_OPEN_ACTIONS.CREATE;
const CURRENT_DOCUMENT_TABLE = TABLES.CURRENT_CHECK_PRICE;

let backgroundEnteredBarcode = "";

export default function CheckPriceCreate() {
  const { user } = useAuth();
  const checkPriceSettings = getServiceSettingsObject(
    user.settings,
    servicesSettingsTitles.CHECK_PRICE
  );

  const [isModalProductOpen, setIsModalProductOpen] = useState(false);
  const [isModalCameraBarcodeReaderOpen, setIsModalCameraBarcodeReaderOpen] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [lastEnteredProducts, setLastEnteredProducts] = useState([]);
  const [settings, setSettings] = useState(checkPriceSettings);

  const [backgroundEnteredBarcodeValue, setBackgroundEnteredBarcodeValue] =
    useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const products = await getAllRecords(CURRENT_DOCUMENT_TABLE);
    const productsToTable = getProductsToTable(products);
    setProducts(getRecordsForTable(productsToTable));
    setLoading(false);
  };

  useEffect(() => {
    document.addEventListener("keydown", detectKeyDownEvent);
    return () => {
      document.removeEventListener("keydown", detectKeyDownEvent);
    };
  }, []);

  const detectKeyDownEvent = (e) => {
    if (isAnyModalOpen()) return;
    backgroundEnteredBarcode = listenToBackgroundEnteredBarcode(
      e,
      setBackgroundEnteredBarcodeValue,
      backgroundEnteredBarcode
    );
  };

  const removeProductFromDocument = async (product) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      product,
      CURRENT_DOCUMENT_TABLE,
      DOCUMENT_ACTION
    );
    setProducts(arrayWithoutDeletedProduct);
  };

  const checkPriceColumns = getCheckPriceColumns(checkPriceSettings);

  const afterChangeProductQty = () => {
    getData();
  };
  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_ACTION}
        afterChangeProductQty={afterChangeProductQty}
        removeProductFromDocument={removeProductFromDocument}
        settings={settings}
        columns={checkPriceColumns}
        dbTable={CURRENT_DOCUMENT_TABLE}
      ></Main>
      {(isModalProductOpen || backgroundEnteredBarcodeValue) && (
        <ModalEnterProducts
          setBackgroundEnteredBarcodeValue={setBackgroundEnteredBarcodeValue}
          backgroundEnteredBarcode={backgroundEnteredBarcodeValue}
          action={DOCUMENT_ACTION}
          dbTable={CURRENT_DOCUMENT_TABLE}
          products={products}
          setProducts={setProducts}
          lastEnteredProducts={lastEnteredProducts}
          isModalProductOpen={isModalProductOpen}
          setIsModalProductOpen={setIsModalProductOpen}
          setLastEnteredProducts={setLastEnteredProducts}
          afterChangeProductQty={afterChangeProductQty}
          serviceTitle={DOCUMENT_TITLE}
          BodyAddon={ModalEnterProductBodyAddon}
          FooterAddon={ModalEnterProductFooterAddon}
          columns={checkPriceColumns}
          removeProductFromDocument={removeProductFromDocument}
        ></ModalEnterProducts>
      )}
      {isModalCameraBarcodeReaderOpen && (
        <ModalBarcodeCameraReader
          action={DOCUMENT_ACTION}
          dbTable={CURRENT_DOCUMENT_TABLE}
          products={products}
          setProducts={setProducts}
          setNewProductToTable={setNewProductToTable}
          isModalCameraBarcodeReaderOpen={isModalCameraBarcodeReaderOpen}
          setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
          focusInputQty={false}
          serviceTitle={DOCUMENT_TITLE}
        ></ModalBarcodeCameraReader>
      )}
      <CheckPriceFooter
        setIsModalProductOpen={setIsModalProductOpen}
        setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
        settings={settings}
        setSettings={setSettings}
      ></CheckPriceFooter>
      <BottomPageTitle title={DOCUMENT_TITLE}></BottomPageTitle>
    </>
  );
}
