import React, { useMemo, useState } from "react";
import { Table } from "antd";
import { getTableTotals } from "../../../../utils/getTableTotals";
import { TableTotals } from "../../../table-utils/TableTotals";
import { useAuth } from "../../../../hook/useAuth";
import { getUserSetting } from "../../../../api/settings/settings";
import ModalEditProductQty from "../../manage-documents/ModalEditProductQty";
import {
  CHARACTERISTICS_ACTION,
  DOCUMENT_OPEN_ACTIONS,
} from "../../_CONSTANTS/constants";
import { useTheme } from "../../../../hook/useTheme";
import ModalEnterCharacteristics from "../../manage-documents/modal-enter-characteristics/ModalEnterCharacteristics";
import ExpandTable from "../../manage-documents/table-components/ExpandTable";
import ModalEnterExpirationDate from "../../manage-documents/modal-enter-expiration-date/ModalEnterExpirationDate";

export default function TableProducts(props) {
  const { user } = useAuth();
  const { useDarkMode } = useTheme();
  const redColumn = useDarkMode ? "red-column-dark" : "red-column";
  const blueColumn = useDarkMode ? "blue-column-dark" : "blue-column";
  const greenColumn = useDarkMode ? "green-column-dark" : "green-column";

  const [isModalChangeProductQtyOpen, setIsModalChangeProductQtyOpen] =
    useState(false);

  const [productToChangeQty, setProductToChangeQty] = useState();

  const [isModalEnterCharacteristicsOpen, setIsModalEnterCharacteristicsOpen] =
    useState(false);
  const [isModalEnterExpirationDateOpen, setIsModalEnterExpirationDateOpen] =
    useState(false);

  const useCharacteristics = getUserSetting(
    "useCharacteristics",
    user.settings,
    "global"
  );

  const showPriceColumn = props?.columns.find((col) => {
    return col.dataIndex === "price";
  });

  const useEnteringExpirationDates = getUserSetting(
    "useEnteringExpirationDates",
    user.settings,
    "global"
  );

  const showSumByDiff = getUserSetting("showSumByDiff", user.settings);

  const onDoubleClickHandler = (record) => {
    setProductToChangeQty(record);

    if (useEnteringExpirationDates && useCharacteristics) {
      return setIsModalEnterExpirationDateOpen(true);
    }

    if (useCharacteristics && record?.characteristics)
      return setIsModalEnterCharacteristicsOpen(true);

    setIsModalChangeProductQtyOpen(true);
  };

  const expandable = useCharacteristics
    ? {
        showExpandColumn: false,
        expandRowByClick: true,
        defaultExpandAllRows: true,
        expandedRowRender: (record) => (
          <ExpandTable
            characteristics={record?.characteristics}
            showPriceColumn={showPriceColumn}
          ></ExpandTable>
        ),
        rowExpandable: (record) => record?.characteristics,
      }
    : {};

  const getRowClassName = (record) => {
    if (!props?.settings?.colorDifferents) return;

    if (props?.settings?.additionalColorDifferents) {
      if (+record.qty === 0) return redColumn;
      if (+record.qty - +record.stockQty > 0) return greenColumn;
      if (+record.qty - +record.stockQty < 0) return blueColumn;
    }

    if (+record.qty - +record.stockQty !== 0) {
      return redColumn;
    }
  };

  const title = useMemo(() => {
    const totals = getTableTotals(props?.products, showSumByDiff);
    return (
      <>
        <TableTotals
          quantity={totals.quantity.toFixed(3)}
          total={totals.total.toFixed(2)}
          productsCount={props?.products.length ? props?.products.length : 0}
        />
      </>
    );
  }, [props?.products]);

  const TableProductsMemo = useMemo(() => {
    return (
      <Table
        tableLayout="fixed"
        columns={props?.columns}
        onRow={(record) => {
          if (props?.action === DOCUMENT_OPEN_ACTIONS.VIEW) return;
          return {
            onDoubleClick: () => {
              onDoubleClickHandler(record);
            },
            onContextMenu: () => {
              onDoubleClickHandler(record);
            },
          };
        }}
        expandable={expandable}
        pagination={{
          disabled: false,
          position: ["bottomCenter"],
          hideOnSinglePage: true,
          showSizeChanger: false,
          size: "default",
          pageSize: props.settings.tablePageSize,
        }}
        rowClassName={(record) => getRowClassName(record)}
        dataSource={props?.products}
        size={"middle"}
        loading={props.loading}
        title={() => title}
      />
    );
  }, [props.settings, props.products, props.loading, props.columns]);

  return (
    <div>
      {TableProductsMemo}
      {isModalEnterCharacteristicsOpen && (
        <ModalEnterCharacteristics
          isModalEnterCharacteristicsOpen={isModalEnterCharacteristicsOpen}
          setIsModalEnterCharacteristicsOpen={
            setIsModalEnterCharacteristicsOpen
          }
          product={productToChangeQty}
          setIsModalProductOpen={props.setIsModalProductOpen}
          afterChangeProductQty={props.afterChangeProductQty}
          products={props.products}
          dbTable={props.dbTable}
          action={CHARACTERISTICS_ACTION.EDIT}
          removeProductFromDocument={props?.removeProductFromDocument}
        ></ModalEnterCharacteristics>
      )}
      {isModalEnterExpirationDateOpen && (
        <ModalEnterExpirationDate
          key="ModalEnterExpirationDate"
          setIsModalEnterExpirationDateOpen={setIsModalEnterExpirationDateOpen}
          isModalEnterExpirationDateOpen={isModalEnterExpirationDateOpen}
          product={productToChangeQty}
          setIsModalProductOpen={props.setIsModalProductOpen}
          products={props.products}
          action={CHARACTERISTICS_ACTION.EDIT}
          dbTable={props.dbTable}
          serviceTitle={props?.serviceTitle}
          removeProductFromDocument={props?.removeProductFromDocument}
        ></ModalEnterExpirationDate>
      )}
      {isModalChangeProductQtyOpen &&
        props?.action !== DOCUMENT_OPEN_ACTIONS.VIEW && (
          <ModalEditProductQty
            isModalChangeLastProductOpen={isModalChangeProductQtyOpen}
            setIsModalChangeLastProductOpen={setIsModalChangeProductQtyOpen}
            productToChangeQty={productToChangeQty}
            removeProductFromDocument={props?.removeProductFromDocument}
            settings={props.settings}
            dbTable={props.dbTable}
            afterChangeProductQty={props.afterChangeProductQty}
          ></ModalEditProductQty>
        )}
    </div>
  );
}
