import { message } from "antd";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Main from "../../components/documents/returns/view/Main";
import { getRecord } from "../../db/dexie-db/functions";
import { getRecordsForTable } from "../../db/documents-funtions";
import BackButton from "../../components/UI/BackButton";
import { DOCUMENT_OPEN_ACTIONS } from "../../components/documents/_CONSTANTS/constants";
import { TABLES } from "../../db/constants/tables";
import { getReturnsColumns } from "../../components/documents/manage-documents/table-components/documents-columns";

export default function ViewReturn() {
  const params = useParams();
  const id = params.id;
  const [document, setDocument] = useState();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const doc = await getRecord(TABLES.RETURNS, Number(id));
    if (!doc) {
      message.error("Помилка отримання даних документа");
    }
    const products = doc.products;
    setDocument(doc);
    setProducts(getRecordsForTable(products));
    setLoading(false);
  };
  const returnColumns = getReturnsColumns();

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_OPEN_ACTIONS.VIEW}
        document={document}
        columns={returnColumns}
      ></Main>
      <BackButton></BackButton>
    </>
  );
}
