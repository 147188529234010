import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { message, Modal, List, Col, Spin } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  FundViewOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import { exportDocument } from "../../../../api/Export/xml/export";
import {
  getDocBarcodingForExport,
  getRecordsForTable,
} from "../../../../db/documents-funtions";
import { useAuth } from "../../../../hook/useAuth";
import { deleteRecord, updateRecord } from "../../../../db/dexie-db/functions";
import { TABLES } from "../../../../db/constants/tables";

const CURRENT_DOCUMENT_TABLE = TABLES.BARCODING;

export const ModalActions = (props) => {
  const [isSpinning, setIsSpinning] = useState(false);

  const { user } = useAuth();
  const navigate = useNavigate();

  const document = props?.document;
  if (!document) return;

  const handleCancel = () => {
    props.setIsModalActionsOpen(false);
  };

  const handleMenuClick = async (e, record) => {
    switch (record.key) {
      case "export":
        setIsSpinning(true);
        // поділ на тип експорту

        const docForExport = await getDocBarcodingForExport(document);

        const result = await exportDocument(
          docForExport,
          { title: "Штрих-коди", value: "barcoding" },
          user
        );

        setIsSpinning(false);
        if (result.status === "failed") {
          return message.error("Помилка експорту документу. " + result.message);
        }
        message.success("Документ відправлено!");

        const newDoc = { ...document, exported: "true" };

        const updated = await updateRecord(
          CURRENT_DOCUMENT_TABLE,
          newDoc.id,
          newDoc
        );
        if (!updated) {
          message.error("Помилка оновлення статусу");
        }

        const newDocuments = props.documents.filter(
          (doc) => doc.id !== newDoc.id
        );
        newDoc.rowsCount = newDoc.products.length;
        // newDoc.key = newDocuments.length + 1;
        // newDoc.index = newDoc.key;
        props.setDocuments([...newDocuments, newDoc]);
        props.setIsModalActionsOpen(false);
        break;
      case "viewDocument":
        navigate(`view/${document.id}`, {
          state: { documentNumber: props.documentNumber },
        });
        break;
      case "editDocument":
        if (document.exported === "true") {
          Modal.confirm({
            title: "Документ вже віправлено, продовжити?",
            onCancel: () => {
              return;
            },
            onOk: () => {
              navigate(`edit/${document.id}`, {
                state: { documentNumber: props.documentNumber },
              });
            },
          });
        } else {
          navigate(`edit/${document.id}`, {
            state: { documentNumber: props.documentNumber },
          });
        }
        break;
      case "deleteDocument":
        Modal.confirm({
          title: "Видалити документ №" + props.documentNumber + "?",
          onCancel: () => {
            return;
          },
          onOk: async () => {
            const deleted = await deleteRecord(
              CURRENT_DOCUMENT_TABLE,
              document.id
            );
            if (!deleted) {
              return message.error("Помилка видалення документа");
            }
            const newDocuments = props.documents.filter(
              (doc) => doc.id !== document.id
            );
            props.setDocuments(getRecordsForTable(newDocuments));
            props.setIsModalActionsOpen(false);
            message.success("Документ видалено!");
            if (!newDocuments.length) {
              navigate("/");
            }
          },
        });

        break;
      default:
        break;
    }
  };

  const data = [
    {
      title: " Експортувати",
      key: "export",
      icon: <CloudUploadOutlined />,
    },
    {
      title: " Переглянути",
      key: "viewDocument",
      icon: <FundViewOutlined />,
    },
    {
      title: " Змінити",
      key: "editDocument",
      icon: <EditOutlined />,
    },
    {
      title: " Видалити",
      key: "deleteDocument",
      icon: <DeleteOutlined />,
    },
  ];

  return (
    <>
      <Modal
        title={"Дії з документом № " + props.documentNumber}
        open={props.isModalActionsOpen}
        footer={null}
        onCancel={handleCancel}
        closable={!isSpinning}
      >
        <Spin spinning={isSpinning}>
          <List
            itemLayout="horizontal"
            dataSource={data}
            size={"default"}
            renderItem={(item) => (
              <List.Item
                onClick={(e) => handleMenuClick(e, item)}
                style={{ paddingLeft: "0px" }}
              >
                <Col span={2}>{item.icon}</Col>
                <Col span={22}>{item.title}</Col>
              </List.Item>
            )}
          />
        </Spin>
      </Modal>
    </>
  );
};
export default ModalActions;
