import { Space, Switch, message } from "antd";
import React, { useState } from "react";
import {
  getUpdatedSettings,
  getUserSetting,
  updateUserSettInSql,
} from "../../api/settings/settings";
import { servicesSettingsTitles } from "../../components/documents/_CONSTANTS/settings";

export default function Orders({ user, updateCurrentUser }) {
  const useDefaultTableType = getUserSetting(
    "useDefaultTableType",
    user.settings,
    servicesSettingsTitles.ORDERS
  );

  const [useDefaultTableTypeState, setUseDefaultTableTypeState] =
    useState(useDefaultTableType);

  const onChangeUseDefaultTableType = async (value) => {
    const res = await updateUserSettInSql(
      user,
      "useDefaultTableType",
      value,
      servicesSettingsTitles.ORDERS
    );
    if (!res) {
      return message.error("Помилка оновлення налаштувань!");
    }
    message.success("Оновлено!");
    setUseDefaultTableTypeState(value);

    const newSettings = getUpdatedSettings(
      user.settings,
      "useDefaultTableType",
      value,
      servicesSettingsTitles.ORDERS
    );
    const updatedLoggedInUser = { ...user, settings: newSettings };

    updateCurrentUser(updatedLoggedInUser);
  };

  return (
    <>
      <Space direction="horizontal">
        <Switch
          checked={useDefaultTableTypeState}
          size="default"
          onChange={(value) => onChangeUseDefaultTableType(value)}
        />
        Стандартний вигляд журналу
      </Space>
    </>
  );
}
