import { getRecord } from "../../../../db/dexie-db/functions";

export const getProductCharacteristicsToTable = async (
  product,
  enteredData,
  dbTable,
  products
) => {
  // треба дописати кількість для вже введених характеристик, щоб цифри відображались у полях
  const characteristics = product.characteristics;
  if (!characteristics) return [];
  // Знайти одиночну характеристику по штрих-коду і записати її
  let characteristicsToTable = [];
  let existingProduct = {};
  if (dbTable) {
    existingProduct = await getRecord(dbTable, product.guid);
  } else {
    existingProduct = products.find((prod) => prod.guid === product.guid);
  }

  if (existingProduct?.characteristics) {
    characteristicsToTable = [
      ...characteristicsToTable,
      ...existingProduct.characteristics,
    ];
  }

  const singleCharacteristic = characteristics.find((char) => {
    const barcodes = char.barcodes;
    if (!barcodes) return false;
    const elIndex = barcodes.indexOf(enteredData);
    if (elIndex === -1) {
      return false;
    }
    return char;
  });

  if (!singleCharacteristic)
    return characteristicsToTable.length
      ? characteristicsToTable
      : characteristics;

  const singleCharacteristicInArray = characteristicsToTable.findIndex(
    (obj) => obj.guid === singleCharacteristic.guid
  );

  if (singleCharacteristicInArray === -1) {
    characteristicsToTable.push(singleCharacteristic);
  }

  return characteristicsToTable;
};

export const getProductCharacteristicsFromCurrentDocument = async (
  dbTable,
  product
) => {
  const existingProduct = await getRecord(dbTable, product.guid);
  if (existingProduct?.characteristics) {
    return existingProduct.characteristics;
  }
  return false;
};
