export const getOrderProductsQty = (products, orderProducts) => {
  const productsWithQtyFromSource = products.map((prod) => {
    const sourceProd = orderProducts.find((sourceProd) =>
      sourceProd.guid === prod.guid ? sourceProd.qty : 0
    );

    return {
      ...prod,
      qtyFromSource: sourceProd?.qty,
    };
  });

  return productsWithQtyFromSource.filter((product) => product?.qty !== 0);
};
