import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Table } from "antd";
import React, { useEffect, useState } from "react";

export default function TableEditingProductBarcodes(props) {
  const [tableDataSource, setTableDataSource] = useState([]);

  useEffect(() => {
    setTableDataSource(props?.productToChange?.newBarcodes);
  }, [props?.productToChange]);

  const defaultColumns = [
    {
      title: "Штрих-код",
      dataIndex: "barcode",
      width: "80%",
      render: (_, record) => {
        return record;
      },
    },
    {
      title: "Дія",
      dataIndex: "action",
      width: "20%",
      render: (_, record) => {
        return (
          <Popconfirm
            title={"Видалити позицію?"}
            onCancel={() => {
              return;
            }}
            onConfirm={async () => {
              const newTableDataSource = tableDataSource.filter(
                (barcode) => barcode !== record
              );

              const deleted = await props.deleteBarcodeHandler(
                props.productToChange,
                record
              );
              if (deleted) setTableDataSource(newTableDataSource);
            }}
          >
            <Button size="large">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        );
      },
    },
  ];
  return (
    <>
      <Table
        tableLayout="fixed"
        columns={defaultColumns}
        pagination={{
          position: ["bottomCenter"],
          size: 999,
          hideOnSinglePage: true,
        }}
        dataSource={tableDataSource}
        size={"middle"}
      />
    </>
  );
}
