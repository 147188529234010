import {
  Modal,
  Input,
  Button,
  Row,
  Col,
  Space,
  message,
  InputNumber,
  notification,
} from "antd";
import React, { useState, useEffect, useRef } from "react";
import { searchProductInDb } from "../../../db/products-functions";
import Scanner from "../../camera-barcode-reader/quagga/Scanner";
import ScannerDefault from "../../camera-barcode-reader/default/ScannerDefault";
const { Search } = Input;

export default function ModalBarcodeCameraReader(props) {
  const [loading, setLoading] = useState(false);
  const [barcode, setBarcode] = useState();
  const [barcodeForSearch, setBarcodeForSearch] = useState();
  const [isScanning, setIsScanning] = useState(true);
  const [currentProduct, setCurrentProduct] = useState();
  const inputQtyRef = useRef();
  const input1Ref = useRef();
  const [qtyInputValue, setQtyInputValue] = useState("");

  const checkIsWebApiSupport = () => {
    if (!("BarcodeDetector" in window)) {
      return false;
    } else {
      return true;
    }
  };

  const isWebApiSupported = checkIsWebApiSupport();

  useEffect(() => {
    if (props.isModalCameraBarcodeReaderOpen) {
      setIsScanning(true);
    }
  }, [props.isModalCameraBarcodeReaderOpen]);

  useEffect(() => {
    if (barcodeForSearch) {
      searchProduct(barcodeForSearch);
      setBarcodeForSearch(null);
    }
  }, [barcodeForSearch]);

  // const operationSystem = getMobileOperatingSystem();

  const closeBarcodeReader = async () => {
    setIsScanning(false);
    setCurrentProduct(undefined);
    props.setIsModalCameraBarcodeReaderOpen(false);
  };

  const searchProduct = async (barcode) => {
    setIsScanning(false);
    const product = await searchProductInDb(barcode);
    if (product) {
      const productFromCurrentDoc = props.products.find(
        (prod) => prod.guid === product.guid
      );

      if (!productFromCurrentDoc) {
        message.error("Даного товару немає в документі");
        setBarcode("");
        setIsScanning(true);
        return setLoading(false);
      }

      if (productFromCurrentDoc?.qtyFromSource) {
        product.qtyFromSource = productFromCurrentDoc.qtyFromSource;
      }

      if (props.settings.autoAddProduct) {
        const result = await props.addProductToDocument(
          { ...product, qty: 1 },
          props.products
        );
        if (result) {
          props.setProducts(result);
          notification.success({
            description: `${product.title}`,
            message: `Додано: 1`,
            duration: 3,
            placement: "bottom",
            closeIcon: false,
          });
        }

        setBarcode("");
        return setTimeout(() => {
          setIsScanning(true);
        }, 2000);
      }

      setCurrentProduct(product);
      setBarcodeForSearch("");

      if (props?.focusInputQty) inputQtyRef.current.focus();
      return setBarcode("");
    }

    Modal.info({
      title: "Товар " + barcode + " не знайдено, спробуйте ще раз",
      onOk: () => {
        setIsScanning(true);
        setBarcodeForSearch("");
        setBarcode("");
        // input1Ref.current.focus();
        return;
      },
      onCancel: () => {
        setIsScanning(true);
        setBarcodeForSearch("");
        setBarcode("");
        // input1Ref.current.focus();
        return;
      },
    });
  };

  const clickHandler = async () => {
    if (!currentProduct) {
      message.info("Проскануйте товар");
      return setIsScanning(true);
    }
    currentProduct.qty = inputQtyRef.current.value
      ? inputQtyRef.current.value
      : 1;

    const result = await props.addProductToDocument(
      currentProduct,
      props.products
    );
    if (result) {
      props.setProducts(result);
    }

    setQtyInputValue("");
    setCurrentProduct(undefined);
    return setTimeout(() => {
      setIsScanning(true);
    }, 2000);
  };

  const footer = [
    <Button
      key="continue"
      onClick={() => {
        setBarcode("");
        setIsScanning(!isScanning);
        setCurrentProduct("");
      }}
    >
      {isScanning ? "Зупинити" : "Продовжити"}
    </Button>,
    <Button key="submit" type="primary" onClick={clickHandler}>
      Додати в таблицю
    </Button>,
  ];

  const decrementQty = () => {
    if (qtyInputValue === 0 || qtyInputValue === "" || qtyInputValue === 1) {
      setQtyInputValue(1);
    } else {
      setQtyInputValue(qtyInputValue - 1);
    }
  };

  const incrementQty = () => {
    if (qtyInputValue === 0 || qtyInputValue === "") {
      setQtyInputValue(1);
    } else {
      setQtyInputValue(qtyInputValue + 1);
    }
  };

  const modalTitle = currentProduct ? (
    <>
      <Row>
        <Col span={20}>
          <Space>{currentProduct.title}</Space>
          <br />
          <br />
          <Space>{"Ціна: " + currentProduct.price}</Space>
          <br />
          <Space>{"Залишок: " + currentProduct.stockQty}</Space>
          {currentProduct?.qtyFromSource ? (
            <>
              <br />
              <Space>
                {"Кількість з документа: " + currentProduct.qtyFromSource}
              </Space>
            </>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </>
  ) : (
    "Введіть дані"
  );

  return (
    <>
      <Modal
        afterClose={closeBarcodeReader}
        title={modalTitle}
        open={props.isModalCameraBarcodeReaderOpen}
        onCancel={closeBarcodeReader}
        footer={footer}
        style={{ top: "5px" }}
      >
        {/* додати перевірку на пристрій і видавати потрібний сканер */}

        {isWebApiSupported ? (
          <>
            <canvas
              id="canvas"
              style={{ width: "100%", height: "300px" }}
            ></canvas>
            <ScannerDefault
              isScanning={isScanning}
              setBarcodeForSearch={setBarcodeForSearch}
              searchProduct={searchProduct}
            ></ScannerDefault>
          </>
        ) : (
          <>
            <span id="video"></span>
            <Scanner
              isScanning={isScanning}
              // setIsScanning={setIsScanning}
              setBarcodeForSearch={setBarcodeForSearch}
              searchProduct={searchProduct}
            ></Scanner>
          </>
        )}

        <Row>
          <Col span={14}>
            <Search
              onSearch={() => searchProduct(barcode)}
              allowClear={true}
              placeholder="Введіть дані"
              loading={loading}
              size="large"
              autoFocus={true}
              ref={input1Ref}
              value={barcode}
              onChange={(e) => setBarcode(e.target.value)}
            />
          </Col>
          <Col span={1}></Col>
          <Col span={9}>
            <InputNumber
              size="large"
              ref={inputQtyRef}
              placeholder="К-ть"
              controls={false}
              onPressEnter={clickHandler}
              onChange={(value) => {
                setQtyInputValue(value);
              }}
              value={qtyInputValue}
              type={"tel"}
              min={1}
              addonBefore={
                <span style={{ padding: "10px" }} onClick={decrementQty}>
                  -
                </span>
              }
              addonAfter={
                <span style={{ padding: "10px" }} onClick={incrementQty}>
                  +
                </span>
              }
            ></InputNumber>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
