import React, { useEffect } from "react";
import { CloudOutlined } from "@ant-design/icons";
import { Modal, Button } from "antd";
import { useAuth } from "../../hook/useAuth";

export default function UpdateAvailableModal(props) {
  const { user } = useAuth();
  useEffect(() => {
    if (user.username === "demo") {
      props?.setIsOpenUpdateAvailableModal(false);
      props.setIsOpenUpdateDataModal(true);
    }
  }, [user.username]);

  const footer = [
    <Button
      key="cancel"
      type="primary"
      onClick={() => props?.setIsOpenUpdateAvailableModal(false)}
    >
      Скасувати
    </Button>,
    <Button
      key="update"
      type="primary"
      onClick={() => {
        props?.setIsOpenUpdateAvailableModal(false);
        props.setIsOpenUpdateDataModal(true);
      }}
    >
      Оновити зараз
    </Button>,
  ];

  return (
    <>
      <Modal
        title={<>Оновлення</>}
        open={props?.open}
        onCancel={() => props?.setIsOpenUpdateAvailableModal(false)}
        footer={footer}
      >
        <CloudOutlined></CloudOutlined> Доступне оновлення даних
      </Modal>
    </>
  );
}
