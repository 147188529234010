import { createContext, useState } from "react";
import React from "react";
import Cookies from "universal-cookie";
import { logOut } from "../api/login/Login";
import { message } from "antd";
import { addHours } from "../utils/NormalizeData";
import { registerGAEvent } from "../analytics/React-ga";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const cookie = new Cookies();
  const loggedInUser = JSON.parse(localStorage.getItem("_auth_user"));
  const authToken = cookie.get("_auth_token");

  const [user, setUser] = useState(
    loggedInUser && authToken ? loggedInUser : null
  );

  const updateCurrentUser = (user) => {
    localStorage.setItem("_auth_user", JSON.stringify(user));
    setUser(user);
  };

  const signIn = (newUser, cb) => {
    const cookie = new Cookies();
    const midnight = new Date();
    midnight.setUTCHours(24, 0, 0, 0);

    const expiresDate = addHours(midnight, 3);
    localStorage.setItem("_auth_user", JSON.stringify(newUser));
    cookie.set("_auth_token", newUser.token, {
      path: "/",
      expires: expiresDate,
    });

    registerGAEvent({
      category: newUser.company,
      action: newUser.username + " - вхід",
      label: "Вхід в систему",
    });

    setUser(newUser);
    cb();
  };

  const signOut = async (cb) => {
    const loggedOut = await logOut(user.id, user.token);

    if (loggedOut !== true) {
      return message.info(`Помилка виходу з системи: ${loggedOut.message}`);
    }
    setUser(null);
    const cookie = new Cookies();
    localStorage.removeItem("_auth_user");
    cookie.remove("_auth_token");
    cb();
  };

  const value = { user, signIn, signOut, updateCurrentUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
