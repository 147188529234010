import dayjs from "dayjs";
import { deleteRecord, getAllRecords, getRecords } from "./dexie-db/functions";
import { getProductsWithIndexes } from "./products-functions";
import { message } from "antd";
import {
  DOCUMENT_BARCODE_PREFIX,
  DOCUMENT_OPEN_ACTIONS,
} from "../components/documents/_CONSTANTS/constants";
import { TABLES } from "./constants/tables";
import { checkDigitEAN13 } from "../utils/checkDigitEAN13";
import { servicesSettingsTitles } from "../components/documents/_CONSTANTS/settings";
import { getUserSetting } from "../api/settings/settings";

const getCharacteristicsForExport = (characteristics) => {
  return characteristics.map((singleCharacteristic) => {
    return {
      guid: singleCharacteristic.guid,
      qty: singleCharacteristic.qty ? singleCharacteristic.qty : 0,
      stockQty: singleCharacteristic.stockQty,
    };
  });
};

export const getDocBarcodingForExport = (doc) => {
  const docForExport = { ...doc };
  const docProducts = doc.products.map((product) => {
    // const productNewBarcodes = product.newBarcodes.map((bar) => {
    //   return { barcode: bar };
    // });
    const newProduct = {
      guid: product.guid,
      newBarcodes: product.newBarcodes,
    };
    return newProduct;
  });

  docForExport.dateTime = dayjs(doc.updated.$d).format("DD.MM.YYYY HH:mm:ss");
  docForExport.products = docProducts;
  delete docForExport.created;
  delete docForExport.date;
  delete docForExport.exported;
  delete docForExport.updated;
  delete docForExport.index;
  delete docForExport.key;
  delete docForExport.rowsCount;
  delete docForExport.supplierName;
  delete docForExport.storageToName;
  delete docForExport.storageFromName;
  delete docForExport.updated;
  delete docForExport.imported;

  return docForExport;
};

export async function getDocForExport(doc) {
  const docForExport = { ...doc };
  const docProducts = doc.products.map((product) => {
    const characteristics = product?.characteristics;

    const newProduct = {
      guid: product.guid,
      qty: !product.qty ? 0 : product.qty,
      price: product.price,
      qtyFromSource: product?.qtyFromSource,
      stockQty: product?.stockQty,
      ...(characteristics && {
        characteristics: getCharacteristicsForExport(characteristics),
      }),
      // Дописати характеристики
    };
    if (!newProduct.qtyFromSource) {
      delete newProduct.qtyFromSource;
    }
    return newProduct;
  });

  docForExport.dateTime = dayjs(doc.updated.$d).format("DD.MM.YYYY HH:mm:ss");
  docForExport.products = docProducts;
  delete docForExport.created;
  delete docForExport.date;
  delete docForExport.exported;
  delete docForExport.updated;
  delete docForExport.index;
  delete docForExport.key;
  delete docForExport.rowsCount;
  delete docForExport.supplierName;
  delete docForExport.storageToName;
  delete docForExport.storageFromName;
  delete docForExport.updated;
  delete docForExport.imported;

  return docForExport;
}

export async function getDocumentsForExport(documents) {
  return await Promise.all(
    documents.map(async (doc) => {
      return await getDocForExport(doc);
    })
  );
}

export async function getDocumentsBarcodingForExport(documents) {
  return await Promise.all(
    documents.map(async (doc) => {
      return await getDocBarcodingForExport(doc);
    })
  );
}

export function filterNotExportedDocuments(exportedDocuments, documents) {
  return documents.filter((document) => {
    const existingExportedDoc = exportedDocuments.find(
      (exportedDocument) => exportedDocument.id === document.id
    );

    return !Boolean(existingExportedDoc);
  });
}

export function getRecordsForTable(records) {
  return records.map((doc, index) => {
    doc.key = index + 1;
    doc.index = index + 1;
    return doc;
  });
}

export function setIndexesForRecords(records) {
  return records.map((doc, index) => {
    doc.key = !doc.key ? index + 1 : doc.key;
    doc.index = !doc.index ? index + 1 : doc.index;
    return doc;
  });
}

export const getDocumentProductsWithoutDeleted = async (
  products,
  product,
  dbTable,
  action
) => {
  if (
    action === DOCUMENT_OPEN_ACTIONS.CREATE ||
    (action === DOCUMENT_OPEN_ACTIONS.EDIT && dbTable)
  ) {
    const deleted = await deleteRecord(dbTable, product.guid);
    if (!deleted) {
      message.error("Помилка видалення записів");
      return false;
    }
  }
  const newProducts = products.filter((prod) => prod.id !== product.id);
  return getProductsWithIndexes(newProducts);
};

export const checkToDeleteDocuments = (documents, daysForStoring) => {
  const documentsToDelete = documents.map((document) => {
    const docDate = dayjs(document.date, "DD.MM.YYYY");
    const diff = dayjs().diff(docDate, "d");

    if (diff > daysForStoring) {
      return document;
    }
  });

  return documentsToDelete.filter((item) => item);
};

export const deleteOldDocuments = async (documents, serviceTable) => {
  const result = await Promise.all(
    documents.map(async (document) => {
      return await deleteRecord(serviceTable, document.id);
    })
  );
  return result;
};

export const deleteOldServicesDocuments = async (daysForStoring, getData) => {
  const orders = await getAllRecords(TABLES.ORDERS);
  const returns = await getAllRecords(TABLES.RETURNS);
  const invoices = await getAllRecords(TABLES.INVOICES);
  const revisions = await getAllRecords(TABLES.REVISIONS);
  const diplacements = await getAllRecords(TABLES.DIPLACEMENTS);
  const checkPrice = await getAllRecords(TABLES.CHECK_PRICE);
  const sales = await getAllRecords(TABLES.SALES);

  const ordersToDelete =
    orders && checkToDeleteDocuments(orders, daysForStoring);
  const returnsToDelete =
    returns && checkToDeleteDocuments(returns, daysForStoring);
  const invoicesToDelete =
    invoices && checkToDeleteDocuments(invoices, daysForStoring);
  const revisionsToDelete =
    revisions && checkToDeleteDocuments(revisions, daysForStoring);
  const diplacementsToDelete =
    diplacements && checkToDeleteDocuments(diplacements, daysForStoring);
  const checkPriceToDelete =
    checkPrice && checkToDeleteDocuments(checkPrice, daysForStoring);
  const salesToDelete = sales && checkToDeleteDocuments(sales, daysForStoring);

  if (ordersToDelete.length)
    (await deleteOldDocuments(ordersToDelete, TABLES.ORDERS))
      ? message.success("Старі замовлення успішно видалені", 3)
      : message.error("Помилка видалення старих замовлень", 3);

  if (returnsToDelete.length)
    (await deleteOldDocuments(returnsToDelete, TABLES.RETURNS))
      ? message.success("Старі повернення успішно видалені", 3)
      : message.error("Помилка видалення старих повернень", 3);

  if (invoicesToDelete.length)
    (await deleteOldDocuments(invoicesToDelete, TABLES.INVOICES))
      ? message.success("Старі приходи успішно видалені", 3)
      : message.error("Помилка видалення старих приходів", 3);

  if (revisionsToDelete.length)
    (await deleteOldDocuments(revisionsToDelete, TABLES.REVISIONS))
      ? message.success("Старі ревізії успішно видалені", 3)
      : message.error("Помилка видалення старих ревізій", 3);

  if (diplacementsToDelete.length)
    (await deleteOldDocuments(diplacementsToDelete, TABLES.DIPLACEMENTS))
      ? message.success("Старі переміщення успішно видалені", 3)
      : message.error("Помилка видалення старих переміщень", 3);

  if (checkPriceToDelete.length)
    (await deleteOldDocuments(checkPriceToDelete, TABLES.CHECK_PRICE))
      ? message.success("Старі перевірки цін успішно видалені", 3)
      : message.error("Помилка видалення старих перевірок цін", 3);

  if (salesToDelete.length)
    (await deleteOldDocuments(salesToDelete, TABLES.SALES))
      ? message.success("Старі реалізації успішно видалені", 3)
      : message.error("Помилка видалення старих реалізацій", 3);

  getData();
};

export const setDocumentsToTable = async (
  setLoading,
  dbTable,
  settings,
  setDocuments,
  getFormattedDocuments
) => {
  setLoading(true);
  const documents = await getAllRecords(dbTable);
  setDocuments(await getFormattedDocuments(documents));
  setLoading(false);
};

export const handleDeleteExported = async (
  dbTable,
  documents,
  getFormattedDocuments,
  setDocuments
) => {
  const documentsToDelete = await getRecords(dbTable, "exported", "true");
  if (!documentsToDelete.length) {
    return message.info("Немає документів для видалення");
  }

  const isSuccessDeleted = await Promise.all(
    documentsToDelete.map(async (doc) => {
      await deleteRecord(dbTable, doc.id);
    })
  );
  if (isSuccessDeleted) {
    const newDocuments = documents.filter(
      (document) => document.exported !== "true"
    );
    const formattedDocuments = await getFormattedDocuments(newDocuments);
    setDocuments(formattedDocuments);
    message.success("Відправлені документи видалено!");
  }
};

export const handleDeleteImported = async (
  dbTable,
  documents,
  getFormattedDocuments,
  setDocuments
) => {
  const documentsToDelete = await getRecords(dbTable, "imported", "true");
  if (!documentsToDelete.length) {
    return message.info("Немає документів для видалення");
  }

  const isSuccessDeleted = await Promise.all(
    documentsToDelete.map(async (doc) => {
      await deleteRecord(dbTable, doc.id);
    })
  );
  if (isSuccessDeleted) {
    const newDocuments = documents.filter(
      (document) => document.exported !== "true"
    );
    const formattedDocuments = await getFormattedDocuments(newDocuments);
    setDocuments(formattedDocuments);
    message.success("Відправлені документи видалено!");
  }
};

export const handleExportUnexported = async (
  dbTable,
  setDocumentsToExport,
  setDocumentsToExportOriginal,
  setIsModalExportOpen,
  getDocumentsForExport,
  setLoadingExportButton
) => {
  setLoadingExportButton(true);
  const unexportedDocuments = await getRecords(dbTable, "exported", "false");
  if (!unexportedDocuments.length) {
    setLoadingExportButton(false);
    return message.info("Немає даних для відправки");
  }
  setDocumentsToExport(await getDocumentsForExport(unexportedDocuments));
  setDocumentsToExportOriginal(unexportedDocuments);
  setIsModalExportOpen(true);
};

export const handleExportTodayDocuments = async (
  dbTable,
  setDocumentsToExport,
  setDocumentsToExportOriginal,
  setIsModalExportOpen,
  getDocumentsForExport,
  setLoadingExportButton
) => {
  setLoadingExportButton(true);
  const todayDocuments = await getRecords(
    dbTable,
    "date",
    dayjs().format("DD.MM.YYYY")
  );

  if (!todayDocuments.length) {
    setLoadingExportButton(false);
    return message.info("Немає даних для відправки");
  }
  setDocumentsToExport(await getDocumentsForExport(todayDocuments));
  setDocumentsToExportOriginal(todayDocuments);
  setIsModalExportOpen(true);
};

export const generateDocBarcode = () => {
  const dateInMiliseconds = dayjs().valueOf().toString();

  const dateInMilisecondsMinusTwoSymbols = dateInMiliseconds.substring(
    dateInMiliseconds.length,
    dateInMiliseconds.length - (dateInMiliseconds.length - 3)
  );

  const barcode12 = `${DOCUMENT_BARCODE_PREFIX}${dateInMilisecondsMinusTwoSymbols}`;
  const checkDigit = checkDigitEAN13(barcode12);
  const barcodeEan13 = `${barcode12}${checkDigit}`;

  return barcodeEan13;
};

export const getDocumentByBarcode = async (barcode, dbTable) => {
  const allDocuments = await getAllRecords(dbTable);
  if (!allDocuments) return false;

  const document = allDocuments.find((doc) => doc.docBarcode === barcode);
  if (!document) return false;
  return document;
};

export async function getRealizationForPrint(settings, doc) {
  const printArt = getUserSetting(
    "printArt",
    settings,
    servicesSettingsTitles.SALES
  );

  const docForPrint = { ...doc };
  const docProducts = doc.products.map((product) => {
    const newProduct = {
      title: printArt ? `${product.title} / ${product.art}` : product.title,
      qty: !product.qty ? 0 : product.qty,
      price: product.price,
    };
    return newProduct;
  });

  docForPrint.dateTime = dayjs(doc.updated.$d).format("DD.MM.YYYY HH:mm:ss");
  docForPrint.products = docProducts;
  delete docForPrint.created;
  delete docForPrint.date;
  delete docForPrint.exported;
  delete docForPrint.updated;
  delete docForPrint.index;
  delete docForPrint.key;
  delete docForPrint.rowsCount;
  delete docForPrint.updated;
  delete docForPrint.imported;

  return docForPrint;
}
