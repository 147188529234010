import React from "react";

export default function DefaulModalBodyAddonCamera({ askPrice }) {
  return (
    <>
      <span id="currentProductStockDataInCameraModal"></span>
      {askPrice ? "" : <span id="currentProductPriceDataInCameraModal"></span>}
    </>
  );
}
