export const getTableTotals = (products, byDifferents = false) => {
  const { total, quantity } = products.reduce(
    (totalAndQnt, docProduct) => {
      if (byDifferents) {
        return {
          total:
            totalAndQnt.total +
            Number(
              ((!docProduct.qty ? 0 : docProduct.qty) - docProduct.stockQty) *
                docProduct.price
            ),
          quantity:
            totalAndQnt.quantity + Number(!docProduct.qty ? 0 : docProduct.qty),
        };
      } else {
        return {
          total:
            totalAndQnt.total +
            Number(!docProduct.qty ? 0 : docProduct.qty * docProduct.price),
          quantity:
            totalAndQnt.quantity + Number(!docProduct.qty ? 0 : docProduct.qty),
        };
      }
    },
    {
      total: 0,
      quantity: 0,
    }
  );
  return { total, quantity };
};

export const getCharacteristicTotals = (
  characteristics = [],
  showTotalByDiff = true
) => {
  const { total, quantity } = characteristics.reduce(
    (totalAndQnt, singleCharacteristic) => {
      const totalShowByDiff =
        totalAndQnt.total +
        Number(
          ((!singleCharacteristic.qty ? 0 : singleCharacteristic.qty) -
            (singleCharacteristic.stockQty
              ? singleCharacteristic.stockQty
              : 0)) *
            (singleCharacteristic.price ? singleCharacteristic.price : 0)
        );
      const totaoShowByFact =
        totalAndQnt.total +
        (!singleCharacteristic?.qty ? 0 : singleCharacteristic.qty) *
          (singleCharacteristic.price ? singleCharacteristic.price : 0);
      return {
        total: showTotalByDiff ? totalShowByDiff : totaoShowByFact,
        quantity:
          totalAndQnt.quantity +
          Number(!singleCharacteristic.qty ? 0 : singleCharacteristic.qty),
      };
    },
    {
      total: 0,
      quantity: 0,
    }
  );
  return { total, quantity };
};
