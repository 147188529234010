import { Modal, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { SearchOutlined } from "@ant-design/icons";
import { getMobileOperatingSystem } from "../../../../utils/getMobileOperatingSystem";

export default function ModalEnterBarcode(props) {
  const [currentProduct, setCurrentProduct] = useState();

  const inputDataRef = useRef();

  useEffect(() => {
    if (props.isModalProductOpen) {
      if (getMobileOperatingSystem() === "iOS")
        return inputDataRef.current.focus();

      setTimeout(() => {
        inputDataRef.current.focus();
      }, 1);
    }
  }, [props.isModalProductOpen]);

  const newModalTitle = () => {
    return currentProduct ? currentProduct.title : "Введіть дані";
  };

  return (
    <>
      <Modal
        key="modalEnterProductBarcoding"
        title={newModalTitle}
        style={{ top: "10px", padding: "10px 15px !important" }}
        className="modalEnterProduct"
        open={props.isModalProductOpen}
        onCancel={() => {
          props.setIsModalProductOpen(false);
        }}
        footer={null}
        afterClose={() => {
          if (props?.setBackgroundEnteredBarcodeValue)
            props.setBackgroundEnteredBarcodeValue("");
          setCurrentProduct(undefined);
          inputDataRef.current.value = "";
        }}
      >
        <Row>
          <form onSubmit={() => false}>
            <InputGroup className="mb-3">
              <Form.Control
                className="inputData"
                placeholder="Введіть дані"
                ref={inputDataRef}
                type={"tel"}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    const enteredData = e.target.value;
                    e.target.value = "";
                    props.processSearchProduct(enteredData);
                  }
                }}
                autoFocus={true}
                // onFocus={onFocusDataInput}
                // onBlur={onBlurDataInput}
              />
              <InputGroup.Text
                onClick={() => {
                  const enteredData = inputDataRef.current.value;
                  props.processSearchProduct(enteredData);
                  inputDataRef.current.value = "";
                }}
              >
                <SearchOutlined></SearchOutlined>
              </InputGroup.Text>
            </InputGroup>
          </form>
        </Row>
      </Modal>
    </>
  );
}
