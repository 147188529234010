import { Modal, message, Spin, Space, notification, App } from "antd";
import React, { useEffect, useState } from "react";
import { getDataFromTxt } from "../../api/Import/txt/import";
import {
  getDataFromXml,
  getFormattedDocsForVerification,
} from "../../api/Import/xml/import";
import dayjs from "dayjs";
import { useAuth } from "../../hook/useAuth";
import { getFormattedDocs } from "../../api/Import/xml/import";

import { db } from "../../db/dexie-db/db";
import { getUserSetting } from "../../api/settings/settings";
import { servicesSettingsTitles } from "../documents/_CONSTANTS/settings";
import { getAllRecords } from "../../db/dexie-db/functions";
import { TABLES } from "../../db/constants/tables";
import { filterArrayOfObjectFromArrayOfObjectsByIndex } from "../../utils/array-functions";
import { getUpdateDate } from "../../utils/getLatestUpdateDate";

export default function ImportModal(props) {
  const { user } = useAuth();
  const [loadingText, setLoadingText] = useState("");

  useEffect(() => {
    if (props.open) {
      getData();
    }
  }, [props.open]);

  const getData = async () => {
    setLoadingText("Завантаження даних");
    let data;
    if (user.exchangeType === "xml") {
      data = await getDataFromXml(user.username, user.useFtp);
    } else {
      data = await getDataFromTxt(user.username, user.useFtp);
    }

    if (!data) {
      props.setIsOpenUpdateDataModal(false);
      return notification.error({
        message: "Помилка оновлення!",
        description: "Помилка завантаження даних...",
        duration: 0,
      });
    }

    const products = data.products;
    const categories = data.categories;
    const suppliers = data.suppliers;
    const storages = data.storages;
    const documents = data.documents;
    const documentsForVerification = data.documentsForVerification;

    if (!products || products.length === 0 || !data) {
      props.setIsOpenUpdateDataModal(false);
      return notification.error({
        message: "Помилка оновлення!",
        description: "Помилка завантаження товарів...",
        duration: 0,
      });
    }
    if (!categories || categories.length === 0) {
      message.info("Категорії не завантажені!");
    }
    if (!suppliers.length) {
      message.info("Постачальники не завантажені!");
    }

    try {
      setLoadingText("Підготовка...");
      await clearIndexDb();
    } catch (error) {
      setLoadingText("");
      alert(JSON.stringify(error));
      message.error("[ERROR] - failed clear db");
    }

    const savedRequiredData = await saveRequiredDataToIndexDb(
      products,
      categories,
      suppliers
    );
    if (!savedRequiredData) {
      console.log("Помилка збереження даних");
      message.error("Помилка збереження даних");
      setLoadingText("Помилка оновлення, перевірте правильність вигрузки");
      return;
    }

    const savedDocuments = await saveDocumentsToIndexedDb(documents);
    if (!savedDocuments) {
      message.error("Помилка збереження документів");
      setLoadingText("Помилка оновлення, перевірте правильність вигрузки");
      return;
    }

    if (documentsForVerification?.length > 0) {
      const dontRewriteDocsForVerification = getUserSetting(
        "dontRewriteDocuments",
        user.settings,
        servicesSettingsTitles.VERIFICATION
      );

      const savedDocumentsForVerification =
        await saveDocumentsForVerificationToIndexedDb(
          documentsForVerification,
          dontRewriteDocsForVerification
        );
      if (!savedDocumentsForVerification) {
        message.error("Помилка збереження документів для перевірки");
        setLoadingText("Помилка оновлення, перевірте правильність вигрузки");
        return;
      }
    }

    if (storages.length > 0) {
      const savedStorages = await db.Storages.bulkPut(storages);
      if (!savedStorages) {
        message.error("Помилка збереження складів");
        setLoadingText("Помилка оновлення, перевірте правильність вигрузки");
        return;
      }
    } else {
      message.info("Склади не завантажені");
    }

    setTimeout(() => {
      props.setIsOpenUpdateDataModal(false);
      message.success("Дані оновлено!");
      localStorage.setItem("updated", dayjs());
      try {
        document.getElementById(
          "homeUpdatedText"
        ).innerText = `Оновлено: ${getUpdateDate(dayjs())}`;
      } catch (error) {}
      setLoadingText("");
    }, 100);
  };

  const clearIndexDb = async () => {
    return await Promise.all([
      await db.Products.clear(),
      await db.Categories.clear(),
      await db.Suppliers.clear(),
      await db.Storages.clear(),
    ]);
  };

  const saveDocumentsForVerificationToIndexedDb = async (
    documentsForVerification,
    dontRewrite = false
  ) => {
    const docs = await getFormattedDocsForVerification(
      documentsForVerification
    );
    let docsToDb = docs;
    if (dontRewrite) {
      const storedDocs = await getAllRecords(TABLES.VERIFICATION);
      docsToDb = filterArrayOfObjectFromArrayOfObjectsByIndex(
        docs,
        storedDocs,
        "id"
      );
    }
    if (!docsToDb.length) return true;
    return await db.DocumentsForVerification.bulkPut(docsToDb);
  };

  const saveDocumentsToIndexedDb = async (documents) => {
    let ordersToDb, revisionsToDb;
    const invoices =
      documents.invoices?.length > 0
        ? (await getFormattedDocs(documents.invoices)).filter(
            (document) => document
          )
        : [];
    const diplacements =
      documents.diplacements?.length > 0
        ? (await getFormattedDocs(documents.diplacements)).filter(
            (document) => document
          )
        : [];
    const orders =
      documents.orders?.length > 0
        ? (await getFormattedDocs(documents.orders)).filter(
            (document) => document
          )
        : [];
    ordersToDb = orders;

    const revisions =
      documents.revisions?.length > 0
        ? (await getFormattedDocs(documents.revisions)).filter(
            (document) => document
          )
        : [];
    revisionsToDb = revisions;

    if (ordersToDb.length) {
      const dontRewriteOrders = getUserSetting(
        "dontRewriteOrders",
        user.settings,
        servicesSettingsTitles.ORDERS
      );
      if (dontRewriteOrders) {
        const storedOrders = await getAllRecords(TABLES.ORDERS);
        ordersToDb = filterArrayOfObjectFromArrayOfObjectsByIndex(
          orders,
          storedOrders,
          "id"
        );
      }
    }

    const storedRevisions = await getAllRecords(TABLES.REVISIONS);
    revisionsToDb = filterArrayOfObjectFromArrayOfObjectsByIndex(
      revisions,
      storedRevisions,
      "id"
    );

    return await Promise.all([
      await db.Orders.bulkPut(ordersToDb),
      await db.Invoices.bulkPut(invoices),
      await db.Diplacements.bulkPut(diplacements),
      await db.Revisions.bulkPut(revisionsToDb),
    ]);
  };

  const saveRequiredDataToIndexDb = async (products, categories, suppliers) => {
    return await Promise.all([
      await db.Products.bulkPut(products),
      await db.Categories.bulkPut(categories),
      await db.Suppliers.bulkPut(suppliers),
    ]);
  };

  return (
    <>
      <App>
        <Modal
          title={"Оновлення даних"}
          closable={false}
          maskClosable={false}
          footer={null}
          open={props.open}
          onCancel={() => props.setIsOpenUpdateDataModal(false)}
        >
          <>
            <Space>
              <Spin spinning={true}></Spin> {loadingText}
            </Space>
          </>
        </Modal>
      </App>
    </>
  );
}
