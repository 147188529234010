import {
  CloudUploadOutlined,
  DeleteOutlined,
  EditOutlined,
  FundViewOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

export const tableRowActions = [
  {
    title: " Експортувати",
    key: "export",
    icon: <CloudUploadOutlined />,
  },
  {
    title: " Надрукувати",
    key: "print",
    icon: <PrinterOutlined />,
  },
  {
    title: " Переглянути",
    key: "viewDocument",
    icon: <FundViewOutlined />,
  },
  {
    title: " Змінити",
    key: "editDocument",
    icon: <EditOutlined />,
  },
  {
    title: " Видалити",
    key: "deleteDocument",
    icon: <DeleteOutlined />,
  },
];
