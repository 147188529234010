import { Radio, Space, Switch } from "antd";
import React from "react";
import Menu from "../../../menu/Menu";
import { useAuth } from "../../../../hook/useAuth";
import {
  getUpdatedSettings,
  updateUserSettInSql,
} from "../../../../api/settings/settings";
import { PAGE_SIZE_VALUES } from "../../_CONSTANTS/settings";

function MenuComponent(props) {
  const { user, updateCurrentUser } = useAuth();

  const onChangeColorDifferents = (value) => {
    props.setSettings({ ...props.settings, colorDifferents: value });
    updateUserSettInSql(user, "colorDifferents", value, "invoice");
    updateUserWithNewSettings(
      user.settings,
      "colorDifferents",
      value,
      "invoice"
    );
  };

  const onChangeShowBarcodes = (value) => {
    props.setSettings({ ...props.settings, showBarcodes: value });
    updateUserSettInSql(user, "showBarcodes", value, "invoice");
    updateUserWithNewSettings(user.settings, "showBarcodes", value, "invoice");
  };

  const onChangePageSize = ({ target: { value } }) => {
    props.setSettings({ ...props.settings, pageSize: value });
    updateUserSettInSql(user, "pageSize", value, "invoice");
    updateUserWithNewSettings(user.settings, "pageSize", value, "invoice");
  };

  const updateUserWithNewSettings = (
    allSettings,
    settName,
    settValue,
    serviceName
  ) => {
    const updatedSettings = getUpdatedSettings(
      allSettings,
      settName,
      settValue,
      serviceName
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };
    updateCurrentUser(updatedLoggedInUser);
  };

  const onChangeAskPrice = (value) => {
    props.setSettings({ ...props.settings, askPrice: value });
    updateUserSettInSql(user, "askPrice", value, "invoice");
    updateUserWithNewSettings(user.settings, "askPrice", value, "invoice");
  };

  const settingsTab = (
    <>
      <Space direction="vertical">
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.askPrice}
            size="default"
            onChange={onChangeAskPrice}
          />
          Запитувати ціну
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.colorDifferents}
            size="default"
            onChange={onChangeColorDifferents}
          />
          Підсвітка розбіжностей
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.showBarcodes}
            size="default"
            onChange={onChangeShowBarcodes}
          />
          Показувати штрихкоди
        </Space>

        <Space direction="horizontal">
          <Radio.Group
            options={PAGE_SIZE_VALUES}
            onChange={onChangePageSize}
            value={props?.settings?.pageSize}
            optionType="button"
            buttonStyle="solid"
          />
          Товарів на сторінку
        </Space>
      </Space>
    </>
  );
  return (
    <>
      <Menu settingsTab={settingsTab}></Menu>
    </>
  );
}
export default MenuComponent;
