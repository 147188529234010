import React, { useState } from "react";
import { Table } from "antd";
import ModalEditProductQty from "../ModalEditProductQty";
import ModalEnterCharacteristics from "../modal-enter-characteristics/ModalEnterCharacteristics";
import { getUserSetting } from "../../../../api/settings/settings";
import { useAuth } from "../../../../hook/useAuth";
import { CHARACTERISTICS_ACTION } from "../../_CONSTANTS/constants";
import ModalEnterExpirationDate from "../modal-enter-expiration-date/ModalEnterExpirationDate";

function TableLastEnteredProducts(props) {
  const { user } = useAuth();

  const [isModalChangeLastProductOpen, setIsModalChangeLastProductOpen] =
    useState(false);
  const [lastProductToChange, setLastProductToChange] = useState(undefined);
  const [isModalEnterCharacteristicsOpen, setIsModalEnterCharacteristicsOpen] =
    useState(false);
  const [isModalEnterExpirationDateOpen, setIsModalEnterExpirationDateOpen] =
    useState(false);

  const display = props?.isVisible ? "block" : "none";

  const useCharacteristics = getUserSetting(
    "useCharacteristics",
    user.settings,
    "global"
  );

  const useEnteringExpirationDates = getUserSetting(
    "useEnteringExpirationDates",
    user.settings,
    "global"
  );

  const onDoubleClickHandler = async (record) => {
    setLastProductToChange(record);
    if (useEnteringExpirationDates && useCharacteristics) {
      return setIsModalEnterExpirationDateOpen(true);
    }

    if (useCharacteristics && record?.characteristics)
      return setIsModalEnterCharacteristicsOpen(true);

    setIsModalChangeLastProductOpen(true);
  };

  return (
    <>
      <Table
        key="TableLastEnteredProducts"
        style={{ display: display }}
        tableLayout="fixed"
        loading={props?.isloading}
        columns={props.columns}
        pagination={{
          hideOnSinglePage: true,
        }}
        dataSource={props?.lastEnteredProducts}
        size={"small"}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              onDoubleClickHandler(record);
            },
            onContextMenu: (event) => {
              onDoubleClickHandler(record);
            },
          };
        }}
      />

      {isModalEnterCharacteristicsOpen && (
        <ModalEnterCharacteristics
          isModalEnterCharacteristicsOpen={isModalEnterCharacteristicsOpen}
          setIsModalEnterCharacteristicsOpen={
            setIsModalEnterCharacteristicsOpen
          }
          product={lastProductToChange}
          setIsModalProductOpen={props.setIsModalProductOpen}
          products={props.products}
          addProductToCurrentDocument={props.handleChangeQty}
          removeProductFromDocument={props?.removeProductFromDocument}
          settings={props.settings}
          action={CHARACTERISTICS_ACTION.EDIT}
          dbTable={props.dbTable}
          afterChangeProductQty={props.afterChangeProductQty}
        ></ModalEnterCharacteristics>
      )}

      {isModalEnterExpirationDateOpen && (
        <ModalEnterExpirationDate
          key="ModalEnterExpirationDate"
          setIsModalEnterExpirationDateOpen={setIsModalEnterExpirationDateOpen}
          isModalEnterExpirationDateOpen={isModalEnterExpirationDateOpen}
          product={lastProductToChange}
          setIsModalProductOpen={props.setIsModalProductOpen}
          products={props.products}
          afterClose={props.afterCloseModalEnterCharacteristics}
          addProductToCurrentDocument={
            props.addProductWithCharacteristicsToCurrentDocument
          }
          dbTable={props.dbTable}
          serviceTitle={props?.serviceTitle}
          afterChangeProductQty={props.afterChangeProductQty}
        ></ModalEnterExpirationDate>
      )}

      {isModalChangeLastProductOpen && (
        <ModalEditProductQty
          isModalChangeLastProductOpen={isModalChangeLastProductOpen}
          setIsModalChangeLastProductOpen={setIsModalChangeLastProductOpen}
          // handleChangeQty={props.handleChangeQty}
          productToChangeQty={lastProductToChange}
          removeProductFromDocument={props?.removeProductFromDocument}
          settings={props.settings}
          dbTable={props.dbTable}
          afterChangeProductQty={props.afterChangeProductQty}
        ></ModalEditProductQty>
      )}
    </>
  );
}
export default TableLastEnteredProducts;
