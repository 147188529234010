import React, { useState, useEffect, useRef, useMemo } from "react";
import Footer from "../../components/documents/revisions/create/Footer";
import Main from "../../components/documents/revisions/create/Main";
import ModalBarcodeCameraReader from "../../components/camera-barcode-reader/ModalBarcodeCameraReader";
import {
  getColumnsData,
  getRevisionSettings,
} from "../../components/documents/revisions/functions";
import ModalChooseProducts from "../../components/products/products-tree/ModalChooseProducts";
import BottomPageTitle from "../../components/header/BottomPageTitle";
import { useAuth } from "../../hook/useAuth";
import ModalFoldersChoosing from "../../components/documents/revisions/ModalFoldersChoosing";
import ModalEnterProduct from "../../components/documents/manage-documents/modal-enter-product/ModalEnterProducts";
import { TABLES } from "../../db/constants/tables";
import {
  DOCUMENTS_TITLES,
  DOCUMENT_OPEN_ACTIONS,
} from "../../components/documents/_CONSTANTS/constants";
import { getDocumentProductsWithoutDeleted } from "../../db/documents-funtions";
import { getDefaultColumns } from "../../components/documents/revisions/table-products/columns";
import {
  getCurrentDocProducts,
  getProductsWithIndexes,
  getSortedProductsByTimeAdded,
} from "../../db/products-functions";
import { getDiffColumnVisibility } from "../../components/table-utils/last-entered-products/Columns";
import { listenToBackgroundEnteredBarcode } from "../../utils/eventListeners";
import { getUserSetting } from "../../api/settings/settings";

const DOCUMENT_TITLE = DOCUMENTS_TITLES.REVISION;
const DOCUMENT_ACTION = DOCUMENT_OPEN_ACTIONS.CREATE;
const CURRENT_DOCUMENT_TABLE = TABLES.CURRENT_REVISION;

let backgroundEnteredBarcode = "";

export default function RevisionCreate() {
  const { user } = useAuth();

  const revisionSettings = getRevisionSettings(user);
  const useCharacteristics = getUserSetting(
    "useCharacteristics",
    user.settings,
    "global"
  );
  const showStockQty = revisionSettings?.showRealCount;

  const [isModalProductOpen, setIsModalProductOpen] = useState(false);
  const [isModalChooseProductsOpen, setIsModalChooseProductsOpen] =
    useState(false);
  const [isModalCameraBarcodeReaderOpen, setIsModalCameraBarcodeReaderOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [settings, setSettings] = useState(revisionSettings);
  const [isModalChooseCategoriesOpen, setIsModalChooseCategoriesOpen] =
    useState(false);
  const searchInput = useRef(null);
  const [backgroundEnteredBarcodeValue, setBackgroundEnteredBarcodeValue] =
    useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    setProducts(await getProductsToTable());
    setLoading(false);
  };

  const getProductsToTable = async () => {
    const products = await getCurrentDocProducts(CURRENT_DOCUMENT_TABLE);
    const sortedProducts = getSortedProductsByTimeAdded(products);
    const sortedProductsWithIndexes = getProductsWithIndexes(sortedProducts);
    return sortedProductsWithIndexes;
  };

  useEffect(() => {
    document.addEventListener("keydown", detectKeyDownEvent);
    return () => {
      document.removeEventListener("keydown", detectKeyDownEvent);
    };
  }, []);

  const detectKeyDownEvent = (e) => {
    backgroundEnteredBarcode = listenToBackgroundEnteredBarcode(
      e,
      setBackgroundEnteredBarcodeValue,
      backgroundEnteredBarcode
    );
  };

  const removeProductFromDocument = async (product) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      product,
      CURRENT_DOCUMENT_TABLE,
      DOCUMENT_ACTION
    );
    setProducts(arrayWithoutDeletedProduct);
  };

  const defaultColumns = getDefaultColumns({
    settings,
    searchInput,
    useCharacteristics,
  });

  const columns = useMemo(() => {
    return getColumnsData(defaultColumns, settings);
  }, [settings]);

  const afterChangeProductQty = async () => {
    setProducts(await getProductsToTable());
  };

  const FooterMemo = useMemo(() => {
    return (
      <Footer
        setIsModalProductOpen={setIsModalProductOpen}
        setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
        setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
        setIsModalChooseCategoriesOpen={setIsModalChooseCategoriesOpen}
        settings={settings}
        setSettings={setSettings}
        action={DOCUMENT_ACTION}
      ></Footer>
    );
  }, [settings]);

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_ACTION}
        dbTable={CURRENT_DOCUMENT_TABLE}
        columns={columns}
        settings={settings}
        afterChangeProductQty={afterChangeProductQty}
        removeProductFromDocument={removeProductFromDocument}
      ></Main>
      {(isModalProductOpen || backgroundEnteredBarcodeValue) && (
        <ModalEnterProduct
          backgroundEnteredBarcode={backgroundEnteredBarcodeValue}
          setBackgroundEnteredBarcodeValue={setBackgroundEnteredBarcodeValue}
          action={DOCUMENT_ACTION}
          dbTable={CURRENT_DOCUMENT_TABLE}
          products={products}
          setProducts={setProducts}
          isModalProductOpen={isModalProductOpen}
          setIsModalProductOpen={setIsModalProductOpen}
          settings={settings}
          weightTemplate={user.weightTemplate}
          serviceTitle={DOCUMENT_TITLE}
          showDiffColumn={getDiffColumnVisibility(DOCUMENT_TITLE, false)}
          focusInputQty={true}
          showStockQty={showStockQty === false ? false : true}
          removeProductFromDocument={removeProductFromDocument}
          columns={columns}
        ></ModalEnterProduct>
      )}

      {isModalCameraBarcodeReaderOpen && (
        <ModalBarcodeCameraReader
          action={DOCUMENT_ACTION}
          products={products}
          setProducts={setProducts}
          focusInputQty={true}
          isModalCameraBarcodeReaderOpen={isModalCameraBarcodeReaderOpen}
          setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
          dbTable={CURRENT_DOCUMENT_TABLE}
          serviceTitle={DOCUMENT_TITLE}
          settings={settings}
          weightTemplate={user.weightTemplate}
          showStockQty={showStockQty === false ? false : true}
        ></ModalBarcodeCameraReader>
      )}
      {isModalChooseProductsOpen && (
        <ModalChooseProducts
          action={DOCUMENT_ACTION}
          products={products}
          setProducts={setProducts}
          afterClose={afterChangeProductQty}
          settings={settings}
          isModalChooseProductsOpen={isModalChooseProductsOpen}
          setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
          dbTable={CURRENT_DOCUMENT_TABLE}
          showStockQty={showStockQty === false ? false : true}
        ></ModalChooseProducts>
      )}

      {isModalChooseCategoriesOpen && (
        <ModalFoldersChoosing
          isModalChooseCategoriesOpen={isModalChooseCategoriesOpen}
          setIsModalChooseCategoriesOpen={setIsModalChooseCategoriesOpen}
          setProducts={setProducts}
        ></ModalFoldersChoosing>
      )}
      {FooterMemo}
      <BottomPageTitle title={DOCUMENT_TITLE}></BottomPageTitle>
    </>
  );
}
