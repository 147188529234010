import { Dropdown, Modal, Space, Tabs, message } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TableProducts from "../create/TableProducts";
import InfoTab from "../create/InfoTab";
import {
  SaveOutlined,
  OrderedListOutlined,
  DownOutlined,
  StopOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import {
  addRecord,
  updateRecord,
  clearObjectStore,
} from "../../../../db/dexie-db/functions";
import { getTableTotals } from "../../../../utils/getTableTotals";
import { getOrderProductsQty } from "../functions";
import { DOCUMENT_OPEN_ACTIONS, TABS_TITLES } from "../../_CONSTANTS/constants";
import { TABLES } from "../../../../db/constants/tables";
import { generateDocBarcode } from "../../../../db/documents-funtions";

const Main = (props) => {
  const navigate = useNavigate();

  const action = props?.action;
  const documentForEdit = props?.document;

  const [loadingSaveButton, setLoadingSaveButton] = useState(false);
  const [supplier, setSupplier] = useState();
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (documentForEdit) {
      setSupplier(documentForEdit.supplierGuid);
      // setComment(documentForEdit.comment);
    }
  }, [documentForEdit]);

  const handleMenuClick = async () => {
    Modal.confirm({
      title: "Не зберігати?",
      onCancel: () => {
        return;
      },
      onOk: async () => {
        const clearedCurrentOrder = await clearObjectStore(
          TABLES.CURRENT_INVOICE
        );
        if (!clearedCurrentOrder) {
          return message.error("Помилка очищення документа");
        }
        props?.setProducts([]);
        navigate(-1);
      },
      okText: "Ні",
    });
  };

  const items = [
    {
      label: (
        <Space>
          <StopOutlined />
          Скасувати
        </Space>
      ),
      key: "1",
      onClick: handleMenuClick,
    },
  ];

  const handleButtonClick = async () => {
    if (!supplier) {
      return Modal.confirm({
        title: "Контрагент не вибраний, продовжити?",
        onCancel: () => {
          return;
        },
        onOk: () => {
          saveDocumentHadler();
        },
      });
    }
    saveDocumentHadler();
  };

  const saveDocumentHadler = async () => {
    setLoadingSaveButton(true);
    switch (action) {
      case DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE:
        const createdFromOrder = createInvoice(
          props,
          documentForEdit,
          supplier
        );
        if (!createdFromOrder) {
          return;
        }
        setLoadingSaveButton(false);
        break;
      case DOCUMENT_OPEN_ACTIONS.EDIT:
        if (!documentForEdit || !action) {
          setLoadingSaveButton(false);
          return message.error("Помилка взагалі не реальна");
        }
        if (props.products.length === 0) {
          setLoadingSaveButton(false);
          return message.error("Додайте товари");
        }
        const updatedDocument = {
          ...props.document,
          updated: dayjs(),
          exported: "false",
          products: props.products,
          id: props.document.id,
          supplierGuid: supplier ? supplier : "",
          sum: getTableTotals(props.products).total,
          comment,
        };
        const updated = await updateRecord(
          TABLES.INVOICES,
          Number(updatedDocument.id),
          updatedDocument
        );
        if (!updated) {
          setLoadingSaveButton(false);
          return message.error("Помилка оновлення документу");
        }
        message.success("Оновлено!");
        setLoadingSaveButton(false);
        break;
      case DOCUMENT_OPEN_ACTIONS.CREATE:
        if (props.products.length === 0) {
          setLoadingSaveButton(false);
          return message.error("Додайте товари");
        }
        const created = await createInvoice(props, documentForEdit, supplier);
        if (!created) {
          return;
        }
        setLoadingSaveButton(false);
        break;
      default:
        break;
    }

    return navigate("/invoices");
  };

  const createInvoice = async (props, documentForEdit, supplier) => {
    const newDocument = {
      date: dayjs().format("DD.MM.YYYY"),
      created: dayjs(),
      updated: dayjs(),
      exported: "false",
      docBarcode: generateDocBarcode(),
      products:
        action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE
          ? getOrderProductsQty(props.products, documentForEdit.products)
          : props.products,
      supplierGuid: supplier ? supplier : "",
      sum: getTableTotals(props.products).total,
      comment,
      sourceGuid:
        action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE
          ? documentForEdit?.guid
          : "",
    };

    const result = await addRecord(TABLES.INVOICES, newDocument);
    if (!result) {
      setLoadingSaveButton(false);
      message.error(result.message);
      return false;
    }
    const clearedCurrentOrder = await clearObjectStore(TABLES.CURRENT_INVOICE);
    if (!clearedCurrentOrder) {
      message.error("Помилка очищення поточного документа");
      return false;
    }
    message.success("Збережено");
    return true;
  };

  const operations = (
    <>
      <Dropdown.Button
        loading={loadingSaveButton}
        size="middle"
        icon={<DownOutlined />}
        menu={{
          items,
        }}
        onClick={handleButtonClick}
      >
        <SaveOutlined /> Зберегти
      </Dropdown.Button>
    </>
  );

  const OperationsSlot = {
    right: operations,
  };

  const deftabs = [
    {
      label: TABS_TITLES.INFO,
      key: "1",
      children: (
        <InfoTab
          supplier={supplier}
          setSupplier={setSupplier}
          comment={comment}
          setComment={setComment}
        ></InfoTab>
      ),
    },
    {
      label: TABS_TITLES.PRODUCTS,
      key: "2",
      children: (
        <TableProducts
          settings={props.settings}
          loading={props.loading}
          products={props.products}
          // setProducts={props.setProducts}
          action={action}
          afterChangeProductQty={props.afterChangeProductQty}
          removeProductFromDocument={props.removeProductFromDocument}
          columns={props.columns}
          dbTable={props.dbTable}
        ></TableProducts>
      ),
    },
  ];

  return (
    <>
      <Tabs
        tabBarStyle={{ marginBottom: "5px" }}
        tabBarExtraContent={OperationsSlot}
        defaultActiveKey={
          props.action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE ? "2" : "1"
        }
        size={"middle"}
        items={deftabs}
      />
    </>
  );
};
export default Main;
