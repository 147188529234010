import { getVideoDevices } from "./barcodeScannerAPI";

export class VideoTrackSingleton {
  constructor() {
    if (!VideoTrackSingleton.instance) {
      this.videoTrack = null;
      VideoTrackSingleton.instance = this;
    }

    return VideoTrackSingleton.instance;
  }

  async getTrack() {
    const vDevices = await getVideoDevices();
    const twoDevices = vDevices.slice(-2);

    const backCamera = vDevices.filter((dev) => dev.label.includes("back"))[0];

    const cameraToUse = localStorage.getItem("cameraToUse")
      ? +localStorage.getItem("cameraToUse")
      : 0;

    let deviceId;
    if (backCamera) {
      deviceId = backCamera.deviceId;
    } else {
      deviceId = twoDevices[cameraToUse].deviceId;
    }

    const constrains = {
      video: {
        optional: [
          {
            sourceId: deviceId,
          },
        ],
      },
    };

    const stream = await navigator.mediaDevices.getUserMedia(constrains);

    const videoTrack = stream.getVideoTracks()[0];

    this.videoTrack = videoTrack;

    return videoTrack;
  }

  stop() {
    if (this.videoTrack && this.videoTrack.enabled) {
      this.videoTrack.stop();
      this.videoTrack = null;
    }
  }
}
