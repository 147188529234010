import { message } from "antd";
import React, { useState, useEffect } from "react";
import Main from "../../components/documents/revisions/view/Main";
import { getRecord } from "../../db/dexie-db/functions";
import { getRecordsForTable } from "../../db/documents-funtions";
import {
  getColumnsData,
  getRevisionSettings,
} from "../../components/documents/revisions/functions";
import BottomPageTitle from "../../components/header/BottomPageTitle";
import { useAuth } from "../../hook/useAuth";
import { useParams } from "react-router-dom";
import BackButton from "../../components/UI/BackButton";
import {
  DOCUMENTS_VIEWING_TITLES,
  DOCUMENT_OPEN_ACTIONS,
} from "../../components/documents/_CONSTANTS/constants";
import { getDefaultColumns } from "../../components/documents/revisions/table-products/columns";
import { TABLES } from "../../db/constants/tables";
import { getUserSetting } from "../../api/settings/settings";

export default function RevisionEdit() {
  const { user } = useAuth();

  const useCharacteristics = getUserSetting(
    "useCharacteristics",
    user.settings,
    "global"
  );
  const revisionSettings = getRevisionSettings(user);

  const params = useParams();
  const id = params.id;

  const [document, setDocument] = useState();

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const doc = await getRecord(TABLES.REVISIONS, Number(id));
    if (!doc) {
      message.error("Помилка отримання даних документа");
    }

    const products = doc.products;
    setDocument(doc);
    setProducts(getRecordsForTable(products));
    setLoading(false);
  };

  const defaultColumns = getDefaultColumns({
    settings: revisionSettings,
    handleChangeQty: null,
    useCharacteristics,
  });

  const columns = getColumnsData(defaultColumns, revisionSettings);

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_OPEN_ACTIONS.VIEW}
        columns={columns}
        settings={revisionSettings}
        document={document}
      ></Main>
      <BackButton></BackButton>
      <BottomPageTitle
        title={DOCUMENTS_VIEWING_TITLES.VIEWING_REVISION}
      ></BottomPageTitle>
    </>
  );
}
