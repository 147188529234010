import { message } from "antd";
import { db } from "../../db/dexie-db/db";
import { TABLES } from "../constants/tables";
import dayjs from "dayjs";

export const getRecord = async (objectStore, value) => {
  try {
    return await db[objectStore].get(value);
  } catch (error) {
    return false;
  }
};

export const getRecordByKeyValue = async (objectStore, keyValueObject) => {
  try {
    return await db[objectStore].get(keyValueObject);
  } catch (error) {
    return false;
  }
};

export const getRecordByMyltipleKey = async (objectStore, value) => {
  try {
    const res = await db[objectStore]
      .where("barcodes")
      .equals(value)
      .distinct()
      .toArray();
    return res[0];
  } catch (error) {
    return false;
  }
};

export const getRecordByMyltipleKeyByFieldName = async (
  objectStore,
  value,
  fieldName
) => {
  try {
    const res = await db[objectStore]
      .where(fieldName)
      .equals(value)
      .distinct()
      .toArray();
    return res[0];
  } catch (error) {
    return false;
  }
};

export const getAllRecords = async (objectStore) => {
  try {
    return await db[objectStore].toArray();
  } catch (error) {
    return false;
  }
};

export const getRecords = async (objectStore, index, value) => {
  try {
    return await db[objectStore].where(index).equals(value).toArray();
  } catch (error) {
    return false;
  }
};

export const getRecordsByKeywords = async (objectStore, index, value) => {
  try {
    const collection = db[objectStore].filter((record) =>
      record[index].toLowerCase().includes(value.toLowerCase())
    );
    return await collection.toArray();
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteRecord = async (objectStore, indexField) => {
  try {
    await db[objectStore].delete(indexField);
    return true;
  } catch (error) {
    console.log(error);
    message.error("Помилка видалення товару " + indexField);
    return false;
  }
};

export const clearObjectStore = async (objectStore) => {
  try {
    await db[objectStore].clear();
    return true;
  } catch (error) {
    return false;
  }
};

export const updateRecord = async (objectStore, key, record) => {
  try {
    await db[objectStore].update(key, record);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const bulkPutDataToObjectStore = async (objectStore, records) => {
  try {
    await db[objectStore].bulkPut(records);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updateRecords = async (objectStore, records) => {
  try {
    await Promise.all(
      records.map(async (record) => {
        return updateRecord(objectStore, record.id, record);
      })
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const addRecord = async (objectStore, record) => {
  try {
    return await db[objectStore].add(record);
  } catch (error) {
    console.log(error);
    message.error("Помилка додавання товару " + record.title);
    return false;
  }
};

export const getLastRecordsFromDb = async (objectStore, count) => {
  try {
    return await db[objectStore].reverse().limit(count).toArray();
  } catch (error) {
    console.log(error);
    message.error("Помилка отримання останніх товарів");
    return false;
  }
};

export const setDemoDataToDb = async () => {
  const docInvoice = {
    date: dayjs().format("DD.MM.YYYY"),
    created: {
      $L: "en",
      $d: "2024-02-09T13:13:18.398Z",
      $x: {},
      $y: 2024,
      $M: 1,
      $D: 9,
      $W: 5,
      $H: 15,
      $m: 13,
      $s: 18,
      $ms: 398,
    },
    updated: {
      $L: "en",
      $d: "2024-02-09T13:13:18.398Z",
      $x: {},
      $y: 2024,
      $M: 1,
      $D: 9,
      $W: 5,
      $H: 15,
      $m: 13,
      $s: 18,
      $ms: 398,
    },
    exported: "false",
    docBarcode: "2374843983980",
    products: [
      {
        index: 1,
        art: "12724",
        guid: "f18f964d-34f6-11e5-80a9-902b346e2041",
        barcodes: ["5449000050212"],
        title: "КОКА-КОЛА  1,5л  ПЕТ Light",
        price: 1,
        stockQty: 1,
        countForExport: 1,
        isWeight: false,
        parentCode: "РТ000006418",
        color: "0,0,0",
        id: 83660,
        qty: 3,
        timeAdded: 1707484394544,
        key: 1707484394544,
      },
    ],
    supplierGuid: "1c966420-0ee9-11eb-bac3-001999ab75c1",
    sum: 3,
    comment: "",
    sourceGuid: "",
    id: 1,
  };

  const docRevision = {
    date: dayjs().format("DD.MM.YYYY"),
    created: {
      $L: "en",
      $d: "2024-02-09T13:04:02.195Z",
      $x: {},
      $y: 2024,
      $M: 1,
      $D: 9,
      $W: 5,
      $H: 15,
      $m: 4,
      $s: 2,
      $ms: 195,
    },
    updated: {
      $L: "en",
      $d: "2024-02-09T13:04:02.195Z",
      $x: {},
      $y: 2024,
      $M: 1,
      $D: 9,
      $W: 5,
      $H: 15,
      $m: 4,
      $s: 2,
      $ms: 195,
    },
    exported: "false",
    products: [
      {
        index: 1,
        art: "12248",
        guid: "a33e5810-30b8-11e3-804f-902b346e2041",
        barcodes: ["46135360"],
        title: "ЦИГ Прима Оптима  #6",
        price: 1,
        stockQty: 1,
        countForExport: 1,
        isWeight: false,
        parentCode: "РТ000006369",
        color: "0,0,0",
        id: 54483,
        qty: 3,
        timeAdded: 1707483824634,
        key: 1707483824634,
      },
      {
        index: 2,
        art: "4557",
        guid: "fadbff28-2f81-11e5-80a9-902b346e2041",
        barcodes: ["4820179281392"],
        title: "Лонгміксер 0,5 Кактус ж/б",
        price: 1,
        stockQty: 1,
        countForExport: 1,
        isWeight: false,
        parentCode: "РТ000008189",
        color: "0,0,0",
        id: 54488,
        qty: 2,
        timeAdded: 1707483828709,
        key: 1707483828709,
      },
      {
        index: 3,
        art: "11963",
        guid: "e3bf1c8d-31c6-11e5-80a9-902b346e2041",
        barcodes: ["4820001988888"],
        title: "ЦИГ Прилуки Преміум Demi",
        price: 1,
        stockQty: 1,
        countForExport: 1,
        isWeight: false,
        parentCode: "РТ000006367",
        color: "0,0,0",
        id: 54489,
        qty: 1,
        timeAdded: 1707483838622,
        key: 1707483838622,
      },
    ],
    sum: 3,
    comment: "",
    docBarcode: "2374838421954",
    id: 1,
  };

  const docOrder1 = {
    id: 100039,
    docBarcode: null,
    products: [
      {
        art: "12724",
        guid: "f18f964d-34f6-11e5-80a9-902b346e2041",
        barcodes: ["5449000050212"],
        title: "КОКА-КОЛА  1,5л  ПЕТ Light",
        price: 0,
        stockQty: 1,
        countForExport: 1,
        isWeight: false,
        parentCode: "РТ000006418",
        color: "0,0,0",
        id: 118664,
        qty: 10,
      },
      {
        art: "12725",
        guid: "f18f964f-34f6-11e5-80a9-902b346e2041",
        barcodes: ["5449000958921"],
        title: "Чай NESTEA  1л  Лесная ягода",
        price: 46.45,
        stockQty: 1,
        countForExport: 1,
        isWeight: false,
        parentCode: "РТ000006418",
        color: "0,0,0",
        id: 118665,
        qty: 36,
      },
      {
        art: "11469",
        guid: "a7c34778-39a8-11e5-80a9-902b346e2041",
        barcodes: ["4820029431328"],
        title: "ОБОЛОНЬ  Живчик яблуко газ 0,33л",
        price: 24.17,
        stockQty: 1,
        countForExport: 1,
        isWeight: false,
        parentCode: "РТ000006336",
        color: "0,0,0",
        id: 118666,
        qty: 6,
      },
    ],
    supplierGuid: "32bf687b-e29f-11e5-8a00-902b346e2041",
    sum: 1817.02,
    comment: [],
    dateTime: "08.02.2024 10:48:09",
    guid: "22342c65-adf6-11ee-8c36-005056be7e4e",
    supplierTeam: "Кока кола",
    imported: "true",
    exported: "false",
    created: {
      $L: "en",
      $d: "2024-02-08T08:48:09.000Z",
      $y: 2024,
      $M: 1,
      $D: 8,
      $W: 4,
      $H: 10,
      $m: 48,
      $s: 9,
      $ms: 0,
    },
    updated: {
      $L: "en",
      $d: "2024-02-08T08:48:09.000Z",
      $y: 2024,
      $M: 1,
      $D: 8,
      $W: 4,
      $H: 10,
      $m: 48,
      $s: 9,
      $ms: 0,
    },
    date: dayjs().format("DD.MM.YYYY"),
  };
  const docOrder2 = {
    id: 100040,
    docBarcode: null,
    products: [
      {
        art: "11470",
        guid: "a7c3477a-39a8-11e5-80a9-902b346e2041",
        barcodes: ["4820029431311"],
        title: "ОБОЛОНЬ  Живчик яблуко негазований 0,33л",
        price: 10,
        stockQty: 1,
        countForExport: 1,
        isWeight: false,
        parentCode: "РТ000006336",
        color: "0,0,0",
        id: 118667,
        qty: 10,
      },
      {
        art: "11461",
        guid: "a7c3477c-39a8-11e5-80a9-902b346e2041",
        barcodes: ["4820000199759"],
        title: "Вода ОБОЛОНЬ Лимон Апельсин 1л",
        price: 15,
        stockQty: 1,
        countForExport: 1,
        isWeight: false,
        parentCode: "РТ000006332",
        color: "0,0,0",
        id: 118668,
        qty: 20,
      },
      {
        art: "11471",
        guid: "a7c34780-39a8-11e5-80a9-902b346e2041",
        barcodes: ["4820029431397"],
        title: "Прозора з екст. зеленого чаю та см. персика 0,5н/г",
        price: 25,
        stockQty: 1,
        countForExport: 1,
        isWeight: false,
        parentCode: "РТ000006336",
        color: "0,0,0",
        id: 118670,
        qty: 6,
      },
    ],
    supplierGuid: "1c966420-0ee9-11eb-bac3-001999ab75c1",
    sum: 550,
    comment: [],
    dateTime: "08.02.2024 10:48:09",
    guid: "22342c65-adf6-11ee-8c36-005056be7e4b",
    supplierTeam: "Головний склад",
    imported: "true",
    exported: "false",
    created: {
      $L: "en",
      $d: "2024-02-08T08:48:09.000Z",
      $y: 2024,
      $M: 1,
      $D: 8,
      $W: 4,
      $H: 10,
      $m: 48,
      $s: 9,
      $ms: 0,
    },
    updated: {
      $L: "en",
      $d: "2024-02-08T08:48:09.000Z",
      $y: 2024,
      $M: 1,
      $D: 8,
      $W: 4,
      $H: 10,
      $m: 48,
      $s: 9,
      $ms: 0,
    },
    date: dayjs().format("DD.MM.YYYY"),
  };

  try {
    await db[TABLES.REVISIONS].add(docRevision);
  } catch (error) {}
  try {
    await db[TABLES.INVOICES].add(docInvoice);
  } catch (error) {}

  try {
    await db[TABLES.ORDERS].add(docOrder1);
    await db[TABLES.ORDERS].add(docOrder2);
  } catch (error) {}
};
