import React, { useState } from "react";
import dayjs from "dayjs";
import { Table, Checkbox } from "antd";
import ModalActions from "./ModalActions";
import {
  BarcodeOutlined,
  CloudUploadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import DatePickerDropDown from "../../../filters/DatePickerDropDown";
import { LOCALIZATION } from "../../_CONSTANTS/constants";

export default function (props) {
  const [isModalActionsOpen, setIsModalActionsOpen] = useState(false);
  const [document, setDocument] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const defaultColumns = [
    {
      title: "№",
      dataIndex: "index",
      key: "index",
      width: "13%",
      sorter: (a, b) => a.index - b.index,
      defaultSortOrder: "descend",
    },
    {
      title: "Дата",
      dataIndex: "created",
      width: "20%",
      key: "created",
      render: (_, record) => {
        const created = dayjs(record.created.$d).format("DD.MM HH:mm");
        return created;
      },
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <DatePickerDropDown
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
            ></DatePickerDropDown>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined></SearchOutlined>;
      },
      onFilter: (value, record) => {
        return dayjs(record.created.$d).isSame(value.$d, "day");
      },
    },
    {
      title: "Контрагент",
      dataIndex: "supplierName",
      key: "supplierName",
      width: "45%",
    },
    {
      title: "Сума",
      dataIndex: "sum",
      key: "sum",
      width: "20%",
      render: (_, record) => {
        return Number(record.sum).toFixed(2);
      },
    },
    {
      title: <BarcodeOutlined />,
      dataIndex: "processed",
      key: "processed",
      width: "10%",
      render: (_, record) => {
        return (
          <Checkbox
            disabled
            checked={record.processed ? true : false}
          ></Checkbox>
        );
      },
    },
    {
      title: <CloudUploadOutlined />,
      dataIndex: "exported",
      key: "exported",
      width: "10%",
      render: (_, record) => {
        return (
          <Checkbox
            id={record.index}
            disabled
            checked={record.exported === "true" ? true : false}
          ></Checkbox>
        );
      },
    },
  ];

  const columns = [
    {
      title: "",
      dataIndex: "supplierName",
      key: "supplierName",
      width: "100%",
    },
  ];

  const expandedRowRender = (row) => {
    const childColumns = [
      {
        title: "Дата",
        dataIndex: "created",
        width: "15%",
        key: "created",
        render: (_, record) => {
          const created = dayjs(record.created.$d).format("DD.MM HH:mm");
          return created;
        },
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
      },
      {
        title: props?.useCheckingOrdersBarcodes
          ? LOCALIZATION.UK.kontragent
          : LOCALIZATION.UK.supplierTeam,
        dataIndex: "supplierTeam",
        key: "supplierTeam",
        width: "40%",
        render: (_, record) => {
          if (!props?.useCheckingOrdersBarcodes) return record.supplierTeam;
          const resString = String(record.supplierTeam) ? (
            <span>
              {record.supplierTeam}
              <br></br>
              {record.id}
            </span>
          ) : (
            record.id
          );
          return resString;
        },
      },
      {
        title: "Сума",
        dataIndex: "sum",
        key: "sum",
        width: "15%",
        render: (_, record) => {
          return Number(record.sum).toFixed(2);
        },
      },
      {
        title: <BarcodeOutlined />,
        dataIndex: "processed",
        key: "processed",
        width: "7%",
        render: (_, record) => {
          return (
            <Checkbox
              disabled
              checked={record.processed ? true : false}
            ></Checkbox>
          );
        },
      },
      {
        title: <CloudUploadOutlined />,
        dataIndex: "exported",
        key: "exported",
        width: "7%",
        render: (_, record) => {
          return (
            <Checkbox
              disabled
              checked={record.exported === "true" ? true : false}
            ></Checkbox>
          );
        },
      },
    ];

    const data = [...row.documents];
    const dataToTable = data.map((record) => {
      return {
        ...record,
        supplierTeam: record?.supplierTeam
          ? record.supplierTeam
          : row.supplierName,
      };
    });
    return (
      <Table
        columns={getDefaultColumns(
          props?.useCheckingOrdersBarcodes,
          childColumns
        )}
        onRow={onRowAction}
        dataSource={dataToTable}
        pagination={false}
        tableLayout="fixed"
        rowClassName={(record, index) => {
          return record.imported === "true" ? "imported" : "";
        }}
      />
    );
  };

  const onRowAction = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setIsModalActionsOpen(true);
        setDocument(record);
        // setDocumentNumber(record.index);
      },
    };
  };

  const onTableRowExpand = (expanded, record) => {
    const keys = [];
    if (expanded) {
      keys.push(record.key);
    }
    setExpandedRowKeys(keys);
  };

  const getDefaultColumns = (useCheckingOrdersBarcodes, defaultColumns) => {
    if (useCheckingOrdersBarcodes) {
      return defaultColumns;
    } else {
      return defaultColumns.filter(
        (column) => column.dataIndex !== "processed"
      );
    }
  };

  return (
    <>
      {props?.useDefaultTableType ? (
        <Table
          loading={props.loading}
          pagination={{ position: ["bottomCenter"], hideOnSinglePage: true }}
          onRow={onRowAction}
          rowClassName={(record, index) => {
            if (props.useCheckingOrdersBarcodes) {
              return record?.processed ? "imported" : "";
            }

            return record.imported ? "imported" : "";
          }}
          columns={getDefaultColumns(
            props?.useCheckingOrdersBarcodes,
            defaultColumns
          )}
          dataSource={props.documents}
          size={"middle"}
          tableLayout="fixed"
        />
      ) : (
        <Table
          loading={props.loading}
          pagination={{ position: ["bottomCenter"], hideOnSinglePage: true }}
          columns={columns}
          dataSource={props.documents}
          size={"middle"}
          tableLayout={"fixed"}
          expandable={{
            expandedRowRender,
            expandRowByClick: true,
            expandedRowKeys,
            onExpand: onTableRowExpand,
          }}
        />
      )}
      <ModalActions
        document={document}
        setRecord={setDocument}
        setDocumentsToTable={props.setDocumentsToTable}
        setIsModalActionsOpen={setIsModalActionsOpen}
        isModalActionsOpen={isModalActionsOpen}
        useCheckingOrdersBarcodes={props.useCheckingOrdersBarcodes}
      ></ModalActions>
    </>
  );
}
