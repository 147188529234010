import { Col, Divider, Row, Select } from "antd";
import Input from "antd/es/input/Input";
import React, { useEffect, useState } from "react";
import { getAllRecords } from "../../../../db/dexie-db/functions";

export default function InfoTab(props) {
  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const onChange = (value) => {
    props.setSupplier(value);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const getData = async () => {
    const suppliersFromDb = await getAllRecords("Suppliers");
    const suppliersToSelect = getSuppliersForSelect(suppliersFromDb);
    setSuppliers(suppliersToSelect);
  };

  const getSuppliersForSelect = (suppliersFromDb) => {
    return suppliersFromDb.map((supplier) => {
      return { value: supplier.guid, label: supplier.title };
    });
  };

  return (
    <>
      <Row style={{ padding: "20px" }}>
        <Col offset={2} span={20}>
          <Select
            style={{ width: "100%" }}
            size={"large"}
            showSearch
            placeholder="Виберіть постачальника"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={suppliers}
            value={props?.supplier}
            disabled={props?.disabledFields}
          />
          <Divider style={{ margin: "10px" }}></Divider>
          <Input
            style={{ width: "100%" }}
            size={"large"}
            placeholder="Номер"
            value={props?.document.id}
            disabled={true}
            onChange={(value) => {
              props.setComment(value.target.value);
            }}
          ></Input>
          <Divider style={{ margin: "10px" }}></Divider>
          <Input
            style={{ width: "100%" }}
            size={"large"}
            placeholder="Коментар"
            value={props?.comment}
            disabled={props?.disabledFields}
            onChange={(value) => {
              props.setComment(value.target.value);
            }}
          ></Input>
        </Col>
      </Row>
    </>
  );
}
