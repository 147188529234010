import { Col, Row, Table } from "antd";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import React from "react";
import { TableTotals } from "../../../table-utils/TableTotals";
import { getCharacteristicTotals } from "../../../../utils/getTableTotals";
import { sortArrayOfObjectsByTitle } from "../../../../utils/array-functions";

export default function TableCharacteristics(props) {
  const characteristics = props?.characteristics;

  if (!characteristics) return;

  const incrementQty = (e) => {
    const inputElement = getActieInputValue(e);
    const value = +inputElement.value;
    const newValue = value + 1;
    inputElement.value = newValue;
    const characteristicGuid = e.target.getAttribute("guid");
    const currentCharacteristic = characteristics.find(
      (char) => char.guid === characteristicGuid
    );

    currentCharacteristic.qty = +newValue;
    updateCharacteristics(currentCharacteristic);
  };

  const getActieInputValue = (e) => {
    const parentNode = e.currentTarget.parentNode;
    const inputElement = parentNode.querySelector(".inputQty");
    return inputElement;
  };

  const decrementQty = (e) => {
    const inputElement = getActieInputValue(e);
    const value = +inputElement.value;
    const newValue = value - 1;
    inputElement.value = newValue;
    const characteristicGuid = e.target.getAttribute("guid");
    const currentCharacteristic = characteristics.find(
      (char) => char.guid === characteristicGuid
    );

    currentCharacteristic.qty = +newValue;
    updateCharacteristics(currentCharacteristic);
  };

  const updateCharacteristics = (currentCharacteristic) => {
    var index = characteristics.indexOf(currentCharacteristic);

    if (index !== -1) {
      characteristics[index] = currentCharacteristic;
    }
    props.setCharacteristics([...characteristics]);
  };
  const columns = [
    {
      key: "title",
      title: "Дані",
      dataIndex: "title",
      width: "60%",
      sorter: (a, b) => sortArrayOfObjectsByTitle(a, b),
      render: (_, record) => {
        return (
          <>
            <Row style={{ fontSize: "13px" }}>{`${record.title}`}</Row>
            <Row style={{ verticalAlign: "center" }}>
              <Col
                style={{ fontSize: "13px" }}
                span={12}
              >{`Ціна: ${(+record.price).toFixed(2)}`}</Col>
              <Col
                style={{ fontSize: "13px" }}
                span={12}
              >{`Облік: ${(+record.stockQty).toFixed(2)}`}</Col>
            </Row>
          </>
        );
      },
    },
    {
      key: "qty",
      title: "К-ть факт",
      dataIndex: "qty",
      width: "40%",
      sorter: (a, b) => a.qty - b.qty,
      render: (_, record) => {
        return (
          <>
            <Row>
              <InputGroup>
                <InputGroup.Text guid={record.guid} onClick={decrementQty}>
                  -
                </InputGroup.Text>
                <Form.Control
                  className="inputQty"
                  type="tel"
                  placeholder="К-ть"
                  guid={record.guid}
                  value={record?.qty ? record.qty : ""}
                  autoFocus={false}
                  onInput={(e) => {
                    const value = e.target.value;
                    const formattedValue = value.replace(/,/g, ".");
                    let enteredQty = formattedValue;
                    const characteristicGuid = e.target.getAttribute("guid");
                    const currentCharacteristic = characteristics.find(
                      (char) => char.guid === characteristicGuid
                    );

                    currentCharacteristic.qty = enteredQty;
                    updateCharacteristics(currentCharacteristic);
                  }}
                />
                <InputGroup.Text guid={record.guid} onClick={incrementQty}>
                  +
                </InputGroup.Text>
              </InputGroup>
            </Row>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        pagination={{
          position: ["bottomCenter"],
          hideOnSinglePage: true,
        }}
        dataSource={characteristics}
        size={"small"}
        title={() => {
          const totals = getCharacteristicTotals(
            characteristics,
            props?.showTotalCharacteristicsByDiff
          );
          return (
            <>
              <TableTotals
                quantity={totals.quantity.toFixed(3)}
                total={totals.total.toFixed(2)}
                productsCount={
                  characteristics.length ? characteristics.length : 0
                }
              />
            </>
          );
        }}
        // loading={props.loading}
      />
    </>
  );
}
