import { InfoCircleOutlined, OrderedListOutlined } from "@ant-design/icons";

export const DOCUMENT_OPEN_ACTIONS = {
  CREATE: "CREATE",
  CREATE_FROM_SOURCE: "createFromSource",
  EDIT: "EDIT",
  VIEW: "VIEW",
  VERIFY: "VERIFY",
};

export const DOCUMENTS_TITLES = {
  REVISION: "Ревізія",
  INVOICE: "Прихід",
  DIPLACEMENT: "Переміщення",
  ORDER: "Замовлення",
  SALE: "Реалізація",
  RETURN: "Повернення",
  CHECK_PRICE: "Перевірка цін",
  VERIFICATION: "Перевірка документів",
  BARCODING: "Штрихкодування",
  EDITING_BARCODING: "Редагування штрих-кодів",
};

export const DOCUMENTS_EDITING_TITLES = {
  EDITING_REVISION: "Редагування ревізії",
  EDITING_INVOICE: "Редагування приходу",
  EDITING_DIPLACEMENT: "Редагування переміщення",
  EDITING_ORDER: "Редагування замовлення",
  EDITING_SALE: "Редагування реалізації",
  EDITING_RETURN: "Редагування повернення",
  EDITING_CHECK_PRICE: "Редагування перевірки цін",
};

export const DOCUMENTS_VIEWING_TITLES = {
  VIEWING_REVISION: "Перегляд ревізії",
  VIEWING_INVOICE: "Перегляд приходу",
  VIEWING_DIPLACEMENT: "Перегляд переміщення",
  VIEWING_ORDER: "Перегляд замовлення",
  VIEWING_SALE: "Перегляд реалізації",
  VIEWING_RETURN: "Перегляд повернення",
  VIEWING_CHECK_PRICE: "Перегляд перевірки цін",
  VIEWING_VERIFICATION: "Перегляд перевірки",
  VIEWING_BARCODING: "Перегляд штрих-кодів",
};

export const DOCUMENT_BARCODE_PREFIX = "23";

export const TABS_TITLES = {
  INFO: (
    <span>
      <InfoCircleOutlined /> Інфо
    </span>
  ),
  PRODUCTS: (
    <span>
      <OrderedListOutlined /> Товари
    </span>
  ),
};

export const LOCALSTORAGE_VARIABLES = {
  EDITING_REVISION_ID: "editingRevisionId",
};

export const LOCALIZATION = {
  UK: {
    supplierTeam: "Команда",
    kontragent: "Контрагент",
    supplier: "Постачальник",
  },
};

export const PAYMENT_TYPES = {
  CASH: "Готівка",
  CARD: "Картка",
  DEBT: "Борг",
};

export const CHARACTERISTICS_ACTION = {
  EDIT: "edit",
  ADD: "add",
};
