import {
  CameraOutlined,
  BarcodeOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { FloatButton } from "antd";
import React from "react";
import MenuComponent from "../menu/Menu";
import Data from "../menu/Menu";
import BackButton from "../../../UI/BackButton";
import { DOCUMENT_OPEN_ACTIONS } from "../../_CONSTANTS/constants";

function Footer(props) {
  const handleChangeModalProductOpen = () => {
    props.setIsModalProductOpen(true);
  };

  const handleChangeModalBarcodeReaderOpen = () => {
    props.setIsModalCameraBarcodeReaderOpen(true);
  };

  const handleChangeModalChooseProductsOpen = () => {
    props.setIsModalChooseProductsOpen(true);
  };

  const handleChangeModalChooseCategoriesOpen = () => {
    props.setIsModalChooseCategoriesOpen(true);
  };

  return (
    <>
      <MenuComponent
        settings={props?.settings}
        setSettings={props?.setSettings}
        data={Data}
      ></MenuComponent>
      <BackButton></BackButton>
      <FloatButton.Group
        trigger="click"
        type="primary"
        icon={<PlusCircleOutlined />}
      >
        <FloatButton
          onClick={handleChangeModalProductOpen}
          icon={<BarcodeOutlined />}
        />
        <FloatButton
          onClick={handleChangeModalBarcodeReaderOpen}
          icon={<CameraOutlined />}
        />
        <FloatButton
          onClick={handleChangeModalChooseProductsOpen}
          icon={<UnorderedListOutlined />}
        />
        {props?.action !== DOCUMENT_OPEN_ACTIONS.EDIT ? (
          <FloatButton
            onClick={handleChangeModalChooseCategoriesOpen}
            icon={<TableOutlined />}
          />
        ) : (
          ""
        )}
      </FloatButton.Group>
    </>
  );
}

export default Footer;
