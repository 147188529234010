import { Col, InputNumber, Input, Row, Space, message, Switch } from "antd";
import React, { useEffect, useState } from "react";
import {
  setUpdatedUserWithNewSettings,
  getUserSetting,
  saveWeightTemplate,
  updateUserSettInSql,
} from "../../api/settings/settings";
import { getVideoDevices } from "../../components/camera-barcode-reader/default/barcodeScannerAPI";
import { useTheme } from "../../hook/useTheme";
import { getStorageSpace } from "../../utils/otherFunctions";

const { Search } = Input;

export default function Global({ user, updateCurrentUser }) {
  const { toggleThemeMode } = useTheme();

  const daysForStoringDocuments = getUserSetting(
    "daysForStoringDocuments",
    user.settings,
    "global"
  );

  const darkMode = getUserSetting("darkMode", user.settings, "global");

  const [days, setDays] = useState(daysForStoringDocuments);
  const [isLoading, setIsLoading] = useState(false);

  const [cameraToUse, setCameraToUse] = useState(
    !localStorage.getItem("cameraToUse")
      ? 0
      : +localStorage.getItem("cameraToUse")
  );
  const [isBackCameraFound, setIsBackCameraFound] = useState(false);

  const [useDarkMode, setUseDarkMode] = useState(darkMode);

  useEffect(() => {
    getVideoDevicesData();
    checkStorage();
  }, []);

  const checkStorage = async () => {
    const storageSpace = await getStorageSpace(user.username);
    if (!storageSpace) return;
    let textMessage = `Використано пам'яті: ${storageSpace.usedMegaBytes} Мб.`;
    const storageInfoP = document.getElementById("storage-info-p");
    if (storageSpace.usedMegaBytes > 30) {
      storageInfoP.style = "color: yellow";
    } else if (storageSpace.usedMegaBytes > 150) {
      storageInfoP.style = "color: red";
      message.textMessage =
        textMessage + ` Необхідно очистити дані у налаштуваннях браузера!`;
    }
    storageInfoP.textContent = textMessage;
  };

  const getVideoDevicesData = async () => {
    const vDevices = await getVideoDevices();
    const backCamera = vDevices.filter((dev) => dev.label.includes("back"))[0];
    if (backCamera?.deviceId) setIsBackCameraFound(true);
  };

  const onChangeUseDarkMode = async (value) => {
    toggleThemeMode(value);
    setUseDarkMode(value);

    const res = await updateUserSettInSql(user, "darkMode", value, "global");
    if (res) {
      message.success("Оновлено!");
      setUpdatedUserWithNewSettings(
        user.settings,
        "darkMode",
        value,
        "global",
        user,
        updateCurrentUser
      );
    }
  };

  const saveTemplate = async (value) => {
    if (!value) {
      return message.error("Введіть шаблон!");
    }
    setIsLoading(true);
    const updatedLoggedInUser = { ...user, weightTemplate: value };
    updateCurrentUser(updatedLoggedInUser);
    // sessionStorage.setItem("user", JSON.stringify(updatedLoggedInUser));
    const result = await saveWeightTemplate(user.id, value);
    setIsLoading(false);
    if (result) {
      return message.success("Збережено");
    }
    return message.error("Помилка!");
  };

  const updateDaysForStingDocuments = async (days) => {
    setDays(days);
    const res = await updateUserSettInSql(
      user,
      "daysForStoringDocuments",
      days,
      "global"
    );
    if (res) {
      message.success("Оновлено!");
    }
  };

  const checkIsWebApiSupport = () => {
    if (!("BarcodeDetector" in window)) {
      return false;
    } else {
      return true;
    }
  };

  const isWebApiSupported = checkIsWebApiSupport();

  const changeCameraNum = (value) => {
    localStorage.setItem("cameraToUse", value ? 1 : 0);
    setCameraToUse(value ? 1 : 0);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <p style={{ margin: "0px" }}>Зберігати документи:</p>
          <InputNumber
            addonAfter={<span style={{ padding: 10 }}>днів</span>}
            onChange={(e) => {
              updateDaysForStingDocuments(e);
            }}
            max={15}
            min={3}
            defaultValue={days ? +days : 7}
            value={days}
            type="tel"
          ></InputNumber>
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col span={24}>
          <Space direction="vertical">
            <p style={{ margin: "0px" }}>
              Шаблон штрихкоду для вагового товару
              <br />
              Приклад: 21QQQQQPPPPP <br />
              (21- префікс, PPPPP - код товару, QQQQQ - вага товару)
            </p>
            <Search
              style={{ width: "max-content" }}
              defaultValue={user.weightTemplate}
              enterButton="Зберегти"
              allowClear
              onSearch={saveTemplate}
              loading={isLoading}
            />
          </Space>
        </Col>
      </Row>
      <br></br>
      {isWebApiSupported && !isBackCameraFound ? (
        <>
          <Row>
            <Col span={24}>
              <Space direction="vertical">
                <p style={{ margin: "0px" }}>Використовувати камеру:</p>
                <Switch
                  onChange={changeCameraNum}
                  checkedChildren="Камера 1"
                  unCheckedChildren="Камера 2"
                  checked={cameraToUse}
                ></Switch>
              </Space>
            </Col>
          </Row>
          <br></br>
        </>
      ) : (
        ""
      )}
      <Row>
        <Col span={24}>
          <Space direction="horizontal">
            <Switch
              checked={useDarkMode}
              size="default"
              onChange={(value) => onChangeUseDarkMode(value)}
            />
            Нічний режим
          </Space>
        </Col>
      </Row>
      <br></br>
      <br></br>
      <Row>
        <Col span={24}>
          <p style={{ margin: "0px" }} id="storage-info-p"></p>
        </Col>
      </Row>
    </>
  );
}
