import { message } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Main from "../../components/documents/invoices/view/Main";
import { getRecord } from "../../db/dexie-db/functions";
import { getRecordsForTable } from "../../db/documents-funtions";
import BackButton from "../../components/UI/BackButton";
import BottomPageTitle from "../../components/header/BottomPageTitle";
import { TABLES } from "../../db/constants/tables";
import {
  DOCUMENTS_VIEWING_TITLES,
  DOCUMENT_OPEN_ACTIONS,
} from "../../components/documents/_CONSTANTS/constants";
import { useAuth } from "../../hook/useAuth";
import { getServiceSettingsObject } from "../../api/settings/settings";
import { getDefaultInvoiceColumns } from "../../components/documents/manage-documents/table-components/documents-columns";

export default function ViewReturn() {
  const { user } = useAuth();
  const invoiceSettings = getServiceSettingsObject(user.settings, "invoice");
  const defaultInvoiceColumns = getDefaultInvoiceColumns(invoiceSettings);

  const params = useParams();
  const navigate = useNavigate();
  const id = params.id;
  const [document, setDocument] = useState();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const doc = await getRecord(TABLES.INVOICES, Number(id));
    if (!doc) {
      message.error("Помилка отримання даних документа");
    }
    const products = doc.products;
    setDocument(doc);
    const badProducts = products.filter((prod) => !prod.title || !prod.guid);
    if (badProducts.length) {
      message.error(
        "Помилка отримання товарів з документу, перевірте правильність вигрузки!"
      );
      return navigate(`/invoices`);
    }
    setProducts(getRecordsForTable(products));
    setLoading(false);
  };

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_OPEN_ACTIONS.VIEW}
        document={document}
        columns={defaultInvoiceColumns}
      ></Main>
      <BackButton></BackButton>
      <BottomPageTitle
        title={DOCUMENTS_VIEWING_TITLES.VIEWING_INVOICE}
      ></BottomPageTitle>
    </>
  );
}
