import { getServer } from "../utils/Environment";

const server = getServer();

const url = server + "/server/server.php";
// const urlMonthly = server + "/server/statistics/statistics-monthly.php";
const messageUrl = server + "/server/server.php";

export async function statistics(user, serviceName) {
  let operation;
  switch (serviceName) {
    case "Перевірка цін":
      operation = "checkPrice";
      break;
    case "Перевірки цін":
      operation = "checkPrice";
      break;
    case "Переміщення":
      operation = "diplacements";
      break;
    case "Прихід":
      operation = "invoice";
      break;
    case "Приходи":
      operation = "invoice";
      break;
    case "Замовлення":
      operation = "order";
      break;
    case "Повернення":
      operation = "returns";
      break;
    case "Ревізія":
      operation = "revizia";
      break;
    case "Ревізії":
      operation = "revizia";
      break;
    case "Реалізація":
      operation = "realization";
      break;
    case "Перевірка документа":
      operation = "verification";
      break;
    case "Штрих-коди":
      operation = "barcoding";
      break;
    default:
  }
  try {
    const result = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        username: user.username,
        company: user.company,
        operation,
        action: "statistics",
      }),
    });
    // const resultMonthly = await fetch(urlMonthly, {
    //   method: "POST",
    //   body: JSON.stringify({ company: userCompany, operation }),
    // });
    const res = await result.json();
    // const resMonthly = await resultMonthly.json();
    return res;
  } catch (error) {
    // console.log(error);
    // throw error;
    // return false;
  }
}

export async function sendTGMessage(message) {
  try {
    const result = await fetch(messageUrl, {
      method: "POST",
      body: JSON.stringify({ message, action: "sendTGMessage" }),
    });
    const res = await result.json();
    return res;
  } catch (error) {
    console.log(error);
    // throw error;
    // return false;
  }
}

export async function sendTGMessageTolPrivate(message) {
  try {
    const result = await fetch(messageUrl, {
      method: "POST",
      body: JSON.stringify({ message, action: "sendTGMessageToPrivate" }),
    });
    const res = await result.json();
    return res;
  } catch (error) {
    console.log(error);
    // throw error;
    // return false;
  }
}
