import React, { useEffect } from "react";
import { scanBarcode, stopScanning } from "./barcodeScannerAPI";

export default function ScannerDefault(props) {
  useEffect(() => {
    toggleScanning(props.isScanning);
  }, [props.isScanning]);

  async function toggleScanning(isScanning) {
    if (isScanning) {
      const barcode = await scanBarcode();
      props.setBarcodeForSearch(barcode);
      return;
    }
    await stopScanning();
  }
}
