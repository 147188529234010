import { Tabs } from "antd";
import { useState, useEffect } from "react";
import TableProducts from "../create/TableProducts";
import InfoTab from "../create/InfoTab";
import { OrderedListOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { TABS_TITLES } from "../../_CONSTANTS/constants";

const Main = (props) => {
  const documentForEdit = props?.document;
  const [isReturn, setIsReturn] = useState();
  const [supplierGuid, setSupplierGuid] = useState();
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (props?.document) {
      setComment(documentForEdit?.comment);
      setIsReturn(documentForEdit?.isReturn);
      setSupplierGuid(documentForEdit?.supplierGuid);
    }
  }, [props?.document]);

  const deftabs = [
    {
      label: TABS_TITLES.INFO,
      key: "1",
      children: (
        <InfoTab
          disabledFields={true}
          comment={documentForEdit?.comment}
          setComment={setComment}
          supplierGuid={supplierGuid}
          payType={documentForEdit?.payType}
        ></InfoTab>
      ),
    },
    {
      label: TABS_TITLES.PRODUCTS,
      key: "2",
      children: (
        <TableProducts
          loading={props.loading}
          products={props.products}
          setProducts={props.setProducts}
          disabledFields={true}
          settings={props.settings}
          action={props.action}
          setIsReturn={setIsReturn}
          isReturn={isReturn}
          columns={props.columns}
        ></TableProducts>
      ),
    },
  ];

  return (
    <>
      <Tabs
        tabBarStyle={{ marginBottom: "5px" }}
        defaultActiveKey="2"
        size={"middle"}
        items={deftabs}
      />
    </>
  );
};
export default Main;
