import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { message, Tabs } from "antd";
import BackButton from "../../components/UI/BackButton";
import TableProducts from "../../components/documents/check-price/check-price-create/TableProducts";
import { OrderedListOutlined } from "@ant-design/icons";
import { getRecord } from "../../db/dexie-db/functions";
import { TABLES } from "../../db/constants/tables";
import { DOCUMENT_OPEN_ACTIONS } from "../../components/documents/_CONSTANTS/constants";
import { useAuth } from "../../hook/useAuth";
import { getServiceSettingsObject } from "../../api/settings/settings";
import { servicesSettingsTitles } from "../../components/documents/_CONSTANTS/settings";
import { getCheckPriceColumns } from "../../components/documents/manage-documents/table-components/documents-columns";

const DOCUMENT_ACTION = DOCUMENT_OPEN_ACTIONS.VIEW;

export default function CheckPriceView() {
  const params = useParams();
  const id = params.id;
  const { user } = useAuth();
  const checkPriceSettings = getServiceSettingsObject(
    user.settings,
    servicesSettingsTitles.CHECK_PRICE
  );
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [settings, setSettings] = useState(checkPriceSettings);

  useEffect(() => {
    const getData = async () => {
      const doc = await getRecord(TABLES.CHECK_PRICE, Number(id));
      if (!doc) {
        return message.error("Помилка отримання документа №" + id);
      }
      const products = doc.products;
      if (!products) {
        return message.error("Помилка отримання товарів з документа");
      }
      setProducts(products);
      setLoading(false);
    };

    getData();
  }, []);

  const checkPriceColumns = getCheckPriceColumns(checkPriceSettings);

  const productsTableTab = {
    label: (
      <span>
        <OrderedListOutlined /> Перевірка цін
      </span>
    ),
    key: "checkPrice",
    children: (
      <TableProducts
        disabledFields={true}
        loading={loading}
        products={products}
        action={DOCUMENT_OPEN_ACTIONS.VIEW}
        settings={settings}
        columns={checkPriceColumns}
      ></TableProducts>
    ),
  };

  const tabs = [productsTableTab];

  return (
    <>
      <Tabs items={tabs} tabBarStyle={{ padding: "0px 10px" }}></Tabs>
      <BackButton></BackButton>
    </>
  );
}
