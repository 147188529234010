import ReactGA from "react-ga4";
ReactGA.initialize("G-ECRPCNCTB8");

export function registerGAEvent({ category, action, label }) {
  ReactGA.event({
    category: "Компанія: " + category,
    action,
    label,
  });
}
