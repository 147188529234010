import React from "react";
import { useAuth } from "../hook/useAuth";
import BottomPageTitle from "../components/header/BottomPageTitle";
import BackButton from "../components/UI/BackButton";
import SettingsTabs from "./settings/SettingsTabs";
import { Row, Space } from "antd";
import CONFIG from "../config.json";

export default function Settings() {
  const { user, updateCurrentUser } = useAuth();

  return (
    <>
      <div style={{ padding: "0px 15px" }}>
        <SettingsTabs
          user={user}
          updateCurrentUser={updateCurrentUser}
        ></SettingsTabs>
        <BottomPageTitle title={"Налаштування"}></BottomPageTitle>

        <Row
          justify={"center"}
          style={{
            position: "fixed",
            bottom: 90,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Space direction="horizontal">{`Версія: ${CONFIG.APP_VERSION}`}</Space>
        </Row>
        <BackButton></BackButton>
      </div>
    </>
  );
}
