import React, { useEffect } from "react";
import AppRouter from "./router/AppRouter";
import { AuthProvider } from "./hoc/AuthProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import ThemeProvider from "./hoc/ThemeProvider";

function App() {
  const handleUnload = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [handleUnload]);

  return (
    <AuthProvider>
      <ThemeProvider>
        <AppRouter></AppRouter>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
