export function removeDuplicatesFromArray(arr) {
  return arr.filter((it, index) => index === arr.indexOf((it = it.trim())));
}

export function getUniqueArrayOfObjects(arr, field) {
  const uniqueIds = [];
  const uniqueArr = [];
  arr.filter((element) => {
    const isDuplicate = uniqueIds.includes(element[field]);
    if (!isDuplicate) {
      uniqueIds.push(element.guid);
      element.qty = 0;
      uniqueArr.push(element);
      return true;
    }
    return false;
  });

  return uniqueArr;
}

export function sortArrayOfObjectsByIndex(a, b, field) {
  if (a.index < b.index) {
    return -1;
  }
  if (a.index > b.index) {
    return 1;
  }
  return 0;
}

export function sortArrayOfObjectsByTimeAdded(a, b) {
  if (a.timeAdded < b.timeAdded) {
    return -1;
  }
  if (a.timeAdded > b.timeAdded) {
    return 1;
  }
  return 0;
}

export const filterArrayFromArray = (array1, array2) => {
  var filtered = array1.filter(function (e) {
    return this.indexOf(e) < 0;
  }, array2);
  return filtered;
};

export const filterArrayOfObjectFromArrayOfObjectsByIndex = (
  array1,
  array2,
  index
) => {
  return array1.filter(
    (record) =>
      array2.find((record2) => record[index] === record2[index]) === undefined
  );
};

export const sortArrayOfObjectsByTitle = (a, b) => {
  return a.title.localeCompare(b.title);
};
