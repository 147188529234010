import { getUserSetting } from "../../../api/settings/settings";
import { servicesSettingsTitles } from "../_CONSTANTS/settings";

export const getRevisionSettings = (user) => {
  const showRealCount = getUserSetting(
    "showRealCount",
    user.settings,
    servicesSettingsTitles.REVISIONS
  );
  const showArtColumn = getUserSetting(
    "showArtInTable",
    user.settings,
    servicesSettingsTitles.REVISIONS
  );
  const showInfoColumn = getUserSetting(
    "showInfoColumn",
    user.settings,
    servicesSettingsTitles.REVISIONS
  );

  const showDiffColumn = getUserSetting(
    "showDiffColumn",
    user.settings,
    servicesSettingsTitles.REVISIONS
  );

  const showPriceColumn = getUserSetting(
    "showPriceColumn",
    user.settings,
    servicesSettingsTitles.REVISIONS
  );
  const showSumColumn = getUserSetting(
    "showSummOrRozb",
    user.settings,
    servicesSettingsTitles.REVISIONS
  );
  const digitalKeyboard = getUserSetting(
    "digitalKeyboard",
    user.settings,
    servicesSettingsTitles.REVISIONS
  );
  const sounds = getUserSetting(
    "sound",
    user.settings,
    servicesSettingsTitles.REVISIONS
  );
  const colorDifferents = getUserSetting(
    "color_differents",
    user.settings,
    servicesSettingsTitles.REVISIONS
  );
  const showSumByDiff = getUserSetting(
    "showSumByDiff",
    user.settings,
    servicesSettingsTitles.REVISIONS
  );
  const additionalColorDifferents = getUserSetting(
    "additionalHighlightDifferents",
    user.settings,
    servicesSettingsTitles.REVISIONS
  );
  const showVirtualKeyboard = getUserSetting(
    "virtualKeyboard",
    user.settings,
    servicesSettingsTitles.REVISIONS
  );
  const tablePageSize = !localStorage.getItem("tablePageSize")
    ? 10
    : +localStorage.getItem("tablePageSize");

  const settingsDefault = {
    showRealCount,
    showArtColumn,
    showSumColumn,
    digitalKeyboard,
    sounds,
    colorDifferents,
    additionalColorDifferents,
    showSumByDiff,
    showVirtualKeyboard,
    tablePageSize,
    showInfoColumn,
    showPriceColumn,
    showDiffColumn,
  };
  return settingsDefault;
};

export const getColumnsData = (defaultColumns, settings) => {
  const showArtColumn = settings?.showArtColumn;
  const showSumColumn = settings.showSumColumn;
  const showPriceColumn = settings.showPriceColumn;
  const showInfoColumn = settings.showInfoColumn;
  const showDiffColumn = settings.showDiffColumn;

  let columns = defaultColumns;

  columns = showArtColumn
    ? columns
    : columns.filter((col) => col.dataIndex !== "art");

  columns = showSumColumn
    ? columns
    : columns.filter((col) => col.dataIndex !== "sum");

  columns =
    showPriceColumn !== false
      ? columns
      : columns.filter((col) => col.dataIndex !== "price");

  columns = showInfoColumn
    ? columns
    : columns.filter((col) => col.dataIndex !== "info");

  columns = showDiffColumn
    ? columns
    : columns.filter((col) => col.dataIndex !== "diff");
  return columns;
};
