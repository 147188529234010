import { message, Modal } from "antd";
import React, { useState, useEffect } from "react";
import ModalEnterBarcode from "../../components/documents/barcoding/modal-enter-products/ModalEnterBarcode";
import {
  addRecord,
  bulkPutDataToObjectStore,
  clearObjectStore,
  getRecord,
  getRecordByMyltipleKey,
  getRecordByMyltipleKeyByFieldName,
  updateRecord,
} from "../../db/dexie-db/functions";
import {
  getDocumentProductsWithoutDeleted,
  getRecordsForTable,
} from "../../db/documents-funtions";
import {
  getProductsWithTimeAdded,
  updateIndexesInArray,
} from "../../db/products-functions";
import { TABLES } from "../../db/constants/tables";
import {
  DOCUMENTS_TITLES,
  DOCUMENT_OPEN_ACTIONS,
} from "../../components/documents/_CONSTANTS/constants";

import BottomPageTitle from "../../components/header/BottomPageTitle";
import { getServiceSettingsObject } from "../../api/settings/settings";
import { useAuth } from "../../hook/useAuth";
import { servicesSettingsTitles } from "../../components/documents/_CONSTANTS/settings";
import {
  isAnyModalOpen,
  listenToBackgroundEnteredBarcode,
} from "../../utils/eventListeners";
import Footer from "../../components/documents/barcoding/create/Footer";
import Main from "../../components/documents/barcoding/create/Main";
import ModalChooseProducts from "../../components/products/products-tree/ModalChooseProducts";
import { useParams } from "react-router-dom";
import ModalBarcodeCameraReaderUniversal from "../../components/camera-barcode-reader/ModalBarcodeCameraReaderUniversal";

const DOCUMENT_TITLE = DOCUMENTS_TITLES.EDITING_BARCODING;
const DOCUMENT_ACTION = DOCUMENT_OPEN_ACTIONS.EDIT;
const CURRENT_DOCUMENT_TABLE = TABLES.EDITING_BARCODING;

let backgroundEnteredBarcode = "";

export default function EditBarcoding(props) {
  const { user } = useAuth();
  const checkPriceSettings = getServiceSettingsObject(
    user.settings,
    servicesSettingsTitles.BARCODING
  );
  const params = useParams();
  const id = params.id;

  const [isModalProductOpen, setIsModalProductOpen] = useState(false);
  const [isModalCameraBarcodeReaderOpen, setIsModalCameraBarcodeReaderOpen] =
    useState(false);
  const [isModalChooseProductsOpen, setIsModalChooseProductsOpen] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [isScanning, setIsScanning] = useState();
  const [products, setProducts] = useState([]);
  const [editingDocument, setEditingDocument] = useState();
  const [settings, setSettings] = useState(checkPriceSettings);
  const [backgroundEnteredBarcodeValue, setBackgroundEnteredBarcodeValue] =
    useState("");
  const [barcode, setBarcode] = useState("");
  const [isScanningByCamera, setIsScanningByCamera] = useState(false);

  useEffect(() => {
    document.addEventListener("keydown", detectKeyDownEvent);
    return () => {
      document.removeEventListener("keydown", detectKeyDownEvent);
    };
  }, []);

  const detectKeyDownEvent = (e) => {
    if (isAnyModalOpen()) return;
    backgroundEnteredBarcode = listenToBackgroundEnteredBarcode(
      e,
      // setIsModalProductOpen,
      setBackgroundEnteredBarcodeValue,
      backgroundEnteredBarcode
    );
  };

  useEffect(() => {
    const getData = async () => {
      processNewEditing();
    };

    getData();
  }, []);

  const processNewEditing = async () => {
    const deleted = await clearObjectStore(CURRENT_DOCUMENT_TABLE);
    if (!deleted)
      return message.error("Помилка очищення таблиці для редагування!");
    const doc = await getRecord(TABLES.BARCODING, +id);
    if (!doc) {
      return message.error("Помилка отримання даних документа");
    }
    const products = doc.products;
    const addedToIndexedDb = await bulkPutDataToObjectStore(
      CURRENT_DOCUMENT_TABLE,
      products
    );
    if (!addedToIndexedDb) {
      return message.error("Помилка заповнення товарів документа");
    }
    setEditingDocument(doc);
    setProducts(getProductsWithTimeAdded(products));
    setLoading(false);
  };

  const removeProductFromDocument = async (product) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      product,
      CURRENT_DOCUMENT_TABLE,
      DOCUMENT_ACTION
    );
    setProducts(arrayWithoutDeletedProduct);
  };

  const onRowClickOnChooseProduct = (product) => {
    return Modal.confirm({
      title: "Прикріпити штрих-код до цієї позиції?",
      onOk: () => {
        attachBarcodeToProduct(barcode, product);
      },
    });
  };

  const attachBarcodeToProduct = async (barcode, product) => {
    const enteredNewBarcodes = product?.newBarcodes;
    const newBarcodes = enteredNewBarcodes
      ? [...enteredNewBarcodes, barcode]
      : [barcode];

    setIsModalChooseProductsOpen(false);

    const newProduct = { ...product, newBarcodes };
    const newProducts = await addProductToDocument(newProduct);
    setProducts(getRecordsForTable(newProducts));
    if (isScanningByCamera) {
      setIsScanning(true);
    } else {
      setIsModalProductOpen(true);
    }
  };

  const addProductToDocument = async (product) => {
    const existingProduct = products.find(
      (existingProduct) => product.guid === existingProduct.guid
    );

    if (existingProduct) {
      const newBarcodes = [...existingProduct.newBarcodes, barcode];
      existingProduct.newBarcodes = newBarcodes;
      const arrayWithoutExistingProduct = updateIndexesInArray(
        products,
        existingProduct
      );

      existingProduct.index = arrayWithoutExistingProduct.length + 1;
      existingProduct.key = arrayWithoutExistingProduct.length + 1;

      const newProducts = [...arrayWithoutExistingProduct, existingProduct];

      const updated = await bulkPutDataToObjectStore(
        CURRENT_DOCUMENT_TABLE,
        newProducts
      );
      if (!updated) {
        message.error("Помилка оновлення даних в таблиці");
        return false;
      }
      return newProducts;
    }

    const existingProducts = JSON.parse(JSON.stringify(products));
    const newProduct = { ...product };
    newProduct.key = products.length + 1;
    newProduct.index = newProduct.key;
    await addRecord(CURRENT_DOCUMENT_TABLE, newProduct);
    const newProducts = [...existingProducts, newProduct];
    return newProducts;
  };

  const deleteBarcodeHandler = async (product, barcode) => {
    const newEnteredBarcodes = product.newBarcodes;
    const newBarcodes = newEnteredBarcodes.filter(
      (record) => record !== barcode
    );
    product.newBarcodes = newBarcodes;
    const updated = await updateRecord(
      CURRENT_DOCUMENT_TABLE,
      product.guid,
      product
    );

    if (!updated) {
      message.error("Помилка оновлення товару у базі даних!");
      return false;
    }

    const oldProducts = JSON.parse(JSON.stringify(products));
    const productToChangeInTable = products.find(
      (oldProduct) => oldProduct.guid === product.guid
    );
    if (!productToChangeInTable) {
      message.error("Помилка отримання товару для зміни в таблиці");
      return false;
    }

    productToChangeInTable.newBarcodes = newBarcodes;

    return true;
  };

  const processSearchProduct = async (enteredData) => {
    if (!Number(enteredData)) {
      return message.error("Введіть штрих-код");
    }
    setBarcode(enteredData);
    const productByBarcode = await getRecordByMyltipleKey(
      TABLES.PRODUCTS,
      enteredData
    );

    const barcodeInCurrentDocument = await getRecordByMyltipleKeyByFieldName(
      CURRENT_DOCUMENT_TABLE,
      enteredData,
      "newBarcodes"
    );
    if (barcodeInCurrentDocument) {
      message.error("Цей штрих-код вже додано!");
      if (isScanningByCamera || isModalCameraBarcodeReaderOpen) {
        setIsScanning(false);
        setTimeout(() => {
          setIsScanning(true);
        }, 1000);
      }
      return;
    }

    if (!productByBarcode) {
      return processProductNotFound(enteredData);
    }
    return processFindedProduct(productByBarcode);
  };

  const processProductNotFound = (barcode) => {
    return Modal.confirm({
      title: `Товар не знайдено, прикріпити штрих-код ${barcode} до позиції?`,
      cancelText: "Ні",
      okText: "Так",
      onCancel: () => {
        if (isScanningByCamera) setIsScanning(true);
        return;
      },
      onOk: () => {
        if (isScanningByCamera) {
          setIsScanning(false);
        } else {
          setIsModalProductOpen(false);
        }

        return setIsModalChooseProductsOpen(true);
      },
    });
  };

  const processFindedProduct = (productByBarcode) => {
    return alert(productByBarcode.title);
  };

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_ACTION}
        removeProductFromDocument={removeProductFromDocument}
        settings={settings}
        deleteBarcodeHandler={deleteBarcodeHandler}
        document={editingDocument}
        dbTable={CURRENT_DOCUMENT_TABLE}
      ></Main>
      {(isModalProductOpen || backgroundEnteredBarcodeValue) && (
        <ModalEnterBarcode
          barcode={barcode}
          setBarcode={setBarcode}
          action={DOCUMENT_ACTION}
          dbTable={CURRENT_DOCUMENT_TABLE}
          products={products}
          setProducts={setProducts}
          isModalProductOpen={isModalProductOpen}
          setIsModalProductOpen={setIsModalProductOpen}
          serviceTitle={DOCUMENT_TITLE}
          setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
          processSearchProduct={processSearchProduct}
        ></ModalEnterBarcode>
      )}
      {isModalCameraBarcodeReaderOpen && (
        <ModalBarcodeCameraReaderUniversal
          barcode={barcode}
          setBarcode={setBarcode}
          isModalCameraBarcodeReaderUniversalOpen={
            isModalCameraBarcodeReaderOpen
          }
          setIsModalCameraBarcodeReaderUniversalOpen={
            setIsModalCameraBarcodeReaderOpen
          }
          dbTable={TABLES.PRODUCTS}
          processSearchData={processSearchProduct}
          isScanning={isScanning}
          setIsScanning={setIsScanning}
        ></ModalBarcodeCameraReaderUniversal>
      )}
      {isModalChooseProductsOpen && (
        <ModalChooseProducts
          isModalChooseProductsOpen={isModalChooseProductsOpen}
          onRowClick={onRowClickOnChooseProduct}
          setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
        ></ModalChooseProducts>
      )}

      <Footer
        setIsModalProductOpen={setIsModalProductOpen}
        setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
        setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
        settings={settings}
        setSettings={setSettings}
        setIsScanningByCamera={setIsScanningByCamera}
      ></Footer>
      <BottomPageTitle title={DOCUMENT_TITLE}></BottomPageTitle>
    </>
  );
}
