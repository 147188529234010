import React from "react";
import { Table } from "antd";
import { sortArrayOfObjectsByTitle } from "../../../utils/array-functions";

const Products = (props) => {
  const columns = [
    {
      title: "Назва",
      dataIndex: "title",
      width: "70%",
      sorter: (a, b) => sortArrayOfObjectsByTitle(a, b),
    },
    {
      title: "Ціна",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      width: "15%",
      // render: (_, record) => {
      //   console.log(record.price);
      // },
    },
    {
      title: "Залишок",
      dataIndex: "stockQty",
      width: "15%",
      render: (_, record) => {
        if (props?.showStockQty === false) return 0;
        return record.stockQty;
      },
    },
  ];

  return (
    <>
      <Table
        size={"small"}
        key={"choose-products-table"}
        loading={props.loading}
        pagination={{
          position: ["bottomCenter"],
          hideOnSinglePage: true,
          pageSize: 999,
        }}
        scroll={{
          // x: "max-content",
          y: window.screen.height - 200,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => props.onRowClick(record),
          };
        }}
        columns={columns}
        dataSource={props.productsToTable}
      />
    </>
  );
};

export default Products;
