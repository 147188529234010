import React, { useMemo, useState } from "react";
import { Table, Divider } from "antd";
import { getTableTotals } from "../../../../utils/getTableTotals";
import { TableTotals } from "../../../table-utils/TableTotals";
import { DOCUMENT_OPEN_ACTIONS } from "../../_CONSTANTS/constants";
import ModalEditProductQty from "../../manage-documents/ModalEditProductQty";
import { useTheme } from "../../../../hook/useTheme";
import { sortArrayOfObjectsByTitle } from "../../../../utils/array-functions";

export default function TableProducts(props) {
  const { useDarkMode } = useTheme();
  const redColumn = useDarkMode ? "red-column-dark" : "red-column";
  const blueColumn = useDarkMode ? "blue-column-dark" : "blue-column";
  const greenColumn = useDarkMode ? "green-column-dark" : "green-column";

  const [isModalChangeProductQtyOpen, setIsModalChangeProductQtyOpen] =
    useState(false);
  const [productToChangeQty, setProductToChangeQty] = useState();

  const defaultColumns = [
    {
      title: "№",
      dataIndex: "index",
      width: "15%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.index - b.index,
    },
    {
      title: "Назва",
      dataIndex: "title",
      width: "40%",
      sorter: (a, b) => sortArrayOfObjectsByTitle(a, b),

      render: (_, record) => {
        const textColor = record.color;
        if (props.settings?.showBarcodesInTable) {
          return (
            <span style={{ color: textColor }}>
              {record.title}
              {!record.barcodes[0] ? (
                ""
              ) : (
                <>
                  <Divider style={{ margin: "3px 0px" }}></Divider>
                  {record.barcodes.map((barcode) => {
                    return (
                      <>
                        <span key={barcode}>{barcode}</span>
                        <br></br>
                      </>
                    );
                  })}
                </>
              )}
            </span>
          );
        } else {
          return <span style={{ color: textColor }}>{record.title}</span>;
        }
      },
    },
    {
      title: "Ціна",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      width: "20%",
      render: (_, record) => {
        return record.price.toFixed(2);
      },
    },
    {
      title: "К-ть",
      dataIndex: "qty",

      sorter: (a, b) => a.qty - b.qty,
      width: "25%",
      render: (_, record) => {
        return (
          <>
            <span style={{ fontWeight: "500" }}>
              {record.isWeight
                ? record.qtyFromSource.toFixed(3)
                : parseInt(record.qtyFromSource)}
            </span>
            <br></br>
            <span>
              {record.isWeight && record.qty
                ? record?.qty.toFixed(3)
                : record.qty
                ? parseInt(record.qty)
                : 0}
            </span>
          </>
        );
      },
    },
    {
      title: "Сума",
      dataIndex: "sum",
      sorter: (a, b) => a.sum - b.sum,
      width: "25%",
      render: (_, record) => {
        return (+record.qty * +record.price).toFixed(2);
      },
    },
    {
      title: "Розб",
      dataIndex: "diff",
      width: "20%",
      render: (_, record) => {
        if (!record.qty) {
          return (0 - +record.qtyFromSource).toFixed(2);
        } else {
          return (
            +record.qty - (!record.qtyFromSource ? 0 : +record.qtyFromSource)
          ).toFixed(2);
        }
      },
      sorter: (a, b) => {
        const aQty = !a.qty ? 0 : +a.qty;
        const bQty = !b.qty ? 0 : +b.qty;
        const diffA = aQty - +a.qtyFromSource;
        const diffB = bQty - +b.qtyFromSource;
        return diffA - diffB;
      },
    },
  ];

  const setColumnsData = (showSumColumn) => {
    if (!showSumColumn) {
      // setColumns(defaultColumns.filter((column) => column.dataIndex !== "sum"));
      return defaultColumns.filter((column) => column.dataIndex !== "sum");
    } else {
      return defaultColumns;
    }
  };
  const columns = setColumnsData(props.settings.showSumColumn);

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const onDoubleClickHandler = (record) => {
    setProductToChangeQty(record);
    setIsModalChangeProductQtyOpen(true);
  };

  const getRowClassName = (
    colorDifferents,
    additionalColorDifferents,
    record
  ) => {
    if (!colorDifferents) return;

    if (additionalColorDifferents) {
      if (+record.qty === 0) {
        return redColumn;
      }
      if (+record.qty - +record.qtyFromSource > 0) {
        return greenColumn;
      }
      if (+record.qty - +record.qtyFromSource < 0) {
        return blueColumn;
      }
    }

    if (+record.qty - +record.qtyFromSource !== 0) {
      return redColumn;
    }
    if (+record.qty === +record.qtyFromSource) {
      return greenColumn;
    }
  };

  const title = useMemo(() => {
    const totals = getTableTotals(props?.products);
    return (
      <TableTotals
        quantity={totals.quantity.toFixed(3)}
        total={totals.total.toFixed(2)}
      />
    );
  }, [props?.products]);

  const TableWithProducts = useMemo(() => {
    return (
      <Table
        onRow={(record) => {
          if (props?.action === DOCUMENT_OPEN_ACTIONS.VIEW) return;
          return {
            onDoubleClick: (event) => {
              onDoubleClickHandler(record);
            },
            onContextMenu: (event) => {
              onDoubleClickHandler(record);
            },
          };
        }}
        tableLayout="fixed"
        columns={columns}
        pagination={{
          position: ["bottomCenter"],
          hideOnSinglePage: false,
          showSizeChanger: true,
        }}
        rowClassName={(record) => {
          return getRowClassName(
            props?.settings?.colorDifferents,
            props?.settings?.additionalColorDifferents,
            record
          );
        }}
        dataSource={props?.products}
        size={"middle"}
        onChange={onChange}
        loading={props.loading}
        title={() => title}
      />
    );
  }, [props?.products, props.settings]);

  return (
    <div>
      {TableWithProducts}
      {isModalChangeProductQtyOpen &&
        props?.action !== DOCUMENT_OPEN_ACTIONS.VIEW && (
          <ModalEditProductQty
            isModalChangeLastProductOpen={isModalChangeProductQtyOpen}
            setIsModalChangeLastProductOpen={setIsModalChangeProductQtyOpen}
            afterChangeProductQty={props.afterChangeProductQty}
            productToChangeQty={productToChangeQty}
            removeProductFromDocument={props.removeProductFromDocument}
            settings={props.settings}
            dbTable={props.dbTable}
          ></ModalEditProductQty>
        )}
    </div>
  );
}
