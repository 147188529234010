import React, { useState } from "react";
import { Table } from "antd";
import { DOCUMENT_OPEN_ACTIONS } from "../../_CONSTANTS/constants";
import ModalEditProductQty from "../../manage-documents/ModalEditProductQty";
export default function TableProducts(props) {
  const [isModalChangeProductQtyOpen, setIsModalChangeProductQtyOpen] =
    useState(false);
  const [productToChangeQty, setProductToChangeQty] = useState();

  const onDoubleClickHandler = (record) => {
    setProductToChangeQty(record);
    setIsModalChangeProductQtyOpen(true);
  };

  return (
    <div>
      <Table
        tableLayout="fixed"
        columns={props.columns}
        onRow={(record) => {
          if (props?.action === DOCUMENT_OPEN_ACTIONS.VIEW) return;
          return {
            onDoubleClick: (event) => {
              onDoubleClickHandler(record);
            },
            onContextMenu: (event) => {
              onDoubleClickHandler(record);
            },
          };
        }}
        pagination={{
          position: ["bottomCenter"],
        }}
        dataSource={props?.products}
        size={"middle"}
        loading={props.loading}
      />
      {isModalChangeProductQtyOpen &&
        props?.action !== DOCUMENT_OPEN_ACTIONS.VIEW && (
          <ModalEditProductQty
            isModalChangeLastProductOpen={isModalChangeProductQtyOpen}
            setIsModalChangeLastProductOpen={setIsModalChangeProductQtyOpen}
            productToChangeQty={productToChangeQty}
            removeProductFromDocument={props.removeProductFromDocument}
            settings={props.settings}
            dbTable={props.dbTable}
            afterChangeProductQty={props.afterChangeProductQty}
          ></ModalEditProductQty>
        )}
    </div>
  );
}
