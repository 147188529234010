import { message } from "antd";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Main from "../../components/documents/diplacements/Main";
import { getRecord } from "../../db/dexie-db/functions";
import {
  getDocumentProductsWithoutDeleted,
  getRecordsForTable,
} from "../../db/documents-funtions";
import BottomPageTitle from "../../components/header/BottomPageTitle";
import { DOCUMENT_OPEN_ACTIONS } from "../../components/documents/_CONSTANTS/constants";
import { TABLES } from "../../db/constants/tables";
import BackButton from "../../components/UI/BackButton";
import { getDiplacementColumns } from "../../components/documents/manage-documents/table-components/documents-columns";

const DOCUMENT_ACTION = DOCUMENT_OPEN_ACTIONS.EDIT;

export default function EditOrder() {
  const params = useParams();
  const id = params.id;
  const [document, setDocument] = useState();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const doc = await getRecord(TABLES.DIPLACEMENTS, Number(id));
    if (!doc) {
      message.error("Помилка отримання даних документа");
    }
    const products = doc.products;
    setDocument(doc);
    setProducts(getRecordsForTable(products));
    setLoading(false);
  };

  const diplacementColumns = getDiplacementColumns({ action: DOCUMENT_ACTION });

  // const setProductQty = async (record, enteredQty) => {
  //   return setProductQuantity(
  //     products,
  //     record,
  //     enteredQty,
  //     TABLES.CURRENT_DIPLACEMENT,
  //     DOCUMENT_OPEN_ACTIONS.EDIT
  //   );
  // };

  // const handleChangeQty = async (record, value) => {
  //   handleChangeProductQty(
  //     record,
  //     value,
  //     removeProductFromDocument,
  //     setProductQty,
  //     setProducts
  //   );
  // };

  const removeProductFromDocument = async (product) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      product,
      TABLES.CURRENT_DIPLACEMENT,
      DOCUMENT_OPEN_ACTIONS.EDIT
    );
    setProducts(arrayWithoutDeletedProduct);
  };

  const afterChangeProductQty = (newProduct) => {
    setProducts((prevProducts) => {
      const productToChange = prevProducts.find(
        (product) => product.guid === newProduct.guid
      );

      if (productToChange) productToChange.qty = newProduct.qty;
      return prevProducts;
    });
  };

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_ACTION}
        document={document}
        afterChangeProductQty={afterChangeProductQty}
        removeProductFromDocument={removeProductFromDocument}
        columns={diplacementColumns}
      ></Main>
      <BackButton></BackButton>
      <BottomPageTitle title={"Редагування переміщення"}></BottomPageTitle>
    </>
  );
}
