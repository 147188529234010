import { Dropdown, Modal, Space, Tabs, message } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TableProducts from "../verification/TableProducts";
import InfoTab from "../verification/InfoTab";
import {
  SaveOutlined,
  OrderedListOutlined,
  DownOutlined,
  StopOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import {
  updateRecord,
  clearObjectStore,
} from "../../../../db/dexie-db/functions";
import { getTableTotals } from "../../../../utils/getTableTotals";
import { checkIsDocVerified } from "../functions";
import { DOCUMENT_OPEN_ACTIONS, TABS_TITLES } from "../../_CONSTANTS/constants";
import { TABLES } from "../../../../db/constants/tables";
import { generateDocBarcode } from "../../../../db/documents-funtions";

const Main = (props) => {
  const navigate = useNavigate();

  const action = props?.action;
  const documentForEdit = props?.document;

  const [loadingSaveButton, setLoadingSaveButton] = useState(false);
  const [supplier, setSupplier] = useState();
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (documentForEdit) {
      setSupplier(documentForEdit.supplierGuid);
      setComment(documentForEdit.comment);
    }
  }, [documentForEdit]);

  const handleMenuClick = async () => {
    Modal.confirm({
      title: "Не зберігати?",
      onCancel: () => {
        return;
      },
      onOk: async () => {
        const clearedCurrentOrder = await clearObjectStore(
          TABLES.CURRENT_VERIFICATION
        );
        if (!clearedCurrentOrder) {
          return message.error("Помилка очищення документа");
        }
        props?.setProducts([]);
        navigate(-1);
      },
      okText: "Ні",
    });
  };

  const items = [
    {
      label: (
        <Space>
          <StopOutlined />
          Скасувати
        </Space>
      ),
      key: "1",
      onClick: handleMenuClick,
    },
  ];

  const handleButtonClick = async () => {
    if (!supplier) {
      return Modal.confirm({
        title: "Контрагент не вибраний, продовжити?",
        onCancel: () => {
          return;
        },
        onOk: () => {
          saveDocumentHadler();
        },
      });
    }
    saveDocumentHadler();
  };

  const saveDocumentHadler = async () => {
    setLoadingSaveButton(true);
    switch (action) {
      case DOCUMENT_OPEN_ACTIONS.VERIFY:
        if (props.products.length === 0) {
          setLoadingSaveButton(false);
          return message.error("Додайте товари");
        }

        // перевірити розбіжність по товарах, якщо немає, тоді зберегти, якщо є, тоді повідомлення
        const docVerified = await checkIsDocVerified(props.products);
        const newDocument = {
          date: dayjs().format("DD.MM.YYYY"),
          created: dayjs(),
          updated: dayjs(),
          products: props.products,
          sum: getTableTotals(props.products).total,
          comment,
          verified: docVerified,
          processed: true,
          docBarcode: generateDocBarcode(),
        };

        const saved = await updateRecord(
          TABLES.VERIFICATION,
          Number(props.document.id),
          newDocument
        );
        if (!saved) {
          setLoadingSaveButton(false);
          return message.error("Помилка збереження документу");
        }
        const clearedCurrentVerification = await clearObjectStore(
          TABLES.CURRENT_VERIFICATION
        );
        if (!clearedCurrentVerification) {
          message.error("Помилка очищення поточного документа");
          return false;
        }
        // sendTGMessage(
        //   "Користувач " + user.username + " зберіг перевірку документа."
        // );
        // statistics(user.company, "Перевірка документа");
        message.success("Оновлено!");
        setLoadingSaveButton(false);
        break;
      default:
        break;
    }

    return navigate("/docs-verification");
  };

  const operations = (
    <>
      <Dropdown.Button
        loading={loadingSaveButton}
        size="middle"
        icon={<DownOutlined />}
        menu={{
          items,
        }}
        onClick={handleButtonClick}
      >
        <SaveOutlined /> Зберегти
      </Dropdown.Button>
    </>
  );

  const OperationsSlot = {
    right: operations,
  };

  const deftabs = [
    {
      label: TABS_TITLES.INFO,
      key: "1",
      children: (
        <InfoTab
          supplier={supplier}
          setSupplier={setSupplier}
          comment={comment}
          setComment={setComment}
          document={documentForEdit}
        ></InfoTab>
      ),
    },
    {
      label: TABS_TITLES.PRODUCTS,
      key: "2",
      children: (
        <TableProducts
          loading={props.loading}
          products={props.products}
          setProducts={props.setProducts}
          action={action}
          settings={props.settings}
          afterChangeProductQty={props.afterChangeProductQty}
          removeProductFromDocument={props.removeProductFromDocument}
          dbTable={props.dbTable}
        ></TableProducts>
      ),
    },
  ];

  return (
    <>
      <Tabs
        // onChange={onTablClickFunction}
        // centered
        tabBarStyle={{ marginBottom: "5px" }}
        tabBarExtraContent={OperationsSlot}
        defaultActiveKey="1"
        // activeKey={activeKey}
        size={"middle"}
        items={deftabs}
      />
    </>
  );
};
export default Main;
