export const listenToBackgroundEnteredBarcode = (
  e,
  setBackgroundEnteredBarcodeValue,
  backgroundEnteredBarcode
) => {
  if (isAnyModalOpen()) return "";
  const key = e.keyCode;
  if (key === 13 && backgroundEnteredBarcode?.length > 6) {
    e.preventDefault();
    setBackgroundEnteredBarcodeValue(backgroundEnteredBarcode);
    return "";
  }
  if (key < 48 || key > 57 || key === 13) {
    return "";
  }
  return (backgroundEnteredBarcode = backgroundEnteredBarcode + e.key);
};

export const isAnyModalOpen = () => {
  const modals = document.getElementsByClassName("ant-modal-wrap");
  const drawers = document.getElementsByClassName("ant-drawer-mask");

  if (!modals || !drawers) return false;
  for (let modal of modals) {
    if (modal.style?.display !== "none") return true;
  }
  for (let drawer of drawers) {
    if (drawer.style?.display !== "none") return true;
  }

  return false;
};
