import {
  CameraOutlined,
  BarcodeOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { FloatButton } from "antd";
import React from "react";
import MenuComponent from "../menu/Menu";
import Data from "../menu/Menu";
import BackButton from "../../../UI/BackButton";

export default function SalesFooter(props) {
  const dataMenu = Data;
  return (
    <>
      <MenuComponent
        data={dataMenu}
        settings={props.settings}
        setSettings={props.setSettings}
      ></MenuComponent>
      <BackButton></BackButton>
      <FloatButton.Group
        trigger="click"
        type="primary"
        icon={<PlusCircleOutlined />}
      >
        <FloatButton
          onClick={() => props.setIsModalProductOpen(true)}
          icon={<BarcodeOutlined />}
        />
        <FloatButton
          onClick={() => props.setIsModalCameraBarcodeReaderOpen(true)}
          icon={<CameraOutlined />}
        />
        <FloatButton
          onClick={() => props.setIsModalChooseProductsOpen(true)}
          icon={<UnorderedListOutlined />}
        />
      </FloatButton.Group>
      {/* <BackToTopButton></BackToTopButton> */}
    </>
  );
}
