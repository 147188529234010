import { Button, Col, Modal, Popconfirm, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { getCharacteristicTotals } from "../../../../utils/getTableTotals";
import { getProductToTable } from "../modal-enter-product/enter-products-functions";
import {
  CHARACTERISTICS_ACTION,
  DOCUMENTS_EDITING_TITLES,
  DOCUMENTS_TITLES,
} from "../../_CONSTANTS/constants";
import { DeleteOutlined } from "@ant-design/icons";
import { useAuth } from "../../../../hook/useAuth";
import { getUserSetting } from "../../../../api/settings/settings";
import TableExpirationDates from "./TableExpirationDates";

export default function ModalEnterExpirationDate(props) {
  const { user } = useAuth();
  const showSumByDiff = getUserSetting(
    "showSumByDiff",
    user.settings,
    "revizia"
  );
  const product = props.product;

  const [characteristics, setCharacteristics] = useState([]);

  useEffect(() => {
    if (props.isModalEnterExpirationDateOpen) {
      if (!product?.characteristics) return addNewExpirationDate();
      setCharacteristics(product?.characteristics);
    }
  }, [props.isModalEnterExpirationDateOpen]);

  const saveCharacteristics = async () => {
    console.log(characteristics);
    const badCharacteristic = characteristics.filter((element) => {
      if (!element.qty || !element.title) return element;
    });

    if (badCharacteristic.length) return message.error("Заповніть поля!");

    const totalProductQty = getCharacteristicTotals(characteristics);
    product.characteristics = characteristics;
    const productToTableStructure = await getProductToTable(
      product,
      props.dbTable
    );
    productToTableStructure.productToReturn.qty = totalProductQty.quantity;
    if (props?.action === CHARACTERISTICS_ACTION.EDIT) {
      props.addProductToCurrentDocument(
        productToTableStructure.productToReturn,
        productToTableStructure.productToReturn.qty
      );
    } else {
      props.addProductToCurrentDocument(
        productToTableStructure,
        props.products
      );
    }

    props.setIsModalEnterExpirationDateOpen(false);
  };

  const deleteProductHandler = async (product) => {
    await props.removeProductFromDocument(product);
    props.setIsModalEnterExpirationDateOpen(false);
  };

  const addNewExpirationDate = () => {
    const newGuid = `${product.guid}-${
      characteristics?.length ? characteristics.length + 1 : 1
    }`;
    const newData = {
      key: newGuid,
      guid: newGuid,
      title: null,
      qty: 1,
    };

    setCharacteristics([...characteristics, newData]);
    // setCount(count + 1);
  };

  const footer = [
    <>
      <Row>
        {props?.action === CHARACTERISTICS_ACTION.EDIT ? (
          <Col span={4}>
            <Popconfirm
              title={"Видалити позицію?"}
              onCancel={() => {
                return;
              }}
              onConfirm={() => deleteProductHandler(product)}
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Col>
        ) : (
          // <Col span={2}></Col>
          ""
        )}

        <Col
          span={props?.action === CHARACTERISTICS_ACTION.EDIT ? 8 : 12}
          style={{
            textAlign:
              props?.action === CHARACTERISTICS_ACTION.EDIT ? "" : "left",
          }}
        >
          {}
          <Button
            style={{ width: "90%" }}
            key="submit"
            type="primary"
            onClick={addNewExpirationDate}
          >
            + Термін
          </Button>
        </Col>

        <Col span={12} style={{ textAlign: "right" }}>
          <Button
            style={{ width: "90%" }}
            key="submit"
            type="primary"
            onClick={saveCharacteristics}
          >
            Зберегти
          </Button>
        </Col>
      </Row>
    </>,
  ];

  return (
    <>
      <Modal
        title={product?.title}
        className="modalEnterProductExpirationDate"
        open={props.isModalEnterExpirationDateOpen}
        onCancel={() => {
          props.setIsModalEnterExpirationDateOpen(false);
          setCharacteristics([]);
          if (props.setIsModalProductOpen) props.setIsModalProductOpen(true);
          if (props?.afterClose) props?.afterClose();
        }}
        footer={footer}
      >
        {product?.info ? (
          <i style={{ padding: 5, textDecoration: "underline" }}>
            {product.info}
          </i>
        ) : (
          ""
        )}
        <TableExpirationDates
          characteristics={characteristics}
          setCharacteristics={setCharacteristics}
          showTotalCharacteristicsByDiff={
            props?.serviceTitle === DOCUMENTS_TITLES.REVISION ||
            props?.serviceTitle === DOCUMENTS_EDITING_TITLES.EDITING_REVISION ||
            !showSumByDiff
              ? true
              : false
          }
        ></TableExpirationDates>
      </Modal>
    </>
  );
}
