import { Button, Space, Switch } from "antd";
import React, { useState } from "react";
import Menu from "../../../menu/Menu";
import ImportModal from "../../../import/ImportModal";
import {
  servicesSettingsTitles,
  SETTINGS_KEYS,
} from "../../_CONSTANTS/settings";
import { useAuth } from "../../../../hook/useAuth";
import {
  getUpdatedSettings,
  updateUserSettInSql,
} from "../../../../api/settings/settings";

export default function MenuComponent({ settings, setSettings }) {
  const { user, updateCurrentUser } = useAuth();

  const [isOpenUpdateDataModal, setIsOpenUpdateDataModal] = useState(false);

  const onChangeShowVirtualKeyboard = (value) => {
    setSettings({ ...settings, showVirtualKeyboard: value });
    updateUserSettInSql(
      user,
      SETTINGS_KEYS.USE_VIRTUAL_KEYBOARD,
      value,
      servicesSettingsTitles.RETURNS
    );

    const updatedSettings = getUpdatedSettings(
      user.settings,
      SETTINGS_KEYS.USE_VIRTUAL_KEYBOARD,
      value,
      servicesSettingsTitles.RETURNS
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };
    updateCurrentUser(updatedLoggedInUser);
  };

  const dataTab = (
    <Button
      onClick={() => {
        setIsOpenUpdateDataModal(true);
      }}
    >
      Оновити дані
    </Button>
  );
  const settingsTab = (
    <>
      <Space direction="horizontal">
        <Switch
          checked={settings?.showVirtualKeyboard}
          size="default"
          onChange={onChangeShowVirtualKeyboard}
        />
        Віртуальна клавіатура
      </Space>
    </>
  );
  return (
    <>
      <Menu dataTab={dataTab} settingsTab={settingsTab}></Menu>
      <ImportModal
        open={isOpenUpdateDataModal}
        setIsOpenUpdateDataModal={setIsOpenUpdateDataModal}
      ></ImportModal>
    </>
  );
}
