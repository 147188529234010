import React, { useEffect, useState } from "react";
import { Tabs, Dropdown, message } from "antd";
import { OrderedListOutlined, DeleteOutlined } from "@ant-design/icons";
import Footer from "../../components/documents/documents-verification/list/Footer";
import TableDocuments from "../../components/documents/documents-verification/list/TableDocuments";
import {
  deleteRecord,
  getAllRecords,
  getRecordByKeyValue,
  getRecords,
} from "../../db/dexie-db/functions";
import { TABLES } from "../../db/constants/tables";
import ExportDocumentsComponent from "../../components/documents/manage-documents/documents-header/ExportDocumentsComponent";
import ExportModal from "../../components/export/ExportModal";
import {
  getDocumentsForExport,
  handleDeleteExported,
} from "../../db/documents-funtions";

const DOCUMENT_TABLE = TABLES.VERIFICATION;

export default function DocumentsVerification() {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalExportOpen, setIsModalExportOpen] = useState(false);
  const [documentsToExport, setDocumentsToExport] = useState([]);
  const [documentsToExportOriginal, setDocumentsToExportOriginal] = useState(
    []
  );
  const [loadingExportButton, setLoadingExportButton] = useState(false);

  useEffect(() => {
    setDocumentsToTable();
  }, []);

  const fetchDataFromDb = async () => {
    return await getAllRecords(TABLES.VERIFICATION);
  };

  async function getFormattedDocuments(documents) {
    return await Promise.all(
      documents.map(async (doc, index) => {
        doc.key = index + 1;
        doc.index = index + 1;
        const supplier = await getRecordByKeyValue(TABLES.SUPPLIERS, {
          guid: doc.supplierGuid,
        });

        doc.supplierName = supplier?.title;
        doc.rowsCount = doc.products.length;
        return doc;
      })
    );
  }

  const setDocumentsToTable = async () => {
    setLoading(true);
    const documentsToTable = await fetchDataFromDb();
    setDocuments(await getFormattedDocuments(documentsToTable));
    setLoading(false);
  };

  const handleDeleteVerified = async () => {
    const documentsToDelete = await getRecords(
      TABLES.VERIFICATION,
      "verified",
      "true"
    );
    if (!documentsToDelete.length) {
      return message.info("Немає документів для видалення");
    }
    deleteDocuments(documentsToDelete);
  };

  const deleteDocuments = async (documentsToDelete) => {
    const isSuccessDeleted = await Promise.all(
      documentsToDelete.map(async (doc) => {
        await deleteRecord(TABLES.VERIFICATION, doc.id);
      })
    );
    if (isSuccessDeleted) {
      const newDocuments = documents.filter(
        (document) => document.verified !== "true"
      );
      const formattedDocuments = await getFormattedDocuments(newDocuments);
      setDocuments(formattedDocuments);
      message.success("Відправлені документи видалено!");
    }
  };

  const deleteItems = [
    {
      label: "Видалити перевірені",
      key: "1",
      onClick: handleDeleteVerified,
    },
    {
      label: "Видалити експортовані",
      key: "2",
      onClick: () =>
        handleDeleteExported(
          TABLES.VERIFICATION,
          documents,
          getFormattedDocuments,
          setDocuments
        ),
    },
  ];

  const deleteOperations = (
    <Dropdown.Button size="large" menu={{ items: deleteItems }}>
      <DeleteOutlined />
    </Dropdown.Button>
  );

  const OperationsSlot = {
    left: deleteOperations,
    right: (
      <ExportDocumentsComponent
        dbTable={DOCUMENT_TABLE}
        setDocumentsToExport={setDocumentsToExport}
        setDocumentsToExportOriginal={setDocumentsToExportOriginal}
        setIsModalExportOpen={setIsModalExportOpen}
        getDocumentsForExport={getDocumentsForExport}
        setLoadingExportButton={setLoadingExportButton}
        loadingExportButton={loadingExportButton}
      ></ExportDocumentsComponent>
    ),
  };

  const deftabs = [
    {
      label: (
        <span>
          <OrderedListOutlined /> Документи
        </span>
      ),
      key: "1",
      children: <></>,
    },
  ];

  return (
    <>
      <Tabs
        tabBarStyle={{ marginBottom: "5px" }}
        centered
        tabBarExtraContent={OperationsSlot}
        defaultActiveKey="1"
        size={"large"}
        items={deftabs}
      />
      <TableDocuments
        loading={loading}
        documents={documents}
        setDocuments={setDocuments}
      ></TableDocuments>
      <ExportModal
        isModalExportOpen={isModalExportOpen}
        setIsModalExportOpen={setIsModalExportOpen}
        docTypeData={{ title: "Перевірка документів", value: "verification" }}
        documentsToExport={documentsToExport}
        setLoadingExportButton={setLoadingExportButton}
        documentsToExportOriginal={documentsToExportOriginal}
        DOCUMENT_TABLE={DOCUMENT_TABLE}
        setDocuments={setDocuments}
        getFormattedDocuments={getFormattedDocuments}
        documents={documents}
      ></ExportModal>
      <Footer></Footer>
    </>
  );
}
