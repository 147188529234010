import { Tabs } from "antd";
import { useState, useEffect } from "react";
import TableProducts from "../create/TableProducts";
import InfoTab from "../create/InfoTab";
import { OrderedListOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { TABS_TITLES } from "../../_CONSTANTS/constants";

const Main = (props) => {
  const documentForEdit = props?.document;

  const [supplier, setSupplier] = useState();
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (props?.document) {
      setSupplier(documentForEdit.supplierGuid);
      setComment(documentForEdit.comment);
    }
  }, [props?.document]);

  const deftabs = [
    {
      label: TABS_TITLES.INFO,
      key: "1",
      children: (
        <InfoTab
          disabledFields={true}
          supplier={supplier}
          setSupplier={setSupplier}
          comment={documentForEdit?.comment}
          setComment={setComment}
        ></InfoTab>
      ),
    },
    {
      label: TABS_TITLES.PRODUCTS,
      key: "2",
      children: (
        <TableProducts
          loading={props.loading}
          products={props.products}
          setProducts={props.setProducts}
          disabledFields={true}
          action={props.action}
          columns={props.columns}
        ></TableProducts>
      ),
    },
  ];

  return (
    <>
      <Tabs
        tabBarStyle={{ marginBottom: "5px" }}
        defaultActiveKey="1"
        size={"middle"}
        items={deftabs}
      />
    </>
  );
};
export default Main;
