import { Col, Row, Divider } from "antd";

export const TableTotals = (props) => {
  return (
    <Row>
      <Col>К-ть: {props.quantity}</Col>
      <Divider type="vertical" dashed={false} />
      <Col>Сума: {props.total}</Col>
      <Divider type="vertical" dashed={false} />
      {props.productsCount ? (
        <Col>Позицій: {props.productsCount ? props.productsCount : 0}</Col>
      ) : (
        ""
      )}
    </Row>
  );
};
