import { Table } from "antd";
import React, { useState } from "react";
import { sortArrayOfObjectsByTitle } from "../../../../utils/array-functions";
import { DOCUMENT_OPEN_ACTIONS } from "../../_CONSTANTS/constants";
import ModalChangeProduct from "../modal-change-product/ModalChangeProduct";

export default function TableProducts(props) {
  const [isModalChangeProductOpen, setIsModalChangeProductOpen] =
    useState(false);
  const [productToChange, setProductToChange] = useState();

  const defaultColumns = [
    {
      title: "№",
      dataIndex: "index",
      width: "13%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.index - b.index,
    },
    // {
    //   title: "Арт",
    //   dataIndex: "art",
    //   width: "15%",
    //   sorter: (a, b) => a.art - b.art,
    // },
    {
      title: "Назва",
      dataIndex: "title",
      width: "48%",
      sorter: (a, b) => sortArrayOfObjectsByTitle(a, b),
      render: (_, record) => {
        const textColor = record.color;
        return <span style={{ color: textColor }}>{record.title}</span>;
      },
    },
    {
      title: "ШК",
      dataIndex: "barcode",
      render: (_, record) => {
        const records = record?.newBarcodes;
        return records.map((barcode) => {
          return (
            <>
              <span key={barcode}>{barcode}</span>
              <br></br>
            </>
          );
        });
      },
    },
  ];

  const getColumns = (defaultColumns, settings) => {
    return defaultColumns;
  };

  const columns = getColumns(defaultColumns);

  const onChange = (pagination, filters, sorter, extra) => {};

  const onDoubleClickHandler = (record) => {
    setProductToChange(record);
    setIsModalChangeProductOpen(true);
  };

  return (
    <div>
      <Table
        onRow={(record, rowIndex) => {
          if (props?.action === DOCUMENT_OPEN_ACTIONS.VIEW) return;
          return {
            // onClick: (event) => {alert("onClick")}, // click row
            onDoubleClick: (event) => {
              onDoubleClickHandler(record);
            },
            onContextMenu: (event) => {
              onDoubleClickHandler(record);
            },
          };
        }}
        tableLayout="fixed"
        // rowClassName={record}
        columns={columns}
        pagination={{
          position: ["bottomCenter"],
          hideOnSinglePage: true,
          pageSize: 999,
        }}
        dataSource={props?.products}
        size={"middle"}
        onChange={onChange}
        loading={props.loading}
      />
      {isModalChangeProductOpen && (
        <ModalChangeProduct
          isModalChangeProductOpen={isModalChangeProductOpen}
          setIsModalChangeProductOpen={setIsModalChangeProductOpen}
          productToChange={productToChange}
          removeProductFromDocument={props.removeProductFromDocument}
          settings={props.settings}
          setProducts={props.setProducts}
          deleteBarcodeHandler={props.deleteBarcodeHandler}
        ></ModalChangeProduct>
      )}
    </div>
  );
}
