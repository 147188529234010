import React from "react";
import { message, Radio, Space, Switch } from "antd";
import Menu from "../../../menu/Menu";
import { useAuth } from "../../../../hook/useAuth";
import {
  getUpdatedSettings,
  updateUserSettInSql,
} from "../../../../api/settings/settings";
import {
  PAGE_SIZE_VALUES,
  servicesSettingsTitles,
} from "../../_CONSTANTS/settings";
import { TableOutlined } from "@ant-design/icons";

export default function MenuComponent(props) {
  const { user, updateCurrentUser } = useAuth();

  const onChangeShowArtInTable = (value) => {
    props.setSettings({ ...props.settings, showArtColumn: value });
    updateUserSettInSql(
      user,
      "showArtInTable",
      value,
      servicesSettingsTitles.REVISIONS
    );
    const updatedSettings = getUpdatedSettings(
      user.settings,
      "showArtInTable",
      value,
      servicesSettingsTitles.REVISIONS
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };
    updateCurrentUser(updatedLoggedInUser);
  };

  const onChangeAdditionalColorDifferents = (value) => {
    props.setSettings({ ...props.settings, additionalColorDifferents: value });
    updateUserSettInSql(
      user,
      "additionalHighlightDifferents",
      value,
      servicesSettingsTitles.REVISIONS
    );
    const updatedSettings = getUpdatedSettings(
      user.settings,
      "additionalHighlightDifferents",
      value,
      servicesSettingsTitles.REVISIONS
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };
    updateCurrentUser(updatedLoggedInUser);
  };

  const onChangePageSize = ({ target: { value } }) => {
    if (value === 9999)
      message.info(
        "При встановленні цього параметру додаток може працювати повільно!"
      );
    localStorage.setItem("tablePageSize", value);
    props.setSettings({ ...props.settings, tablePageSize: value });
  };

  const onChangeColorDifferents = (value) => {
    props.setSettings({ ...props.settings, colorDifferents: value });
    updateUserSettInSql(
      user,
      "color_differents",
      value,
      servicesSettingsTitles.REVISIONS
    );
    const updatedSettings = getUpdatedSettings(
      user.settings,
      "color_differents",
      value,
      servicesSettingsTitles.REVISIONS
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };
    updateCurrentUser(updatedLoggedInUser);
  };

  const onChangeShowPriceColumn = (value) => {
    props.setSettings({ ...props.settings, showPriceColumn: value });
    updateUserSettInSql(
      user,
      "showPriceColumn",
      value,
      servicesSettingsTitles.REVISIONS
    );

    const updatedSettings = getUpdatedSettings(
      user.settings,
      "showPriceColumn",
      value,
      servicesSettingsTitles.REVISIONS
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };

    updateCurrentUser(updatedLoggedInUser);
  };

  const onChangeShowSumColumn = (value) => {
    props.setSettings({ ...props.settings, showSumColumn: value });
    updateUserSettInSql(
      user,
      "showSummOrRozb",
      value,
      servicesSettingsTitles.REVISIONS
    );
    const updatedSettings = getUpdatedSettings(
      user.settings,
      "showSummOrRozb",
      value,
      servicesSettingsTitles.REVISIONS
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };
    updateCurrentUser(updatedLoggedInUser);
  };

  const onChangeShowInfoColumn = (value) => {
    props.setSettings({ ...props.settings, showInfoColumn: value });
    updateUserSettInSql(
      user,
      "showInfoColumn",
      value,
      servicesSettingsTitles.REVISIONS
    );
    const updatedSettings = getUpdatedSettings(
      user.settings,
      "showInfoColumn",
      value,
      servicesSettingsTitles.REVISIONS
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };
    updateCurrentUser(updatedLoggedInUser);
  };

  const onChangeShowDiffColumn = (value) => {
    props.setSettings({ ...props.settings, showDiffColumn: value });
    updateUserSettInSql(
      user,
      "showDiffColumn",
      value,
      servicesSettingsTitles.REVISIONS
    );
    const updatedSettings = getUpdatedSettings(
      user.settings,
      "showDiffColumn",
      value,
      servicesSettingsTitles.REVISIONS
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };
    updateCurrentUser(updatedLoggedInUser);
  };

  const onChangeSounds = (value) => {
    props.setSettings({ ...props.settings, sounds: value });
    updateUserSettInSql(user, "sound", value, servicesSettingsTitles.REVISIONS);
  };

  const onChangeDigitalKeyboard = (value) => {
    props.setSettings({ ...props.settings, digitalKeyboard: value });
    updateUserSettInSql(
      user,
      "digitalKeyboard",
      value,
      servicesSettingsTitles.REVISIONS
    );
    const updatedSettings = getUpdatedSettings(
      user.settings,
      "digitalKeyboard",
      value,
      servicesSettingsTitles.REVISIONS
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };
    updateCurrentUser(updatedLoggedInUser);
  };

  const onChangeShowVirtualKeyboard = (value) => {
    props.setSettings({ ...props.settings, showVirtualKeyboard: value });
    updateUserSettInSql(
      user,
      "virtualKeyboard",
      value,
      servicesSettingsTitles.REVISIONS
    );

    const updatedSettings = getUpdatedSettings(
      user.settings,
      "virtualKeyboard",
      value,
      servicesSettingsTitles.REVISIONS
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };
    updateCurrentUser(updatedLoggedInUser);
  };

  const columnsTab = (
    <>
      <Space direction="vertical">
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.showArtColumn}
            size="default"
            onChange={onChangeShowArtInTable}
          />
          Артикул
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.showSumColumn}
            size="default"
            onChange={onChangeShowSumColumn}
          />
          Сума
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.showPriceColumn}
            size="default"
            onChange={onChangeShowPriceColumn}
          />
          Ціна
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.showInfoColumn}
            size="default"
            onChange={onChangeShowInfoColumn}
          />
          Інфо
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.showDiffColumn}
            size="default"
            onChange={onChangeShowDiffColumn}
          />
          Розбіжність
        </Space>
      </Space>
    </>
  );

  const menuItems = [
    {
      key: "columns-tab",
      label: (
        <span>
          <TableOutlined /> Колонки
        </span>
      ),
      children: columnsTab,
    },
  ];

  const settingsTab = (
    <>
      <Space direction="vertical">
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.colorDifferents}
            size="default"
            onChange={onChangeColorDifferents}
          />
          Підсвітка розбіжностей
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.additionalColorDifferents}
            size="default"
            onChange={onChangeAdditionalColorDifferents}
          />
          Додаткова підсвітка розбіжностей
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.digitalKeyboard}
            size="default"
            onChange={onChangeDigitalKeyboard}
          />
          Цифрова клавіатура
        </Space>

        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.sounds}
            size="default"
            onChange={onChangeSounds}
          />
          Звуки
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.showVirtualKeyboard}
            size="default"
            onChange={onChangeShowVirtualKeyboard}
          />
          Віртуальна клавіатура
        </Space>

        <Space direction="horizontal">
          <Radio.Group
            options={PAGE_SIZE_VALUES}
            onChange={onChangePageSize}
            value={props?.settings?.tablePageSize}
            optionType="button"
            buttonStyle="solid"
          />
          Товарів на сторінку
        </Space>
      </Space>
    </>
  );
  return (
    <>
      <Menu settingsTab={settingsTab} menuItems={menuItems}></Menu>
    </>
  );
}
