import { Col, DatePicker, Row, Table } from "antd";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import React from "react";
import { TableTotals } from "../../../table-utils/TableTotals";
import { getCharacteristicTotals } from "../../../../utils/getTableTotals";
import { sortArrayOfObjectsByTitle } from "../../../../utils/array-functions";
import dayjs from "dayjs";

const DATE_FORMAT = "DD.MM.YYYY";

export default function TableExpirationDates(props) {
  const characteristics = props?.characteristics;

  if (!characteristics) return;

  const incrementQty = (e) => {
    const inputElement = getActieInputValue(e);
    const value = +inputElement.value;
    const newValue = value + 1;
    inputElement.value = newValue;
    const characteristicGuid = e.target.getAttribute("guid");
    const currentCharacteristic = characteristics.find(
      (char) => char.guid === characteristicGuid
    );

    currentCharacteristic.qty = +newValue;
    updateCharacteristics(currentCharacteristic);
  };

  const getActieInputValue = (e) => {
    const parentNode = e.currentTarget.parentNode;
    const inputElement = parentNode.querySelector(".inputQty");
    return inputElement;
  };

  const decrementQty = (e) => {
    const inputElement = getActieInputValue(e);
    const value = +inputElement.value;
    const newValue = value - 1;
    inputElement.value = newValue;
    const characteristicGuid = e.target.getAttribute("guid");
    const currentCharacteristic = characteristics.find(
      (char) => char.guid === characteristicGuid
    );

    currentCharacteristic.qty = +newValue;
    updateCharacteristics(currentCharacteristic);
  };

  const updateCharacteristics = (currentCharacteristic) => {
    var index = characteristics.indexOf(currentCharacteristic);

    if (index !== -1) {
      characteristics[index] = currentCharacteristic;
    }
    props.setCharacteristics([...characteristics]);
  };

  const onDoubleClickHandler = (record) => {
    console.log(record);
  };

  const columns = [
    {
      key: "title",
      title: "Дата",
      dataIndex: "title",
      width: "60%",
      sorter: (a, b) => sortArrayOfObjectsByTitle(a, b),
      render: (_, record) => {
        const expirationDateStandart = dayjs(record.title, DATE_FORMAT).format(
          "YYYY-MM-DD"
        );
        const expirationDate = dayjs(record.title, DATE_FORMAT);
        // const defaultValueStandart = dayjs(value, "YYYY-MM-DD");
        return (
          <>
            <input
              className="inputExpirationDate"
              type="date"
              defaultValue={record?.title ? expirationDateStandart : null}
              onChange={(e) => {
                const value = e.target.value;
                if (!value) return;
                const expirationDate = dayjs(value, "YYYY-MM-DD").format(
                  DATE_FORMAT
                );
                record.title = expirationDate;
              }}
            ></input>
            {/* <DatePicker
              inputReadOnly={true}
              onChange={(value) => {
                if (!value) return;
                record.title = value.format(DATE_FORMAT);
              }}
              format={DATE_FORMAT}
              defaultValue={record?.title ? expirationDate : null}
              allowClear={true}
              size="large"
            ></DatePicker> */}
          </>
        );
      },
    },
    {
      key: "qty",
      title: "К-ть факт",
      dataIndex: "qty",
      width: "40%",
      sorter: (a, b) => a.qty - b.qty,
      render: (_, record) => {
        return (
          <>
            <Row>
              <InputGroup>
                <InputGroup.Text guid={record.guid} onClick={decrementQty}>
                  -
                </InputGroup.Text>
                <Form.Control
                  className="inputQty"
                  type="tel"
                  placeholder="К-ть"
                  guid={record.guid}
                  value={record?.qty ? record.qty : ""}
                  autoFocus={false}
                  onInput={(e) => {
                    const value = e.target.value;
                    const formattedValue = value.replace(/,/g, ".");
                    let enteredQty = formattedValue;
                    const characteristicGuid = e.target.getAttribute("guid");
                    const currentCharacteristic = characteristics.find(
                      (char) => char.guid === characteristicGuid
                    );

                    currentCharacteristic.qty = +enteredQty;
                    updateCharacteristics(currentCharacteristic);
                  }}
                />
                <InputGroup.Text guid={record.guid} onClick={incrementQty}>
                  +
                </InputGroup.Text>
              </InputGroup>
            </Row>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        onRow={(record) => {
          return {
            // onClick: (event) => {alert("onClick")}, // click row
            onDoubleClick: (event) => {
              onDoubleClickHandler(record);
            },
            onContextMenu: (event) => {
              onDoubleClickHandler(record);
            },
          };
        }}
        pagination={{
          position: ["bottomCenter"],
          hideOnSinglePage: true,
        }}
        dataSource={characteristics}
        size={"small"}
        title={() => {
          const totals = getCharacteristicTotals(
            characteristics,
            props?.showTotalCharacteristicsByDiff
          );
          return (
            <>
              <TableTotals
                quantity={totals.quantity.toFixed(3)}
                total={totals.total.toFixed(2)}
                productsCount={
                  characteristics.length ? characteristics.length : 0
                }
              />
            </>
          );
        }}
        // loading={props.loading}
      />
    </>
  );
}
