import {
  CameraOutlined,
  BarcodeOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { FloatButton } from "antd";
import React from "react";
import BackButton from "../../../UI/BackButton";
import CheckPriceMenu from "../menu/CheckPriceMenu";

export default function CheckPriceFooter(props) {
  return (
    <>
      <CheckPriceMenu
        settings={props?.settings}
        setSettings={props?.setSettings}
      ></CheckPriceMenu>
      <BackButton></BackButton>
      <FloatButton.Group
        trigger="click"
        type="primary"
        icon={<PlusCircleOutlined />}
      >
        <FloatButton
          onClick={() => props.setIsModalProductOpen(true)}
          icon={<BarcodeOutlined />}
        />
        <FloatButton
          onClick={() => props.setIsModalCameraBarcodeReaderOpen(true)}
          icon={<CameraOutlined />}
        />
      </FloatButton.Group>
    </>
  );
}
