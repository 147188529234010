import { message } from "antd";
import React, { useState, useEffect } from "react";
import Footer from "../../components/documents/orders/create/Footer";
import Main from "../../components/documents/orders/create/Main";
import ModalBarcodeCameraReader from "../../components/camera-barcode-reader/ModalBarcodeCameraReader";
import { getAllRecords } from "../../db/dexie-db/functions";
import {
  getDocumentProductsWithoutDeleted,
  getRecordsForTable,
} from "../../db/documents-funtions";
import BottomPageTitle from "../../components/header/BottomPageTitle";
import ModalChooseProducts from "../../components/products/products-tree/ModalChooseProducts";
import {
  DOCUMENTS_TITLES,
  DOCUMENT_OPEN_ACTIONS,
} from "../../components/documents/_CONSTANTS/constants";
import { TABLES } from "../../db/constants/tables";
import { useAuth } from "../../hook/useAuth";
import ModalEnterProduct from "../../components/documents/manage-documents/modal-enter-product/ModalEnterProducts";
import { setNewProductToTable } from "../../db/products-functions";
import { getDiffColumnVisibility } from "../../components/table-utils/last-entered-products/Columns";
import BackButton from "../../components/UI/BackButton";
import { getServiceSettingsObject } from "../../api/settings/settings";
import { servicesSettingsTitles } from "../../components/documents/_CONSTANTS/settings";
import { getOrderColumns } from "../../components/documents/manage-documents/table-components/documents-columns";

const DOCUMENT_TITLE = DOCUMENTS_TITLES.ORDER;
const DOCUMENT_ACTION = DOCUMENT_OPEN_ACTIONS.CREATE;
const CURRENT_DOCUMENT_TABLE = TABLES.CURRENT_ORDER;

export default function CreateOrder() {
  const { user } = useAuth();
  const ordersSettings = getServiceSettingsObject(
    user.settings,
    servicesSettingsTitles.ORDERS
  );

  const [settings, setSettings] = useState({
    ordersSettings,
    showVirtualKeyboard: ordersSettings.virtualKeyboard,
  });
  const [isModalProductOpen, setIsModalProductOpen] = useState(false);
  const [isModalChooseProductsOpen, setIsModalChooseProductsOpen] =
    useState(false);
  const [isModalCameraBarcodeReaderOpen, setIsModalCameraBarcodeReaderOpen] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const products = await getCurrentDocProducts();
    setProducts(getRecordsForTable(products));
    setLoading(false);
  };

  const orderColumns = getOrderColumns();

  const getCurrentDocProducts = async () => {
    try {
      return await getAllRecords(CURRENT_DOCUMENT_TABLE);
    } catch (error) {
      message.error("Помилка отримання даних з поточного замовлення");
      throw error;
    }
  };

  const removeProductFromDocument = async (product) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      product,
      CURRENT_DOCUMENT_TABLE,
      DOCUMENT_ACTION
    );
    setProducts(arrayWithoutDeletedProduct);
  };

  // const setProductQty = async (record, enteredQty) => {
  //   return setProductQuantity(
  //     products,
  //     record,
  //     enteredQty,
  //     CURRENT_DOCUMENT_TABLE,
  //     DOCUMENT_ACTION
  //   );
  // };

  // const handleChangeQty = async (record, value) => {
  //   handleChangeProductQty(
  //     record,
  //     value,
  //     removeProductFromDocument,
  //     setProductQty,
  //     setProducts
  //   );
  // };

  const afterChangeProductQty = () => {
    getData();
  };

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_ACTION}
        afterChangeProductQty={afterChangeProductQty}
        removeProductFromDocument={removeProductFromDocument}
        settings={settings}
        columns={orderColumns}
        dbTable={CURRENT_DOCUMENT_TABLE}
      ></Main>
      {isModalProductOpen && (
        <ModalEnterProduct
          action={DOCUMENT_ACTION}
          dbTable={CURRENT_DOCUMENT_TABLE}
          products={products}
          setProducts={setProducts}
          isModalProductOpen={isModalProductOpen}
          setIsModalProductOpen={setIsModalProductOpen}
          settings={settings}
          weightTemplate={user.weightTemplate}
          afterChangeProductQty={afterChangeProductQty}
          serviceTitle={DOCUMENT_TITLE}
          showDiffColumn={getDiffColumnVisibility(DOCUMENT_TITLE, false)}
          focusInputQty={true}
          autoAddProductAfterNextScan={false}
          removeProductFromDocument={removeProductFromDocument}
          columns={orderColumns}
        ></ModalEnterProduct>
      )}
      {isModalCameraBarcodeReaderOpen && (
        <ModalBarcodeCameraReader
          action={DOCUMENT_ACTION}
          products={products}
          setProducts={setProducts}
          setNewProductToTable={setNewProductToTable}
          focusInputQty={true}
          settings={settings}
          weightTemplate={user.weightTemplate}
          isModalCameraBarcodeReaderOpen={isModalCameraBarcodeReaderOpen}
          setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
          serviceTitle={DOCUMENT_TITLE}
          dbTable={CURRENT_DOCUMENT_TABLE}
          updateProducts={getData}
        ></ModalBarcodeCameraReader>
      )}
      {isModalChooseProductsOpen && (
        <ModalChooseProducts
          products={products}
          setProducts={setProducts}
          setNewProductToTable={setNewProductToTable}
          isModalChooseProductsOpen={isModalChooseProductsOpen}
          setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
          dbTable={CURRENT_DOCUMENT_TABLE}
          settings={settings}
        ></ModalChooseProducts>
      )}
      <BackButton></BackButton>
      <Footer
        setIsModalProductOpen={setIsModalProductOpen}
        setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
        setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
        settings={settings}
        setSettings={setSettings}
      ></Footer>
      <BottomPageTitle title={DOCUMENT_TITLE}></BottomPageTitle>
    </>
  );
}
