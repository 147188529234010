import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

export function getUpdateDate(date = localStorage.getItem("updated")) {
  const dateFromlocalhost = date;
  if (dateFromlocalhost) {
    return dayjs(dateFromlocalhost).format("DD.MM HH:mm:ss");
  } else {
    return false;
  }
}
