import React, { useState } from "react";
import { Table, Divider, Space, Button } from "antd";
import { getTableTotals } from "../../../../utils/getTableTotals";
import { TableTotals } from "../../../table-utils/TableTotals";
import { DOCUMENT_OPEN_ACTIONS } from "../../_CONSTANTS/constants";

import ModalEditProductQty from "../../manage-documents/ModalEditProductQty";
import { DownloadOutlined } from "@ant-design/icons";
import { useTheme } from "../../../../hook/useTheme";
import { sortArrayOfObjectsByTitle } from "../../../../utils/array-functions";

export default function TableProducts(props) {
  const { useDarkMode } = useTheme();
  const redColumn = useDarkMode ? "red-column-dark" : "red-column";
  const blueColumn = useDarkMode ? "blue-column-dark" : "blue-column";
  const greenColumn = useDarkMode ? "green-column-dark" : "green-column";

  const [isModalChangeProductQtyOpen, setIsModalChangeProductQtyOpen] =
    useState(false);
  const [productToChangeQty, setProductToChangeQty] = useState();

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const onDoubleClickHandler = (record) => {
    setProductToChangeQty(record);
    setIsModalChangeProductQtyOpen(true);
  };

  return (
    <div>
      <Table
        onRow={(record, rowIndex) => {
          if (props?.action === DOCUMENT_OPEN_ACTIONS.VIEW) return;
          return {
            onDoubleClick: (event) => {
              onDoubleClickHandler(record);
            },
            onContextMenu: (event) => {
              onDoubleClickHandler(record);
            },
          };
        }}
        tableLayout="fixed"
        columns={props.columns}
        pagination={{
          position: ["bottomCenter"],
          hideOnSinglePage: false,
          showSizeChanger: true,
          pageSize: props?.settings?.pageSize,
        }}
        dataSource={props?.products}
        rowClassName={(record, index) => {
          if (
            props.action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE &&
            !record?.qtyFromSource
          )
            return redColumn;

          if (props.settings?.colorDifferents && record?.qtyFromSource) {
            if (+record.qty === 0) {
              return redColumn;
            }
            if (+record.qty - +record.qtyFromSource > 0) {
              return greenColumn;
            }
            if (+record.qty - +record.qtyFromSource < 0) {
              return blueColumn;
            }
          }
        }}
        size={"middle"}
        onChange={onChange}
        loading={props.loading}
        title={() => {
          const totals = getTableTotals(props?.products);
          return (
            <TableTotals
              quantity={totals.quantity.toFixed(3)}
              total={totals.total.toFixed(2)}
              productsCount={props?.products.length}
            />
          );
        }}
      />
      {isModalChangeProductQtyOpen &&
        props?.action !== DOCUMENT_OPEN_ACTIONS.VIEW && (
          <ModalEditProductQty
            dbTable={props.dbTable}
            isModalChangeLastProductOpen={isModalChangeProductQtyOpen}
            setIsModalChangeLastProductOpen={setIsModalChangeProductQtyOpen}
            afterChangeProductQty={props.afterChangeProductQty}
            productToChangeQty={productToChangeQty}
            removeProductFromDocument={props.removeProductFromDocument}
            settings={props.settings}
          ></ModalEditProductQty>
        )}
    </div>
  );
}
