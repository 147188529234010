import { useEffect } from "react";
import { message } from "antd";
import Quagga from "@ericblade/quagga2";

const scannerConfig = {
  inputStream: {
    name: "Live",
    type: "LiveStream",
    target: document.querySelector("#video"), // Or '#yourElement' (optional)
    constraints: {
      width: {
        min: 400,
        max: 600,
      },
      height: {
        min: 600,
        max: 720,
      },
      // aspectRatio: {
      //     min: 4 / 3,
      //     max: 16 / 9,
      // },
      facingMode: "environment", // or user
    },
    area: {
      // defines rectangle of the detection/localization area
      top: "0%", // top offset
      right: "0%", // right offset
      left: "0%", // left offset
      bottom: "0%", // bottom offset
    },
  },
  decoder: {
    readers: ["ean_reader"],
    multiple: false,
  },
  locate: false,
  frequency: 25,
};

export default function Scanner(props) {
  useEffect(() => {
    toggleScanning(props.isScanning);
  }, [props.isScanning]);

  async function toggleScanning(isScanning) {
    if (isScanning) {
      await init();
      return;
    }
    await stop();
  }

  async function init() {
    await Quagga.init(
      {
        ...scannerConfig,
        inputStream: {
          ...scannerConfig.inputStream,
          target: document.querySelector("#video"),
        },
      },
      function (err) {
        if (err) {
          message.error("Дайте доступ до камери!");
          return;
        }

        Quagga.start();
      }
    );

    Quagga.onDetected(async (data) => {
      await Quagga.stop();
      props.setBarcodeForSearch(data.codeResult.code);
    });
  }

  async function stop() {
    await Quagga.stop();
  }
}
