import { DOCUMENTS_TITLES } from "../../documents/_CONSTANTS/constants";

export const getDiffColumnVisibility = (serviceTitle, createFromSource) => {
  switch (serviceTitle) {
    case DOCUMENTS_TITLES.SALE:
      return false;
    case DOCUMENTS_TITLES.RETURN:
      return false;
    case DOCUMENTS_TITLES.INVOICE:
      return createFromSource ? true : false;
    case DOCUMENTS_TITLES.REVISION:
      return true;
    case DOCUMENTS_TITLES.CHECK_PRICE:
      return false;
    case DOCUMENTS_TITLES.ORDER:
      return false;
    case DOCUMENTS_TITLES.DIPLACEMENT:
      return false;
    default:
      return false;
  }
};
