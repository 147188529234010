import { message } from "antd";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Main from "../../components/documents/orders/create/Main";
import { getRecord } from "../../db/dexie-db/functions";
import {
  getDocumentProductsWithoutDeleted,
  getRecordsForTable,
} from "../../db/documents-funtions";
import BottomPageTitle from "../../components/header/BottomPageTitle";
import { TABLES } from "../../db/constants/tables";
import {
  DOCUMENTS_EDITING_TITLES,
  DOCUMENT_OPEN_ACTIONS,
} from "../../components/documents/_CONSTANTS/constants";
import BackButton from "../../components/UI/BackButton";
import ModalEnterProduct from "../../components/documents/manage-documents/modal-enter-product/ModalEnterProducts";
import ModalBarcodeCameraReader from "../../components/camera-barcode-reader/ModalBarcodeCameraReader";
import ModalChooseProducts from "../../components/products/products-tree/ModalChooseProducts";
import { useAuth } from "../../hook/useAuth";
import { getDiffColumnVisibility } from "../../components/table-utils/last-entered-products/Columns";
import { setNewProductToTable } from "../../db/products-functions";
import Footer from "../../components/documents/orders/create/Footer";
import { getOrderColumns } from "../../components/documents/manage-documents/table-components/documents-columns";

const DOCUMENT_TITLE = DOCUMENTS_EDITING_TITLES.EDITING_ORDER;
const DOCUMENT_ACTION = DOCUMENT_OPEN_ACTIONS.EDIT;
const CURRENT_DOCUMENT_TABLE = undefined;

export default function EditOrder() {
  const { user } = useAuth();
  const params = useParams();
  const id = params.id;
  const [document, setDocument] = useState();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const [isModalProductOpen, setIsModalProductOpen] = useState(false);
  const [isModalChooseProductsOpen, setIsModalChooseProductsOpen] =
    useState(false);
  const [isModalCameraBarcodeReaderOpen, setIsModalCameraBarcodeReaderOpen] =
    useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const doc = await getRecord(TABLES.ORDERS, Number(id));
    if (!doc) {
      message.error("Помилка отримання даних документа");
    }
    const products = doc.products;
    setDocument(doc);
    setProducts(getRecordsForTable(products));
    setLoading(false);
  };
  const orderColumns = getOrderColumns();

  const removeProductFromDocument = async (product) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      product,
      CURRENT_DOCUMENT_TABLE,
      DOCUMENT_ACTION
    );
    setProducts(arrayWithoutDeletedProduct);
  };

  // const setProductQty = async (record, enteredQty) => {
  //   return setProductQuantity(
  //     products,
  //     record,
  //     enteredQty,
  //     CURRENT_DOCUMENT_TABLE,
  //     DOCUMENT_ACTION
  //   );
  // };

  // const handleChangeQty = async (record, value) => {
  //   handleChangeProductQty(
  //     record,
  //     value,
  //     removeProductFromDocument,
  //     setProductQty,
  //     setProducts
  //   );
  // };

  const afterChangeProductQty = (newProduct) => {
    setProducts((prevProducts) => {
      const productToChange = prevProducts.find(
        (product) => product.guid === newProduct.guid
      );

      if (productToChange) productToChange.qty = newProduct.qty;
      return prevProducts;
    });
  };

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_OPEN_ACTIONS.EDIT}
        document={document}
        afterChangeProductQty={afterChangeProductQty}
        removeProductFromDocument={removeProductFromDocument}
        columns={orderColumns}
      ></Main>
      {isModalProductOpen && (
        <ModalEnterProduct
          action={DOCUMENT_ACTION}
          // dbTable={CURRENT_DOCUMENT_TABLE}
          products={products}
          setProducts={setProducts}
          isModalProductOpen={isModalProductOpen}
          setIsModalProductOpen={setIsModalProductOpen}
          settings={{}}
          weightTemplate={user.weightTemplate}
          afterChangeProductQty={afterChangeProductQty}
          serviceTitle={DOCUMENT_TITLE}
          showDiffColumn={getDiffColumnVisibility(DOCUMENT_TITLE, false)}
          focusInputQty={true}
          autoAddProductAfterNextScan={false}
          columns={orderColumns}
        ></ModalEnterProduct>
      )}
      {isModalCameraBarcodeReaderOpen && (
        <ModalBarcodeCameraReader
          action={DOCUMENT_ACTION}
          products={products}
          setProducts={setProducts}
          setNewProductToTable={setNewProductToTable}
          focusInputQty={true}
          settings={{}}
          weightTemplate={user.weightTemplate}
          isModalCameraBarcodeReaderOpen={isModalCameraBarcodeReaderOpen}
          setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
          serviceTitle={DOCUMENT_TITLE}
          // dbTable={CURRENT_DOCUMENT_TABLE}
        ></ModalBarcodeCameraReader>
      )}
      {isModalChooseProductsOpen && (
        <ModalChooseProducts
          products={products}
          setProducts={setProducts}
          setNewProductToTable={setNewProductToTable}
          isModalChooseProductsOpen={isModalChooseProductsOpen}
          setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
          dbTable={CURRENT_DOCUMENT_TABLE}
        ></ModalChooseProducts>
      )}
      <BackButton alertOnClick={true}></BackButton>
      <Footer
        setIsModalProductOpen={setIsModalProductOpen}
        setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
        setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
      ></Footer>
      <BottomPageTitle
        title={DOCUMENTS_EDITING_TITLES.EDITING_ORDER}
      ></BottomPageTitle>
    </>
  );
}
