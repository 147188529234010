import { Tabs } from "antd";
import { useState, useEffect } from "react";
import TableProducts from "../create/TableProducts";
import InfoTab from "../create/InfoTab";
import { OrderedListOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { TABS_TITLES } from "../../_CONSTANTS/constants";

const Main = (props) => {
  const documentForEdit = props?.document;

  const [storageFrom, setStorageFrom] = useState();
  const [storageTo, setStorageTo] = useState();
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (props?.document) {
      setStorageFrom(documentForEdit.storageFrom);
      setStorageTo(documentForEdit.storageTo);
      setComment(documentForEdit.comment);
    }
  }, [props?.document]);

  const deftabs = [
    {
      label: TABS_TITLES.INFO,
      key: "1",
      children: (
        <InfoTab
          disabledFields={true}
          storageFrom={storageFrom}
          storageTo={storageTo}
          setStorageFrom={setStorageFrom}
          setStorageTo={setStorageTo}
          comment={comment}
          setComment={setComment}
        ></InfoTab>
      ),
    },
    {
      label: TABS_TITLES.PRODUCTS,
      key: "2",
      children: (
        <TableProducts
          loading={props.loading}
          products={props.products}
          action={props.action}
          columns={props.columns}
        ></TableProducts>
      ),
    },
  ];

  return (
    <>
      <Tabs
        tabBarStyle={{ marginBottom: "5px" }}
        defaultActiveKey="1"
        size={"middle"}
        items={deftabs}
      />
    </>
  );
};
export default Main;
