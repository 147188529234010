import { Button, Divider, InputNumber, Modal, Space } from "antd";
import { sortArrayOfObjectsByTitle } from "../../../../utils/array-functions";
import { DOCUMENT_OPEN_ACTIONS } from "../../_CONSTANTS/constants";
import { DashOutlined, DownloadOutlined } from "@ant-design/icons";

export const getCheckPriceColumns = (settings) => {
  const defaultColumns = [
    {
      title: "№",
      dataIndex: "index",
      width: "13%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.index - b.index,
    },
    {
      title: "Арт",
      dataIndex: "art",
      width: "15%",
      sorter: (a, b) => a.art - b.art,
    },
    {
      title: "Назва",
      dataIndex: "title",
      width: "58%",
      sorter: (a, b) => sortArrayOfObjectsByTitle(a, b),
      render: (_, record) => {
        const textColor = record.color;
        return <span style={{ color: textColor }}>{record.title}</span>;
      },
    },
    {
      title: "Ціна",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      width: "20%",
      render: (_, record) => {
        return record.price.toFixed(2);
      },
    },
    {
      title: "О",
      dataIndex: "stockQty",
      sorter: (a, b) => a.stockQty - b.stockQty,
      width: "12%",
      render: (_, record) => {
        return record.stockQty;
      },
    },
    {
      title: "К-ть",
      dataIndex: "qty",
      sorter: (a, b) => a.qty - b.qty,
      width: "15%",
    },
  ];

  let resultColumns = defaultColumns;

  const showStockColumn = settings?.showStockColumn;
  const showArtColumn = settings?.showArtColumn;
  if (!showStockColumn) {
    resultColumns = resultColumns.filter(
      (column) => column.dataIndex !== "stockQty"
    );
  }
  if (!showArtColumn) {
    resultColumns = resultColumns.filter(
      (column) => column.dataIndex !== "art"
    );
  }
  return resultColumns;
};

export const getDiplacementColumns = (props) => {
  return [
    {
      title: "№",
      dataIndex: "timeAdded",
      width: "13%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.timeAdded - b.timeAdded,
      render: (_, record) => {
        return record.index;
      },
    },
    {
      title: "Назва",
      dataIndex: "title",
      width: "38%",
      sorter: (a, b) => sortArrayOfObjectsByTitle(a, b),
      render: (_, record) => {
        const textColor = record.color;
        return <span style={{ color: textColor }}>{record.title}</span>;
      },
    },
    {
      title: "Ціна",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      width: "20%",
      render: (_, record) => {
        return record.price.toFixed(2);
      },
    },
    {
      title: "К-ть",
      dataIndex: "qty",
      sorter: (a, b) => a.qty - b.qty,
      width: "20%",
      render: (_, record) => {
        if (
          props.action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE ||
          record?.qtyFromSource
        ) {
          return (
            <>
              <span style={{ fontWeight: "500" }}>
                {record.isWeight
                  ? record.qtyFromSource.toFixed(3)
                  : parseInt(record.qtyFromSource)}
              </span>
              <br></br>
              <span>
                {record.isWeight && record.qty
                  ? record?.qty.toFixed(3)
                  : record.qty
                  ? parseInt(record.qty)
                  : 0}
              </span>
            </>
          );
        } else {
          return (
            <>
              <span>
                {record.isWeight && record.qty
                  ? record?.qty.toFixed(3)
                  : record.qty
                  ? parseInt(record.qty)
                  : 0}
              </span>
            </>
          );
        }
      },
    },
    {
      title: "Сума",
      dataIndex: "sum",
      sorter: (a, b) => a.sum - b.sum,
      width: "25%",
      render: (_, record) => {
        return (+record.qty * +record.price).toFixed(2);
      },
    },
  ];
};

export const getDefaultInvoiceColumns = (props) => {
  return [
    {
      title: "№",
      dataIndex: "timeAdded",
      width: "13%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.timeAdded - b.timeAdded,
      render: (_, record) => {
        return record.index;
      },
    },
    {
      title: "Назва",
      dataIndex: "title",
      width: "40%",
      sorter: (a, b) => sortArrayOfObjectsByTitle(a, b),
      render: (_, record) => {
        const textColor = record.color;
        if (props.settings?.showBarcodes) {
          return (
            <span style={{ color: textColor }}>
              {record.title}
              {!record.barcodes[0] ? (
                ""
              ) : (
                <>
                  <Divider style={{ margin: "3px 0px" }}></Divider>
                  {record.barcodes.map((barcode) => {
                    return (
                      <>
                        <span
                          style={{ fontSize: "small", fontStyle: "italic" }}
                        >
                          {barcode}
                        </span>
                        <br></br>
                      </>
                    );
                  })}
                </>
              )}
            </span>
          );
        } else {
          return <span style={{ color: textColor }}>{record.title}</span>;
        }
      },
    },
    {
      title: "Ціна",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      width: "20%",
      render: (_, record) => {
        return record.price.toFixed(2);
      },
    },
    {
      title: "К-ть",
      dataIndex: "qty",
      sorter: (a, b) => a.qty - b.qty,
      width: "27%",
      render: (_, record) => {
        if (record?.qtyFromSource) {
          return (
            <>
              <Space>
                <span>
                  {record.isWeight && record.qty
                    ? record?.qtyFromSource.toFixed(3)
                    : record.qtyFromSource
                    ? parseInt(record.qtyFromSource)
                    : 0}
                </span>
                {props.action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE ? (
                  <Button
                    style={{ padding: "0px 10px", margin: "0px" }}
                    onClick={(e) => {
                      props.handleChangeQty(record, record?.qtyFromSource);
                    }}
                  >
                    <DownloadOutlined />
                  </Button>
                ) : (
                  ""
                )}
              </Space>
              <br></br>
              <span>
                {record.isWeight && record.qty
                  ? record?.qty.toFixed(3)
                  : record.qty
                  ? parseInt(record.qty)
                  : 0}
              </span>
            </>
          );
        } else {
          return (
            <>
              <span>
                {record.isWeight && record.qty
                  ? record?.qty.toFixed(3)
                  : record.qty
                  ? parseInt(record.qty)
                  : 0}
              </span>
            </>
          );
        }
      },
    },
    {
      title: "Сума",
      dataIndex: "sum",
      sorter: (a, b) => a.sum - b.sum,
      width: "20%",
      render: (_, record) => {
        return (+record.qty * +record.price).toFixed(2);
      },
    },
  ];
};

export const getOrderColumns = () => {
  return [
    {
      title: "№",
      dataIndex: "timeAdded",
      width: "13%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.timeAdded - b.timeAdded,
      render: (_, record) => {
        return record.index;
      },
    },
    {
      title: "Назва",
      dataIndex: "title",
      width: "40%",
      sorter: (a, b) => sortArrayOfObjectsByTitle(a, b),
      render: (_, record) => {
        const textColor = record.color;
        return <span style={{ color: textColor }}>{record.title}</span>;
      },
    },
    {
      title: "Ціна",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      width: "20%",
      render: (_, record) => {
        return record.price.toFixed(2);
      },
    },
    {
      title: "К-ть",
      dataIndex: "qty",
      sorter: (a, b) => a.qty - b.qty,
      width: "25%",
      render: (_, record) => {
        return (
          <>
            <span>
              {record.isWeight && record.qty
                ? record?.qty.toFixed(3)
                : record.qty
                ? parseInt(record.qty)
                : 0}
            </span>
          </>
        );
      },
    },
    {
      title: "Сума",
      dataIndex: "sum",
      sorter: (a, b) => a.sum - b.sum,
      width: "20%",
      render: (_, record) => {
        return (+record.qty * +record.price).toFixed(2);
      },
    },
  ];
};
export const getReturnsColumns = () => {
  return [
    {
      title: "№",
      dataIndex: "timeAdded",
      width: "13%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.timeAdded - b.timeAdded,
      render: (_, record) => {
        return record.index;
      },
    },
    {
      title: "Назва",
      dataIndex: "title",
      width: "40%",
      sorter: (a, b) => sortArrayOfObjectsByTitle(a, b),
      render: (_, record) => {
        const textColor = record.color;
        return <span style={{ color: textColor }}>{record.title}</span>;
      },
    },
    {
      title: "Ціна",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      width: "20%",
      render: (_, record) => {
        return record.price.toFixed(2);
      },
    },
    {
      title: "К-ть",
      dataIndex: "qty",
      sorter: (a, b) => a.qty - b.qty,
      width: "20%",
      render: (_, record) => {
        return (
          <>
            <span>
              {record.isWeight && record.qty
                ? record?.qty.toFixed(3)
                : record.qty
                ? parseInt(record.qty)
                : 0}
            </span>
          </>
        );
      },
    },
    {
      title: "Сума",
      dataIndex: "sum",
      sorter: (a, b) => a.sum - b.sum,
      width: "25%",
      render: (_, record) => {
        return (+record.qty * +record.price).toFixed(2);
      },
    },
  ];
};

export const getSalesColumns = ({ showArtColumn, useCharacteristics }) => {
  const defaultColumns = [
    {
      title: "№",
      dataIndex: "timeAdded",
      width: "13%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.timeAdded - b.timeAdded,
      render: (_, record) => {
        const hasCharacteristics = record?.characteristics;
        if (!hasCharacteristics || !useCharacteristics) return record.index;
        return (
          <>
            <span>{record.index}</span>
            <br></br>
            <DashOutlined />
          </>
        );
      },
    },
    {
      title: "Арт",
      dataIndex: "art",
      width: "15%",
      sorter: (a, b) => a.art - b.art,
    },
    {
      title: "Назва",
      dataIndex: "title",
      width: "30%",
      sorter: (a, b) => sortArrayOfObjectsByTitle(a, b),
      render: (_, record) => {
        const textColor = record.color;
        return <span style={{ color: textColor }}>{record.title}</span>;
      },
    },
    {
      title: "Ціна",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      width: "20%",
      render: (_, record) => {
        return record.price.toFixed(2);
      },
    },
    {
      title: "К-ть",
      dataIndex: "qty",
      sorter: (a, b) => a.qty - b.qty,
      width: "20%",
      render: (_, record) => {
        return (
          <>
            <span>
              {record.isWeight && record.qty
                ? record?.qty.toFixed(3)
                : record.qty
                ? parseInt(record.qty)
                : 0}
            </span>
          </>
        );
      },
    },
    {
      title: "Сума",
      dataIndex: "sum",
      sorter: (a, b) => a.sum - b.sum,
      width: "25%",
      render: (_, record) => {
        return (+record.qty * +record.price).toFixed(2);
      },
    },
  ];

  if (!showArtColumn) {
    return defaultColumns.filter((column) => column.dataIndex !== "art");
  }
  return defaultColumns;
};
