import { Button, Col, Drawer, message, Popconfirm, Row } from "antd";
import React, { useRef, useState } from "react";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { updateRecord } from "../../../db/dexie-db/functions";
import VirtualKeyboard from "../VirtualKeyboard";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

export default function ModalEditProductQty(props) {
  const { productToChangeQty, dbTable } = props;

  const inputNewQty = useRef();

  const decrementQty = () => {
    let currentValue = inputNewQty.current.value;
    if (currentValue === "" || !currentValue || currentValue < 1) {
      inputNewQty.current.value = 0;
    } else {
      currentValue = +currentValue - 1;
      inputNewQty.current.value = currentValue;
    }
  };

  const incrementQty = () => {
    let currentValue = inputNewQty.current.value;
    if (currentValue === 0 || currentValue === "" || !currentValue) {
      inputNewQty.current.value = 1;
    } else {
      currentValue = +currentValue + 1;
      inputNewQty.current.value = currentValue;
    }
  };

  const saveButtonHandler = async () => {
    await processChangeProductQty(
      dbTable,
      productToChangeQty,
      inputNewQty.current.value
    );
  };

  const plusButtonHandler = async () => {
    let qtyInputValue = inputNewQty.current.value;
    const enteredQty = qtyInputValue === "" ? 1 : +qtyInputValue;
    const newQty = !+productToChangeQty.qty
      ? 0 + enteredQty
      : +productToChangeQty.qty + enteredQty;
    await processChangeProductQty(dbTable, productToChangeQty, newQty);
  };

  const processChangeProductQty = async (dbTable, product, newQty) => {
    newQty = +newQty ? +newQty : 0;
    const newProduct = { ...product, qty: newQty };

    if (dbTable) {
      await changeProductQty(dbTable, newProduct);
    }
    props.afterChangeProductQty(newProduct);
    props.setIsModalChangeLastProductOpen(false);
  };

  const changeProductQty = async (dbTable, newProduct) => {
    const updated = await updateRecord(dbTable, newProduct.guid, newProduct);
    if (!updated) {
      message.error("Помилка оновлення кількості");
      return false;
    }
    return true;
  };

  const deleteProductHandler = async (product) => {
    await props.removeProductFromDocument(product);
    props.afterChangeProductQty(product);
    props.setIsModalChangeLastProductOpen(false);
  };

  const footer = (
    <div>
      <Row style={{ textAlign: "center" }}>
        <Col span={12}>
          <Button
            size="large"
            style={{ width: "90%" }}
            key="submit"
            type="primary"
            onClick={plusButtonHandler}
          >
            +
          </Button>
        </Col>
        <Col span={12}>
          <Button
            size="large"
            style={{ width: "90%" }}
            key="save"
            type="primary"
            onClick={saveButtonHandler}
          >
            Зберегти
          </Button>
        </Col>
        {props?.settings?.showVirtualKeyboard && (
          <VirtualKeyboard
            key="VirtualKeyboardModalEditQty"
            isVirtualKeyboardVisible={true}
            focusedInput={inputNewQty}
            updateNewQtyDisplayData={() => {}}
            currentProduct={productToChangeQty}
            onConfirm={saveButtonHandler}
          ></VirtualKeyboard>
        )}
      </Row>
    </div>
  );

  return (
    <>
      <Drawer
        afterOpenChange={(open) => {
          if (open && productToChangeQty) {
            requestAnimationFrame(() => {
              inputNewQty.current.focus();
            });
          }
        }}
        title={<div style={{ width: "95%" }}>{productToChangeQty?.title}</div>}
        styles={{
          wrapper: { height: window.screen.height * 0.35 },
          body: {
            padding: "5px 15px",
            overflow: "hidden",
          },
          header: { padding: "15px" },
          footer: { padding: "10px" },
        }}
        placement="top"
        style={{ height: window.screen.height * 0.35 }}
        open={props.isModalChangeLastProductOpen}
        maskClosable={false}
        onClose={() => {
          props.setIsModalChangeLastProductOpen(false);
        }}
        footer={footer}
        closeIcon={null}
        extra={
          <CloseOutlined
            onClick={async () => {
              props.setIsModalChangeLastProductOpen(false);
            }}
          ></CloseOutlined>
        }
      >
        {productToChangeQty?.stockQty && props?.settings?.showRealCount
          ? `Облікова кількість: ${productToChangeQty.stockQty}`
          : ""}
        <br></br>
        {`Ціна: ${productToChangeQty?.price.toFixed(2)} грн`}
        <br></br>
        <br></br>
        <Row
          style={{
            margin: "0px 15px",
          }}
        >
          <Col span={6} style={{ textAlign: "center" }}>
            <Popconfirm
              title={"Видалити позицію?"}
              onCancel={() => {
                return;
              }}
              onConfirm={() => deleteProductHandler(productToChangeQty)}
            >
              <Button size="large">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Col>

          <Col offset={6} span={12} style={{ textAlign: "center" }}>
            <form onSubmit={() => false}>
              <InputGroup className="mb-3">
                <InputGroup.Text
                  id="decrement-qty-button"
                  onClick={decrementQty}
                >
                  -
                </InputGroup.Text>
                <Form.Control
                  className={"ant-btn ant-btn-default ant-btn-lg"}
                  placeholder="Введіть к-ть"
                  ref={inputNewQty}
                  type={"tel"}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      saveButtonHandler();
                    }
                  }}
                  onInput={(e) => {
                    const value = e.target.value;
                    const formattedValue = value.replace(/,/g, ".");
                    let enteredQty = formattedValue;
                    if (!productToChangeQty.isWeight)
                      enteredQty = parseInt(formattedValue);

                    if (!+enteredQty) return;
                    e.target.value = enteredQty;
                  }}
                  value={inputNewQty.current?.value}
                />
                <InputGroup.Text
                  id="increment-qty-button"
                  onClick={incrementQty}
                >
                  +
                </InputGroup.Text>
              </InputGroup>
            </form>
          </Col>
        </Row>
      </Drawer>
    </>
  );
}
