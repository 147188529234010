import { Space, Switch, message } from "antd";
import React, { useState } from "react";
import {
  getUpdatedSettings,
  getUserSetting,
  updateUserSettInSql,
} from "../../api/settings/settings";

export default function Revision({ user, updateCurrentUser }) {
  const showSumByDiff = getUserSetting("showSumByDiff", user.settings);

  const [showSumByDiffValue, setShowSumByDiffValue] = useState(showSumByDiff);

  const onChangeShowSumByDiff = async (value) => {
    const res = await updateUserSettInSql(
      user,
      "showSumByDiff",
      value,
      "revizia"
    );
    if (!res) {
      return message.error("Помилка оновлення налаштувань!");
    }
    message.success("Оновлено!");
    setShowSumByDiffValue(value);

    const newSettings = getUpdatedSettings(
      user.settings,
      "showSumByDiff",
      value,
      "revizia"
    );
    const updatedLoggedInUser = { ...user, settings: newSettings };

    updateCurrentUser(updatedLoggedInUser);
  };

  return (
    <>
      <Space direction="horizontal">
        <Switch
          checked={showSumByDiffValue}
          size="default"
          onChange={(value) => onChangeShowSumByDiff(value)}
        />
        Показувати суму документа по розбіжності
      </Space>
    </>
  );
}
