import React, { useState, useRef } from "react";
import Categories from "./Categories";
import Products from "./Products";
import { Modal, Tabs, Row, Col, message, Button } from "antd";
import { DatabaseOutlined, SearchOutlined } from "@ant-design/icons";
import {
  addProductToIndexDb,
  getExistingProductFromDocument,
  getProductPrice,
  getProductsToTable,
  getProductsWithNew,
  updateProductsInTable,
} from "../../documents/manage-documents/modal-enter-product/enter-products-functions";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FindedProductsTable from "../FindedProductsTable";
import { sortArrayOfObjectsByTitle } from "../../../utils/array-functions";
import { useAuth } from "../../../hook/useAuth";
import { getUserSetting } from "../../../api/settings/settings";
import ModalEnterCharacteristics from "../../documents/manage-documents/modal-enter-characteristics/ModalEnterCharacteristics";
import { searchProductInDbByTitle } from "../../../db/products-functions";
import { playSound } from "../../../utils/play-sound";
import sound from "../../UI/sound/beep-09.mp3";
import ModalProductInfo from "../../documents/manage-documents/modal-product-info/ModalProductInfo";
import { TABS_TITLES } from "../../documents/_CONSTANTS/constants";
import dayjs from "dayjs";

function ModalChooseProducts(props) {
  const inputQtyRef = useRef();
  const inputPriceRef = useRef();
  const searchInputRef = useRef();
  const { user } = useAuth();

  const useCharacteristics = getUserSetting(
    "useCharacteristics",
    user.settings,
    "global"
  );

  const [productsToTable, setProductsToTable] = useState([]);
  const [currentProduct, setCurrentProduct] = useState();
  const [findedProducts, setFindedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalEnterCharacteristicsOpen, setIsModalEnterCharacteristicsOpen] =
    useState(false);
  const [isModalProductInfoOpen, setIsModalProductInfoOpen] = useState(false);
  const [productForShowInfo, setProductForShowInfo] = useState();
  const [activeKey, setActiveKey] = useState();
  const [loading, setLoading] = useState(true);

  const handleCancel = async () => {
    props.setIsModalChooseProductsOpen(false);
    if (props.dbTable) {
      await updateProductsInTable(props.dbTable, props.setProducts);
    }
  };

  const changeKey = (key) => {
    setActiveKey(key);
  };

  const setModalTitle = (product) => {
    const modalTitle = document.getElementById("modalTitle");
    const currentProductData = document.getElementById("currentProductData");
    const enteredQtyData = document.getElementById("enteredQtyData");

    currentProductData.textContent = "";
    modalTitle.textContent = "";
    enteredQtyData.textContent = "";
    if (!product) {
      return modalTitle.append("Введіть дані");
    }
    const productTitle = product.title;
    const price = "Ціна: " + product.price.toFixed(2);
    const showStockQty = props?.showStockQty;
    const stockQty = showStockQty === false ? 0 : product.stockQty;
    const stock = "Залишок: " + stockQty;
    modalTitle.append(productTitle);

    currentProductData.append(price);
    currentProductData.append(document.createElement("br"));
    currentProductData.append(stock);
  };

  const setProductPrice = (price) => {
    if (inputPriceRef.current) inputPriceRef.current.value = price;
  };

  const addProductWithCharacteristicsToCurrentDocument = (
    productStructure,
    products
  ) => {
    addProductToCurrentDocument(productStructure, products);
  };

  const enterHandler = async (enteredData, products = props.products) => {
    if (!currentProduct) {
      return message.info("Виберіть товар");
    }
    if (enteredData.length > 6) {
      return message.error("Введіть правильну кількість!");
    }
    const product = { ...currentProduct };
    product.qty = +enteredData ? +enteredData : 1;

    const existingProduct = await getExistingProductFromDocument({
      product,
      products: props.products,
      dbTable: props.dbTable,
    });

    if (existingProduct) {
      product.qty = existingProduct.qty + product.qty;
    }

    // const productToTableStructure = await getProductToTable(
    //   product,
    //   props.dbTable
    // );

    if (props?.settings?.askPrice && inputPriceRef.current) {
      const newPrice = getProductPrice(
        inputPriceRef,
        product,
        product,
        products
      );
      product.price = newPrice;
    }

    setCurrentProduct(undefined);
    setModalTitle(undefined);
    inputPriceRef.current.value = "";
    return addProductToCurrentDocument(product, products);
  };

  const decrementQty = () => {
    if (!currentProduct) return message.error("Не вибраний товар!");
    const qtyInputValue = inputQtyRef.current.value;
    if (
      qtyInputValue === 0 ||
      qtyInputValue === "" ||
      qtyInputValue === 1 ||
      qtyInputValue <= 0
    ) {
      inputQtyRef.current.value = currentProduct?.isWeight ? 0.01 : 1;
    } else {
      inputQtyRef.current.value = qtyInputValue - 1;
    }
  };

  const incrementQty = () => {
    if (!currentProduct) return message.error("Не вибраний товар!");
    const qtyInputValue = +inputQtyRef.current.value;
    if (qtyInputValue === 0 || qtyInputValue === "") {
      inputQtyRef.current.value = 1;
    } else {
      inputQtyRef.current.value = qtyInputValue + 1;
    }
  };
  const onRowClick = async (record) => {
    if (props?.onRowClick) return props?.onRowClick(record);

    if (useCharacteristics && Boolean(record?.characteristics))
      return showModalCharacteristics(record);

    const productFromCurrentDoc = props.products.find(
      (prod) => prod.guid === record.guid
    );
    if (productFromCurrentDoc?.qtyFromSource) {
      record.qtyFromSource = productFromCurrentDoc.qtyFromSource;
    }

    setCurrentProduct(record);
    setModalTitle(record);
    setProductPrice(record?.price);
    setTimeout(() => {
      inputQtyRef.current.focus();
    }, 100);
  };

  const showModalCharacteristics = (product) => {
    setCurrentProduct(product);
    setIsModalEnterCharacteristicsOpen(true);
  };
  const processProductNotFound = (enteredData) => {
    if (props?.settings?.sounds) {
      playSound(sound);
    }
    message.error("Товар " + enteredData + " не знайдено!");
    setCurrentProduct(undefined);
    setModalTitle(undefined);
    setProductPrice("");
    // inputDataRef?.current?.focus();
  };

  const onContextMenu = (record) => {
    setProductForShowInfo(record);
    setIsModalProductInfoOpen(true);
  };

  const processSearchProductsByTitle = async (enteredData) => {
    setIsLoading(true);
    const products = await searchProductInDbByTitle(enteredData);
    if (!products.length || !products[0]) {
      setIsLoading(false);
      return processProductNotFound(enteredData);
    }

    const sortedByTitleProducts = products.sort(sortArrayOfObjectsByTitle);
    setFindedProducts(sortedByTitleProducts);
    setIsLoading(false);
    return;
  };
  const afterCloseModalEnterCharacteristics = () => {
    setModalTitle(undefined);
    setCurrentProduct(undefined);
    if (activeKey === "3") searchInputRef.current.focus();
  };

  const items = [
    {
      label: (
        <span>
          <DatabaseOutlined /> Категорії
        </span>
      ),
      key: "1",
      children: (
        <Categories
          priceType={props.priceType}
          setProductsToTable={setProductsToTable}
          setLoading={setLoading}
          setActiveKey={setActiveKey}
        ></Categories>
      ),
    },
    {
      label: TABS_TITLES.PRODUCTS,
      key: "2",
      children: (
        <Products
          loading={loading}
          productsToTable={productsToTable}
          products={props.products}
          setProducts={props.setProducts}
          setCurrentProduct={setCurrentProduct}
          setModalTitle={setModalTitle}
          setProductPrice={setProductPrice}
          focusedInput={inputQtyRef}
          settings={props.settings}
          showStockQty={props?.showStockQty}
          onRowClick={onRowClick}
        ></Products>
      ),
    },
    {
      label: (
        <span>
          <SearchOutlined /> Пошук
        </span>
      ),
      key: "3",
      children: (
        <>
          <form onSubmit={() => false}>
            <InputGroup className="mb-3">
              <Form.Control
                className="searchProduct"
                placeholder="Введіть дані"
                ref={searchInputRef}
                type={"text"}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    const enteredData = e.target.value;
                    e.target.value = "";
                    processSearchProductsByTitle(enteredData);
                  }
                }}
                autoFocus={false}
              />
              <InputGroup.Text
                onClick={() => {
                  const enteredData = searchInputRef.current.value;
                  searchInputRef.current.value = "";
                  processSearchProductsByTitle(enteredData);
                  // enterHandler(enteredData);
                  // inputDataRef.current.value = "";
                }}
              >
                <SearchOutlined></SearchOutlined>
              </InputGroup.Text>
            </InputGroup>
          </form>
          <FindedProductsTable
            key="FindedProductsTable"
            findedProducts={findedProducts}
            onRowClick={onRowClick}
            onContextMenu={onContextMenu}
            isloading={isLoading}
          ></FindedProductsTable>
          <ModalProductInfo
            open={isModalProductInfoOpen}
            setIsOpen={setIsModalProductInfoOpen}
            setIsModalProductInfoOpen={setIsModalProductInfoOpen}
            product={productForShowInfo}
          ></ModalProductInfo>
        </>
      ),
    },
  ];

  const newModalTitle = (
    <>
      <Row>
        <Col id="modalTitle" span={20}>
          Введіть дані
        </Col>
      </Row>
    </>
  );

  const addProductToCurrentDocument = async (product, products) => {
    product.timeAdded = +dayjs().valueOf().toString();
    product.key = product.timeAdded;

    if (props.dbTable) {
      const added = await addProductToIndexDb(props.dbTable, product);
      if (!added) return message.error("Помилка додавання товару у документ!");
      // setLast3Products(await get3LastProducts());
    } else {
      const newProducts = getProductsWithNew(product, products);
      const productsToTable = getProductsToTable(newProducts);
      props.setProducts(productsToTable);
    }

    if (activeKey === "3" && !isModalEnterCharacteristicsOpen)
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 100);
  };

  return (
    <>
      <Modal
        title={newModalTitle}
        style={{ top: "10px", padding: "10px 15px !important" }}
        className="modalEnterProduct"
        open={props.isModalChooseProductsOpen}
        footer={[]}
        onCancel={handleCancel}
        // afterClose={() => {
        //   if (props?.afterClose) props.afterClose();
        // }}
      >
        <Row>
          <Col
            id="currentProductData"
            style={{ padding: "10px 0px" }}
            span={14}
          ></Col>
          <Col
            id="enteredQtyData"
            style={{
              textAlign: "right",
              padding: "10px 0px",
              fontSize: "16px",
            }}
            span={10}
          ></Col>
        </Row>

        {currentProduct && !currentProduct?.characteristics ? (
          <>
            <Row>
              <Col span={11}>
                <InputGroup className="mb-3">
                  <InputGroup.Text onClick={decrementQty}>-</InputGroup.Text>
                  <Form.Control
                    className="inputData"
                    type="tel"
                    placeholder="К-ть"
                    ref={inputQtyRef}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        const enteredData = e.target.value;
                        enterHandler(enteredData);
                        e.target.value = "";
                      }
                    }}
                    autoFocus={false}
                  />
                  <InputGroup.Text onClick={incrementQty}>+</InputGroup.Text>
                </InputGroup>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <InputGroup className="mb-3">
                  <Form.Control
                    className="inputData"
                    type="tel"
                    placeholder="Ціна"
                    ref={inputPriceRef}
                    autoFocus={false}
                    disabled={props?.settings?.askPrice ? false : true}
                  />
                  <InputGroup.Text>грн</InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Button
                  key="submit"
                  onClick={handleCancel}
                  style={{ width: "100%" }}
                >
                  Скасувати
                </Button>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {
                    const enteredData = inputQtyRef.current.value;
                    enterHandler(enteredData);
                    inputQtyRef.current.value = "";
                  }}
                  style={{ width: "100%" }}
                >
                  Додати
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}

        <Tabs
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={changeKey}
          items={items}
        ></Tabs>
        {isModalEnterCharacteristicsOpen && (
          <ModalEnterCharacteristics
            key="ModalEnterCharacteristics"
            isModalEnterCharacteristicsOpen={isModalEnterCharacteristicsOpen}
            setIsModalEnterCharacteristicsOpen={
              setIsModalEnterCharacteristicsOpen
            }
            product={currentProduct}
            setIsModalProductOpen={props.setIsModalProductOpen}
            products={props.products}
            afterClose={afterCloseModalEnterCharacteristics}
            addProductToCurrentDocument={
              addProductWithCharacteristicsToCurrentDocument
            }
            dbTable={props.dbTable}
            serviceTitle={props?.serviceTitle}
          ></ModalEnterCharacteristics>
        )}
      </Modal>
    </>
  );
}

export default ModalChooseProducts;
