import { Button, Space, Switch } from "antd";

import React from "react";
import Menu from "../../../menu/Menu";
import { useAuth } from "../../../../hook/useAuth";
import {
  getUpdatedSettings,
  updateUserSettInSql,
} from "../../../../api/settings/settings";
import { servicesSettingsTitles } from "../../_CONSTANTS/settings";

export default function MenuComponent(props) {
  const { user, updateCurrentUser } = useAuth();

  const onChangeShowVirtualKeyboard = (value) => {
    props.setSettings({ ...props.settings, showVirtualKeyboard: value });
    updateUserSettInSql(
      user,
      "virtualKeyboard",
      value,
      servicesSettingsTitles.ORDERS
    );

    const updatedSettings = getUpdatedSettings(
      user.settings,
      "virtualKeyboard",
      value,
      servicesSettingsTitles.ORDERS
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };
    updateCurrentUser(updatedLoggedInUser);
  };

  const settingsTab = (
    <>
      <Space direction="horizontal">
        <Switch
          checked={props?.settings?.showVirtualKeyboard}
          size="default"
          onChange={onChangeShowVirtualKeyboard}
        />
        Віртуальна клавіатура
      </Space>
    </>
  );
  return (
    <>
      <Menu settingsTab={settingsTab}></Menu>
    </>
  );
}
