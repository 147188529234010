import { Space, Switch } from "antd";
import React from "react";
import Menu from "../../../menu/Menu";
import { servicesSettingsTitles } from "../../_CONSTANTS/settings";
import {
  setUpdatedUserWithNewSettings,
  updateUserSettInSql,
} from "../../../../api/settings/settings";
import { useAuth } from "../../../../hook/useAuth";

export default function CheckPriceMenu(props) {
  const { user, updateCurrentUser } = useAuth();
  const onChangeShowStockColumn = (value) => {
    props.setSettings({ ...props.settings, showStockColumn: value });
    updateUserSettInSql(
      user,
      "showStockColumn",
      value,
      servicesSettingsTitles.CHECK_PRICE
    );
    setUpdatedUserWithNewSettings(
      user.settings,
      "showStockColumn",
      value,
      servicesSettingsTitles.CHECK_PRICE,
      user,
      updateCurrentUser
    );
  };
  const onChangeShowArtColumn = (value) => {
    props.setSettings({ ...props.settings, showArtColumn: value });
    updateUserSettInSql(
      user,
      "showArtColumn",
      value,
      servicesSettingsTitles.CHECK_PRICE
    );
    setUpdatedUserWithNewSettings(
      user.settings,
      "showArtColumn",
      value,
      servicesSettingsTitles.CHECK_PRICE,
      user,
      updateCurrentUser
    );
  };
  const settingsTab = (
    <>
      <Space direction="vertical">
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.showStockColumn}
            size="default"
            onChange={onChangeShowStockColumn}
          />
          Показувати к-ть облік
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.showArtColumn}
            size="default"
            onChange={onChangeShowArtColumn}
          />
          Показувати артикул
        </Space>
      </Space>
    </>
  );
  return (
    <>
      <Menu settingsTab={settingsTab}></Menu>
    </>
  );
}
