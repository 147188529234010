import React, { useMemo } from "react";
import { Divider } from "antd";

const BottomPageTitle = function BottomPageTitle(props) {
  const BottomPageTitleMemo = useMemo(() => {
    return (
      <Divider
        children={props?.title}
        style={{
          position: "fixed",
          bottom: "40px",
          justifyContent: "center",
        }}
        dashed
      ></Divider>
    );
  }, [props?.title]);

  return <>{BottomPageTitleMemo}</>;
};

export default BottomPageTitle;
