import {
  Button,
  Col,
  Divider,
  Image,
  Row,
  Space,
  Form,
  Input,
  message,
} from "antd";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/login.css";
import { sendTGMessageTolPrivate } from "../statistics/statistics";

export default function Registration() {
  const phoneRef = useRef(null);
  const userNameRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onFinish = (values) => {
    sendTGMessageTolPrivate(
      `--------------------------------------------------------------------------\n\rРеєстрація через app.waapp. \n\rДані: ${values.firstname}, ${values.phone}\n\r--------------------------------------------------------------------------`
    );
    message.success(
      "Дякуємо за реєстрацію. Ми зв'яжемося з Вами найближчим часом!"
    );
  };

  const onFinishFailed = () => {};

  const socialNetworks = (
    <Space size={"small"}>
      <Image
        preview={false}
        src={`${process.env.PUBLIC_URL}/icons8-instagram.svg`}
        onClick={() =>
          (window.location = "https://www.instagram.com/waapp.com.ua/")
        }
      ></Image>
      <Image
        preview={false}
        src={`${process.env.PUBLIC_URL}/icons8-facebook.svg`}
        onClick={() =>
          (window.location = "https://www.facebook.com/waapp.com.ua")
        }
      ></Image>
      <Image
        preview={false}
        src={`${process.env.PUBLIC_URL}/icons8-tiktok.svg`}
        onClick={() =>
          (window.location = "https://www.tiktok.com/@waapp.com.ua/")
        }
      ></Image>
    </Space>
  );

  return (
    <div className="loginPage">
      <Row>
        <Col offset={2} span={20}>
          <Form
            className="loginForm"
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            size="small"
            layout="vertical"
          >
            <Image
              style={{ width: "50%" }}
              preview={false}
              src={`${process.env.PUBLIC_URL}/waapp-logo-light.svg`}
            ></Image>
            <Divider
              style={{
                color: "white",
                border: "white",
                margin: "0px 0px 10px",
              }}
            >
              Реєстрація
            </Divider>

            <Form.Item
              style={{ padding: "0px !important" }}
              label="Ваше ім'я"
              name="firstname"
              rules={[
                {
                  required: true,
                  message: "Введіть ім'я",
                },
              ]}
            >
              <Input
                ref={userNameRef}
                size="large"
                placeholder="Ім'я"
                allowClear
              />
            </Form.Item>
            <Form.Item
              label="Номер телефону"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Введіть будь ласка номер телефону",
                },
              ]}
            >
              <Input
                type="tel"
                color="white"
                size="large"
                placeholder="0998888888"
                allowClear
                ref={phoneRef}
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={loading}
                style={{ width: "100%", border: "1px solid white" }}
                size="large"
                type="primary"
                htmlType="submit"
              >
                Зареєструватися
              </Button>
            </Form.Item>
            <Divider
              style={{
                color: "white",
                border: "white",
                margin: "5px 0px",
              }}
              children={socialNetworks}
            ></Divider>

            <Space>
              <span
                onClick={() => {
                  navigate("/login");
                }}
                style={{
                  color: "white",
                  justifyContent: "center",
                }}
              >
                Авторизація
              </span>
            </Space>
          </Form>
        </Col>
      </Row>
      <a
        style={{
          color: "white",
          justifyContent: "center",
        }}
      ></a>
    </div>
  );
}
