import { Button, Col, Row } from "antd";
import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

export default function DefaultModalFooter({
  onClickAddToTable,
  askPrice,
  inputDataPrice,
  onFocusPriceInput,
  onBlurPriceInput,
  FooterAddon,
}) {
  return (
    <Row>
      {askPrice ? (
        <>
          <Col
            span={13}
            style={{
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            <InputGroup className="mb-3">
              <Form.Control
                id="default-modal-footer-input-data"
                style={{ padding: "4px 10px" }}
                className="inputData"
                placeholder="Введіть ціну"
                ref={inputDataPrice}
                type={"tel"}
                onFocus={onFocusPriceInput}
                onBlur={onBlurPriceInput}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    onClickAddToTable();
                  }
                }}
              />
              <InputGroup.Text style={{ padding: "4px 10px" }}>
                грн
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <Col span={1}></Col>
          <Col span={10}>
            <Button
              style={{ margin: "0px 0px 15px 0px", width: "100%" }}
              id="submit1"
              type="primary"
              onClick={onClickAddToTable}
            >
              Додати
            </Button>
          </Col>
        </>
      ) : (
        <>
          <Col
            span={10}
            style={{
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            {FooterAddon ? FooterAddon : ""}
          </Col>
          <Col span={4}></Col>
          <Col span={10}>
            <Button
              style={{ margin: "0px 0px 15px 0px", width: "100%" }}
              id="submit2"
              type="primary"
              onClick={onClickAddToTable}
            >
              Додати
            </Button>
          </Col>
        </>
      )}
    </Row>
  );
}
