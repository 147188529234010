import { message } from "antd";
import { getServer } from "../../../utils/Environment";
const server = getServer();

const getDataFromServer = async (url, username, useFtp) => {
  try {
    const result = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ username, action: "importDataTxt", useFtp }),
    });
    const res = await result.json();
    return res;
  } catch (error) {
    // message.error("Помилка оновлення");
    // console.log(error);
    // throw error;
    return false;
  }
};

export async function getDataFromTxt(username, useFtp) {
  const dataFromServer = await getDataFromServer(
    server + "/server/server.php",
    username,
    useFtp
  );

  if (!dataFromServer) {
    return false;
  }

  const data = {
    products: JSON.parse(dataFromServer.products),
    categories: JSON.parse(dataFromServer.categories),
    suppliers: JSON.parse(dataFromServer.suppliers),
    storages: [],
    documents: {
      invoices: [],
      diplacements: [],
      orders: [],
    },
  };
  return data;
}
