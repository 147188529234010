import { Button, Col, Modal, Row, Table } from "antd";
import React from "react";
import TableCharacteristics from "../modal-enter-characteristics/TableCharacteristics";
import { sortArrayOfObjectsByTitle } from "../../../../utils/array-functions";

export default function ModalProductInfo(props) {
  const product = props?.product;
  const title = product?.title;
  const price = product?.price;
  const stockQty = product?.stockQty;
  const info = product?.info;
  const characteristics = product?.characteristics;

  const columns = [
    {
      key: "title",
      title: "Назва",
      dataIndex: "title",
      width: "50%",
      sorter: (a, b) => sortArrayOfObjectsByTitle(a, b),
    },
    {
      key: "price",
      title: "Ціна",
      dataIndex: "price",
      width: "25%",
      sorter: (a, b) => a.price - b.price,
    },
    {
      key: "stockQty",
      title: "Залишок",
      dataIndex: "stockQty",
      width: "25%",
      sorter: (a, b) => a.stockQty - b.stockQty,
    },
  ];

  return (
    <Modal
      key="modalProductInfo"
      title={title}
      style={{ top: "10px", padding: "10px 15px !important" }}
      className="modalProductInfo"
      open={props.open}
      onCancel={() => {
        props.setIsOpen(false);
      }}
      footer={[
        <Button type="primary" onClick={() => props.setIsOpen(false)}>
          Закрити
        </Button>,
      ]}
    >
      <Row>{`Ціна: ${price}`}</Row>
      <Row>{`Залишок: ${stockQty}`}</Row>
      {info ? <Row>{`Інфо: ${info}`}</Row> : ""}
      <br></br>
      {characteristics ? (
        <Table
          columns={columns}
          dataSource={characteristics}
          pagination={{ pageSize: 999, hideOnSinglePage: true }}
          size="small"
        ></Table>
      ) : (
        ""
      )}
    </Modal>
  );
}
