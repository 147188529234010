import { Tabs } from "antd";
import React from "react";
import Global from "./Global";
import Revision from "./Revision";
import Orders from "./Orders";

export default function SettingsTabs(props) {
  const items = [
    {
      label: "Загальні",
      key: "main",
      children: (
        <Global
          user={props.user}
          updateCurrentUser={props.updateCurrentUser}
        ></Global>
      ),
    },
    {
      label: "Ревізія",
      key: "revision",
      children: (
        <Revision
          user={props.user}
          updateCurrentUser={props.updateCurrentUser}
        ></Revision>
      ),
    },
    {
      label: "Замовлення",
      key: "orders",
      children: (
        <Orders
          user={props.user}
          updateCurrentUser={props.updateCurrentUser}
        ></Orders>
      ),
    },
  ];

  return (
    <>
      <Tabs tabPosition={"top"} items={items} />
    </>
  );
}
