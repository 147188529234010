import { Col, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { getAllRecords } from "../../../../db/dexie-db/functions";

const InfoTab = function InfoTab(props) {
  console.log(props.storage);
  const [storages, setStorages] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const storagesFromDb = await getAllRecords("Storages");
    const storagesToSelect = getStoragesForSelect(storagesFromDb);
    setStorages(storagesToSelect);
  };

  const getStoragesForSelect = (suppliersFromDb) => {
    return suppliersFromDb.map((supplier) => {
      return { value: supplier.guid, label: supplier.title };
    });
  };
  const onChangeComment = (event) => {
    props.setComment(event.target.value);
  };

  const onChangeStorage = (value) => {
    props.setStorage(value);
  };

  return (
    <>
      <Row style={{ padding: "20px" }}>
        <Col offset={2} span={20}>
          <Input
            style={{ width: "100%" }}
            size={"large"}
            placeholder="Коментар"
            value={props?.comment}
            disabled={props?.disabledFields}
            onChange={onChangeComment}
          ></Input>
          <br />
          <br />
          <Select
            style={{ width: "100%" }}
            size={"large"}
            showSearch
            placeholder="Склад"
            optionFilterProp="children"
            onChange={onChangeStorage}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={storages}
            value={props?.storage}
            disabled={props?.disabledFields}
          />
        </Col>
      </Row>
    </>
  );
};
export default InfoTab;
