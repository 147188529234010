import React from "react";

export default function DefaulModalBodyAddon({ askPrice }) {
  return (
    <>
      <span id="currentProductStockData"></span>
      {askPrice ? "" : <span id="currentProductPriceData"></span>}
    </>
  );
}
