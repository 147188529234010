import React from "react";
import { Button, Col, DatePicker, Row } from "antd";
import locale from "antd/es/date-picker/locale/uk_UA";
import "dayjs/locale/uk";

export default function DatePickerDropDown(props) {
  return (
    <>
      <Row>
        <Col span={24}>
          <DatePicker
            locale={locale}
            defaultOpen={true}
            format={"DD.MM.YYYY"}
            value={props.selectedKeys[0]}
            onChange={(e) => {
              props.setSelectedKeys(e ? [e] : []);
              props.confirm();
            }}
          ></DatePicker>
          <Button
            type="danger"
            onClick={() => {
              props.clearFilters();
              props.confirm();
            }}
          >
            Скинути
          </Button>
        </Col>
      </Row>
    </>
  );
}
