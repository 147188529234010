import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Input, Modal, Radio, Row, Space } from "antd";
import { PAYMENT_TYPES } from "../_CONSTANTS/constants";

export default function ModalPayment(props) {
  const [isVisiblePaymentButton, setIsVisiblePaymentButton] = useState(true);
  const [restText, setRestText] = useState("");
  const [paySum, setPaySum] = useState();

  useEffect(() => {
    if (props.isModalPaymentOpen) {
      setPaySumInputRef();
    }
  }, [props.isModalPaymentOpen]);

  const UseFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };
    return [htmlElRef, setFocus];
  };
  const [paySumInputRef, setPaySumInputRef] = UseFocus();

  const onChangeClientSum = (value) => {
    setPaySum(value);
    if (+value < +props.sum) {
      setIsVisiblePaymentButton(false);
      return setRestText("Не достатньо");
    }
    setIsVisiblePaymentButton(true);
    const rest = +value - +props.sum;
    setRestText(rest.toFixed(2));
  };

  const onChangePaymentType = (e) => {
    props.setPayType(e.target.value);
  };

  const modalTitle = "До сплати: " + props.sum + " грн";
  const footer = [
    <>
      <Space direction="horizontal">
        <Button
          key="cansel"
          type="primary"
          onClick={() => props.setIsModalPaymentOpen(false)}
        >
          Закрити
        </Button>
        {isVisiblePaymentButton ? (
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              props.saveDocumentHadler();
            }}
          >
            Оплачено
          </Button>
        ) : (
          ""
        )}
      </Space>
    </>,
  ];
  return (
    <>
      <Modal
        title={modalTitle}
        style={{ top: "10px" }}
        open={props.isModalPaymentOpen}
        afterClose={() => {
          setPaySum("");
        }}
        onCancel={() => {
          // setCurrentProduct(undefined);
          props.setIsModalPaymentOpen(false);
        }}
        footer={footer}
      >
        <Row>
          <Col>
            <Radio.Group
              // defaultValue="1"
              value={props.payType}
              buttonStyle="solid"
              onChange={onChangePaymentType}
            >
              <Radio.Button value={PAYMENT_TYPES.CASH}>
                {PAYMENT_TYPES.CASH}
              </Radio.Button>
              <Radio.Button value={PAYMENT_TYPES.CARD}>
                {PAYMENT_TYPES.CARD}
              </Radio.Button>
              <Radio.Button value={PAYMENT_TYPES.DEBT}>
                {PAYMENT_TYPES.DEBT}
              </Radio.Button>
            </Radio.Group>

            {props.payType === PAYMENT_TYPES.CASH ? (
              <Space direction="vertical">
                <br></br>
                <Input
                  // controls={false}
                  addonBefore="Сума від клієнта"
                  addonAfter="грн"
                  placeholder={props.sum}
                  size="large"
                  onChange={(e) =>
                    onChangeClientSum(e.target.value.replace(",", "."))
                  }
                  value={paySum}
                  type={"tel"}
                  ref={paySumInputRef}
                />
                <Input
                  style={{ width: "100%" }}
                  disabled
                  addonAfter="грн"
                  addonBefore={"Решта"}
                  value={restText}
                  size="large"
                ></Input>
              </Space>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Modal>
    </>
  );
}
