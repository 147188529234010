import { message } from "antd";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../components/documents/diplacements/create/Footer";
import ModalEnterProduct from "../../components/documents/manage-documents/modal-enter-product/ModalEnterProducts";
import Main from "../../components/documents/diplacements/Main";
import ModalBarcodeCameraReader from "../../components/camera-barcode-reader/ModalBarcodeCameraReader";
import { getAllRecords } from "../../db/dexie-db/functions";
import {
  getDocumentProductsWithoutDeleted,
  getRecordsForTable,
} from "../../db/documents-funtions";

import BottomPageTitle from "../../components/header/BottomPageTitle";
import ModalChooseProducts from "../../components/products/products-tree/ModalChooseProducts";
import { db } from "../../db/dexie-db/db";
import {
  DOCUMENTS_TITLES,
  DOCUMENT_OPEN_ACTIONS,
} from "../../components/documents/_CONSTANTS/constants";
import { TABLES } from "../../db/constants/tables";
import { setNewProductToTable } from "../../db/products-functions";
import { useAuth } from "../../hook/useAuth";
import { getDiffColumnVisibility } from "../../components/table-utils/last-entered-products/Columns";
import { servicesSettingsTitles } from "../../components/documents/_CONSTANTS/settings";
import { getServiceSettingsObject } from "../../api/settings/settings";
import { getDiplacementColumns } from "../../components/documents/manage-documents/table-components/documents-columns";

const DOCUMENT_TITLE = DOCUMENTS_TITLES.DIPLACEMENT;
const DOCUMENT_ACTION = DOCUMENT_OPEN_ACTIONS.CREATE;
const CURRENT_DOCUMENT_TABLE = TABLES.CURRENT_DIPLACEMENT;

export default function CreateDiplacement() {
  const { user } = useAuth();
  const location = useLocation();
  const docSource = location?.state?.document;

  const diplacementsSettings = getServiceSettingsObject(
    user.settings,
    servicesSettingsTitles.DIPLACEMENTS
  );

  const diplacementColumns = getDiplacementColumns({ action: DOCUMENT_ACTION });

  const [settings, setSettings] = useState({
    diplacementsSettings,
    showVirtualKeyboard: diplacementsSettings.virtualKeyboard,
  });

  const [isModalProductOpen, setIsModalProductOpen] = useState(false);
  const [isModalChooseProductsOpen, setIsModalChooseProductsOpen] =
    useState(false);
  const [isModalCameraBarcodeReaderOpen, setIsModalCameraBarcodeReaderOpen] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const [productsFromSource] = useState(
    docSource ? JSON.parse(JSON.stringify(docSource.products)) : ""
  );

  useEffect(() => {
    getData();
  }, []);

  const setDataFromSource = async () => {
    const productsFromSourceWithoutQty = productsFromSource.map((prod) => ({
      ...prod,
      qty: 0,
      qtyFromSource: prod.qty,
    }));
    setProducts(getRecordsForTable(productsFromSourceWithoutQty));
    await db.CurrentDiplacement.bulkPut(productsFromSourceWithoutQty);
  };

  const getData = async () => {
    const products = await getCurrentDocProducts();
    if (products.length > 0) {
      setProducts(getRecordsForTable(products));
      return setLoading(false);
    }
    if (docSource) {
      setDataFromSource();
      return setLoading(false);
    }
    setLoading(false);
  };

  const getCurrentDocProducts = async () => {
    try {
      return await getAllRecords(CURRENT_DOCUMENT_TABLE);
    } catch (error) {
      message.error("Помилка отримання даних з поточної перевірки цін");
      throw error;
    }
  };

  // const setProductQty = async (record, enteredQty) => {
  //   return setProductQuantity(
  //     products,
  //     record,
  //     enteredQty,
  //     CURRENT_DOCUMENT_TABLE,
  //     DOCUMENT_ACTION
  //   );
  // };

  // const handleChangeQty = async (record, value) => {
  //   handleChangeProductQty(
  //     record,
  //     value,
  //     removeProductFromDocument,
  //     setProductQty,
  //     setProducts
  //   );
  // };
  const afterChangeProductQty = () => {
    getData();
  };

  const removeProductFromDocument = async (product) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      product,
      CURRENT_DOCUMENT_TABLE,
      DOCUMENT_ACTION
    );
    setProducts(arrayWithoutDeletedProduct);
  };

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        document={docSource}
        action={
          docSource ? DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE : DOCUMENT_ACTION
        }
        afterChangeProductQty={afterChangeProductQty}
        removeProductFromDocument={removeProductFromDocument}
        settings={settings}
        columns={diplacementColumns}
        dbTable={CURRENT_DOCUMENT_TABLE}
      ></Main>
      {isModalProductOpen && (
        <ModalEnterProduct
          action={DOCUMENT_ACTION}
          dbTable={CURRENT_DOCUMENT_TABLE}
          products={products}
          setProducts={setProducts}
          isModalProductOpen={isModalProductOpen}
          setIsModalProductOpen={setIsModalProductOpen}
          settings={settings}
          weightTemplate={user.weightTemplate}
          afterChangeProductQty={afterChangeProductQty}
          serviceTitle={DOCUMENT_TITLE}
          showDiffColumn={getDiffColumnVisibility(DOCUMENT_TITLE, false)}
          focusInputQty={true}
          columns={diplacementColumns}
          removeProductFromDocument={removeProductFromDocument}
        ></ModalEnterProduct>
      )}
      {isModalCameraBarcodeReaderOpen && (
        <ModalBarcodeCameraReader
          action={DOCUMENT_ACTION}
          dbTable={CURRENT_DOCUMENT_TABLE}
          products={products}
          setProducts={setProducts}
          setNewProductToTable={setNewProductToTable}
          focusInputQty={true}
          isModalCameraBarcodeReaderOpen={isModalCameraBarcodeReaderOpen}
          setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
          serviceTitle={DOCUMENT_TITLE}
          settings={settings}
          weightTemplate={user.weightTemplate}
          updateProducts={getData}
        ></ModalBarcodeCameraReader>
      )}
      {isModalChooseProductsOpen && (
        <ModalChooseProducts
          action={DOCUMENT_ACTION}
          products={products}
          setProducts={setProducts}
          setNewProductToTable={setNewProductToTable}
          isModalChooseProductsOpen={isModalChooseProductsOpen}
          setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
          dbTable={CURRENT_DOCUMENT_TABLE}
        ></ModalChooseProducts>
      )}
      <Footer
        setIsModalProductOpen={setIsModalProductOpen}
        setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
        setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
        settings={settings}
        setSettings={setSettings}
      ></Footer>
      <BottomPageTitle title={"Нове переміщення"}></BottomPageTitle>
    </>
  );
}
