import { message } from "antd";
import { getUserSetting } from "../settings/settings";
import { getServer } from "../../utils/Environment";

const server = getServer();

const url = server + "/server/server.php";

export const processPrintDocument = async (settings, document) => {
  const printerIpAddress = getUserSetting(
    "printerIpAddress",
    settings,
    "global"
  );
  if (!printerIpAddress) {
    message.error("Заповніть IP адресу принтера для друку!");
    return false;
  }
  try {
    const result = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        document,
        action: "print-realization",
        printerIpAddress,
      }),
    });
    const res = await result.json();
    if (res.status === "success") return true;
    message.error(res.message, 5);
    return false;
  } catch (error) {
    return false;
  }
};
