export function listToTree(list) {
  const map = {};
  const roots = [];

  for (let i = 0; i < list.length; i += 1) {
    map[list[i].code] = i;
    list[i].children = [];
  }

  let node;

  for (let i = 0; i < list.length; i += 1) {
    node = list[i];
    node.key = node.code;
    if (node.parentCode === "" || node.parentCode === "-1") {
      roots.push(node);
    } else {
      try {
        list[map[node.parentCode]].children.push(node);
      } catch (error) {
        console.log("Помилка", node.title);
      }
    }
  }

  return roots;
}

export const createDataTree = (dataset) => {
  const hashTable = Object.create(null);
  dataset.forEach(
    (aData) => (hashTable[aData.ID] = { ...aData, childNodes: [] })
  );
  const dataTree = [];
  dataset.forEach((aData) => {
    if (aData.parentID)
      hashTable[aData.parentID].childNodes.push(hashTable[aData.ID]);
    else dataTree.push(hashTable[aData.ID]);
  });
  return dataTree;
};
