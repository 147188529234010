import React, { useState } from "react";
import { Table, Col, Switch, Row, Divider, Space } from "antd";
import { getTableTotals } from "../../../../utils/getTableTotals";
import {
  CHARACTERISTICS_ACTION,
  DOCUMENT_OPEN_ACTIONS,
} from "../../_CONSTANTS/constants";
import ModalEditProductQty from "../../manage-documents/ModalEditProductQty";
import { getUserSetting } from "../../../../api/settings/settings";
import { useAuth } from "../../../../hook/useAuth";
import ExpandTable from "../../manage-documents/table-components/ExpandTable";
import ModalEnterCharacteristics from "../../manage-documents/modal-enter-characteristics/ModalEnterCharacteristics";

export default function TableProducts(props) {
  const { user } = useAuth();

  const [isModalChangeProductQtyOpen, setIsModalChangeProductQtyOpen] =
    useState(false);
  const [productToChangeQty, setProductToChangeQty] = useState();
  const [isModalEnterCharacteristicsOpen, setIsModalEnterCharacteristicsOpen] =
    useState(false);

  const useCharacteristics = getUserSetting(
    "useCharacteristics",
    user.settings,
    "global"
  );

  const onDoubleClickHandler = (record) => {
    setProductToChangeQty(record);
    if (useCharacteristics && record?.characteristics)
      return setIsModalEnterCharacteristicsOpen(true);

    setIsModalChangeProductQtyOpen(true);
  };

  const onChangeIsReturn = (value) => {
    props.setIsReturn(value);
  };

  const expandable = useCharacteristics
    ? {
        showExpandColumn: false,
        expandRowByClick: true,
        defaultExpandAllRows: true,
        expandedRowRender: (record) => (
          <ExpandTable characteristics={record?.characteristics}></ExpandTable>
        ),
        rowExpandable: (record) => record?.characteristics,
      }
    : {};

  return (
    <div>
      <Table
        onRow={(record, rowIndex) => {
          if (props?.action === DOCUMENT_OPEN_ACTIONS.VIEW) return;
          return {
            onDoubleClick: (event) => {
              onDoubleClickHandler(record);
            },
            onContextMenu: (event) => {
              onDoubleClickHandler(record);
            },
          };
        }}
        tableLayout="fixed"
        columns={props.columns}
        expandable={expandable}
        pagination={{
          position: ["bottomCenter"],
          hideOnSinglePage: true,
        }}
        dataSource={props?.products}
        size={"middle"}
        loading={props.loading}
        title={() => {
          const totals = getTableTotals(props?.products);
          const isReturn = props?.isReturn;
          return (
            <>
              <Row>
                <Col>К-ть: {totals.quantity.toFixed(3)}</Col>
                <Divider type="vertical" dashed={false} />
                <Col>Сума: {totals.total.toFixed(2)}</Col>
                <Divider type="vertical" dashed={false} />
                <Col>
                  <Space direction="horizontal">
                    Повернення
                    <Switch
                      onChange={(value) => onChangeIsReturn(value)}
                      checked={isReturn}
                      disabled={props?.disabledFields}
                      size="default"
                    />
                  </Space>
                </Col>
              </Row>
            </>
          );
        }}
      />
      {isModalEnterCharacteristicsOpen && (
        <ModalEnterCharacteristics
          isModalEnterCharacteristicsOpen={isModalEnterCharacteristicsOpen}
          setIsModalEnterCharacteristicsOpen={
            setIsModalEnterCharacteristicsOpen
          }
          product={productToChangeQty}
          setIsModalProductOpen={props.setIsModalProductOpen}
          products={props.products}
          afterChangeProductQty={props.afterChangeProductQty}
          dbTable={props.dbTable}
          action={CHARACTERISTICS_ACTION.EDIT}
          removeProductFromDocument={props?.removeProductFromDocument}
        ></ModalEnterCharacteristics>
      )}

      {isModalChangeProductQtyOpen &&
        props?.action !== DOCUMENT_OPEN_ACTIONS.VIEW && (
          <ModalEditProductQty
            isModalChangeLastProductOpen={isModalChangeProductQtyOpen}
            setIsModalChangeLastProductOpen={setIsModalChangeProductQtyOpen}
            afterChangeProductQty={props.afterChangeProductQty}
            productToChangeQty={productToChangeQty}
            removeProductFromDocument={props.removeProductFromDocument}
            dbTable={props.dbTable}
            settings={props.settings}
          ></ModalEditProductQty>
        )}
    </div>
  );
}
