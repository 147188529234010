import {
  CameraOutlined,
  BarcodeOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { FloatButton } from "antd";
import React from "react";
import BackButton from "../../../UI/BackButton";

export default function Footer(props) {
  return (
    <>
      {/* <BarcodingMenu
        settings={props?.settings}
        setSettings={props?.setSettings}
        // data={Data}
      ></BarcodingMenu> */}
      <BackButton></BackButton>
      <FloatButton.Group
        trigger="click"
        type="primary"
        icon={<PlusCircleOutlined />}
      >
        <FloatButton
          onClick={() => props.setIsModalProductOpen(true)}
          icon={<BarcodeOutlined />}
        />
        <FloatButton
          onClick={() => {
            props.setIsModalCameraBarcodeReaderOpen(true);
            props.setIsScanningByCamera(true);
          }}
          icon={<CameraOutlined />}
        />
      </FloatButton.Group>
      {/* <BackToTopButton></BackToTopButton> */}
    </>
  );
}
