import { Col, Row } from "antd";
import React from "react";

export default function ExpandTable({ characteristics, showPriceColumn }) {
  return (
    <>
      {characteristics.map((char) => {
        return (
          <Row key={char.guid}>
            <Col span={showPriceColumn ? 12 : 18}>{char.title} </Col>
            {showPriceColumn ? (
              <Col span={6}>
                Ціна: {(+char.price ? +char.price : 0).toFixed(2)}{" "}
              </Col>
            ) : (
              ""
            )}

            <Col span={6}>К-ть: {char?.qty ? (+char.qty).toFixed(2) : 0}</Col>
          </Row>
        );
      })}
    </>
  );
}
