import { Button } from "antd";
import React from "react";

export default function ModalEnterProductFooterAddonExpirationDate({
  clickAddExpirationDate,
}) {
  return (
    <>
      <Button
        style={{ width: "100%" }}
        key="submit"
        type="primary"
        onClick={() => {
          clickAddExpirationDate();
        }}
      >
        + Термін
      </Button>
    </>
  );
}
