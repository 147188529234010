import React, { useState, useRef } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Image,
  Row,
  Col,
  Divider,
  message,
  Space,
} from "antd";
import "../styles/login.css";
import { useAuth } from "../hook/useAuth";
import { getStoredToken, loginNew } from "../api/login/Login";
import { sendTGMessage } from "../statistics/statistics";

export default function LoginNew() {
  const passwordRef = useRef(null);
  const userNameRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { user, signIn } = useAuth();

  if (user) {
    message.success("Ви вже авторизовані!");
    return <Navigate to={"/"}></Navigate>;
  }

  const onFinish = async (values) => {
    window.scrollTo(0, 0);
    userNameRef.current.blur();
    passwordRef.current.blur();

    setLoading(true);

    const username = values.username.toLowerCase();
    const password = values.password;
    const token = getStoredToken();

    try {
      const result = await loginNew(username, password, token);

      if (result.status === "error") {
        setLoading(false);
        return message.error(result.message, 5);
      }
      const user = result.user;
      signIn(user, () => navigate("/", { replace: true }));
    } catch (error) {
      sendTGMessage(
        `Користувач ${username} не зміг увійти в систему через помилку: ${error}`
      );
      setLoading(false);
      console.log(error);
      return message.error("Невідома помилка...", 5);
    }
  };
  const onFinishFailed = (errorInfo) => {
    window.scrollTo(0, 0);
    console.log("Failed:", errorInfo);
  };

  const socialNetworks = (
    <Space size={"small"}>
      <Image
        preview={false}
        src={`${process.env.PUBLIC_URL}/icons8-instagram.svg`}
        onClick={() =>
          (window.location = "https://www.instagram.com/waapp.com.ua/")
        }
      ></Image>
      <Image
        preview={false}
        src={`${process.env.PUBLIC_URL}/icons8-facebook.svg`}
        onClick={() =>
          (window.location = "https://www.facebook.com/waapp.com.ua")
        }
      ></Image>
      <Image
        preview={false}
        src={`${process.env.PUBLIC_URL}/icons8-tiktok.svg`}
        onClick={() =>
          (window.location = "https://www.tiktok.com/@waapp.com.ua/")
        }
      ></Image>
    </Space>
  );

  return (
    <>
      <div className="loginPage">
        <Row>
          <Col offset={2} span={20}>
            <Form
              className="loginForm"
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              size="small"
              layout="vertical"
            >
              <Image
                style={{ width: "50%" }}
                preview={false}
                src={`${process.env.PUBLIC_URL}/waapp-logo-light.svg`}
              ></Image>
              <Divider
                style={{
                  color: "white",
                  border: "white",
                  margin: "0px 0px 10px",
                }}
              >
                Авторизація
              </Divider>

              <>
                <Form.Item
                  style={{ padding: "0px !important" }}
                  label="Ім'я користувача"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Введіть ім'я користувача",
                    },
                  ]}
                >
                  <Input
                    ref={userNameRef}
                    size="large"
                    placeholder="Ім'я користувача"
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  label="Пароль"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Введіть будь ласка пароль",
                    },
                  ]}
                >
                  <Input.Password
                    color="white"
                    size="large"
                    placeholder="Пароль"
                    allowClear
                    ref={passwordRef}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={loading}
                    style={{ width: "100%", border: "1px solid white" }}
                    size="large"
                    type="primary"
                    htmlType="submit"
                  >
                    Вхід
                  </Button>
                </Form.Item>
                <Divider
                  style={{
                    color: "white",
                    border: "white",
                    margin: "5px 0px",
                  }}
                  children={socialNetworks}
                ></Divider>
              </>

              <Space>
                <span
                  onClick={() => {
                    navigate("/registration");
                  }}
                  style={{
                    color: "white",
                    justifyContent: "center",
                  }}
                >
                  Зареєструватися
                </span>
              </Space>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}
