import React from "react";
import { Dropdown, Popconfirm, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import {
  handleDeleteExported,
  handleDeleteImported,
} from "../../../../db/documents-funtions";

export default function DeleteDocumentsComponent(props) {
  const deleteItems = [
    {
      label: "Видалити відправлені",
      key: "1",
      onClick: () => {
        handleDeleteExported(
          props.dbTable,
          props.documents,
          props.getFormattedDocuments,
          props.setDocuments
        );
      },
    },
    {
      label: "Видалити імпортовані",
      key: "2",
      onClick: () => {
        handleDeleteImported(
          props.dbTable,
          props.documents,
          props.getFormattedDocuments,
          props.setDocuments
        );
      },
    },
  ];

  return (
    <>
      {props.documents.length > 0 ? (
        <Popconfirm
          title="Видалити відправлені документи?"
          onConfirm={() => {
            handleDeleteExported(
              props.dbTable,
              props.documents,
              props.getFormattedDocuments,
              props.setDocuments
            );
            return;
          }}
        >
          <Dropdown.Button size="large" menu={{ items: deleteItems }}>
            <DeleteOutlined />
          </Dropdown.Button>
        </Popconfirm>
      ) : (
        <Dropdown.Button size="large" menu={{ items: deleteItems }}>
          <DeleteOutlined />
        </Dropdown.Button>
      )}
    </>
  );
}
