import React from "react";
import { useNavigate } from "react-router-dom";
import { FloatButton, Modal } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
const { confirm } = Modal;

export default function BackButton(props) {
  const navigate = useNavigate();

  const alertOnClick = props?.alertOnClick;

  return (
    <>
      <FloatButton
        icon={<RollbackOutlined />}
        type="primary"
        style={{
          left: 24,
        }}
        onClick={() => {
          if (alertOnClick) {
            return confirm({
              title: "Зміни не будуть збережені, продовжити?",
              okText: "Так",
              cancelText: "Ні",
              onOk: () => {
                navigate(-1);
              },
            });
          } else {
            navigate(-1);
          }
        }}
      />
    </>
  );
}
