import { Modal, Row, Col, message } from "antd";
import React, { useState, useEffect, useRef } from "react";
import Scanner from "./quagga/Scanner";
import ScannerDefault from "./default/ScannerDefault";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { SearchOutlined } from "@ant-design/icons";
import { checkIsWebApiSupport } from "../../utils/barcode-reader";

function ModalBarcodeCameraReaderUniversal(props) {
  const inputDataRef = useRef();

  const [barcodeForSearch, setBarcodeForSearch] = useState();

  const isWebApiSupported = checkIsWebApiSupport();

  useEffect(() => {
    if (props.isModalCameraBarcodeReaderUniversalOpen) {
      props.setIsScanning(true);
    } else {
      props.setIsScanning(false);
    }
  }, [props.isModalCameraBarcodeReaderUniversalOpen]);

  useEffect(() => {
    if (barcodeForSearch) {
      props.processSearchData(barcodeForSearch, props.dbTable);
      setBarcodeForSearch(undefined);
    }
  }, [barcodeForSearch]);

  const closeBarcodeReader = async () => {
    props.setIsScanning(false);
    props.setIsModalCameraBarcodeReaderUniversalOpen(false);
  };

  const enterHandler = async (enteredData) => {
    if (!enteredData) {
      return message.info("Введіть дані");
    }
    props.processSearchData(enteredData, props.dbTable);
  };

  const toggleScanning = () => {
    props.setIsScanning(!props.isScanning);
    //   setCurrentProduct(null);
  };

  return (
    <>
      <Modal
        style={{ top: "10px", padding: "10px 15px !important" }}
        className="modalEnterProduct"
        open={props.isModalCameraBarcodeReaderUniversalOpen}
        onCancel={() => closeBarcodeReader()}
        afterClose={() => props.setIsScanning(false)}
        footer={props?.footer ? props?.footer : null}
        closable={false}
      >
        {isWebApiSupported ? (
          <>
            <canvas
              id="canvas"
              style={{ width: "100%", height: "300px" }}
            ></canvas>
            <ScannerDefault
              isScanning={props.isScanning}
              setBarcodeForSearch={setBarcodeForSearch}
            ></ScannerDefault>
          </>
        ) : (
          <>
            <span id="video"></span>
            <Scanner
              isScanning={props.isScanning}
              // setIsScanning={setIsScanning}
              setBarcodeForSearch={setBarcodeForSearch}
              // searchProduct={processSearchProduct}
            ></Scanner>
          </>
        )}

        <Row>
          <Col span={13}>
            <InputGroup className="mb-3">
              <Form.Control
                className="inputData"
                placeholder="Введіть дані"
                ref={inputDataRef}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    const enteredData = e.target.value;
                    e.target.value = "";
                    enterHandler(enteredData);
                  }
                }}
                autoFocus={false}
              />
              <InputGroup.Text
                onClick={() => {
                  const enteredData = inputDataRef.current.value;
                  enterHandler(enteredData);
                  inputDataRef.current.value = "";
                }}
              >
                <SearchOutlined></SearchOutlined>
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <Col span={1}></Col>
          <Col span={10}>
            <InputGroup.Text
              style={{ justifyContent: "center" }}
              key="continue"
              onClick={toggleScanning}
            >
              {props.isScanning ? "Зупинити" : "Продовжити"}
            </InputGroup.Text>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default ModalBarcodeCameraReaderUniversal;
