import { Button, Col, Modal, Popconfirm, Row, Space } from "antd";
import React from "react";
import TableEditingProductBarcodes from "./TableEditingProductBarcodes";
import { DeleteOutlined } from "@ant-design/icons";

export default function ModalChangeProduct(props) {
  const footer = [
    <>
      <Row style={{ textAlign: "center" }}>
        <Col span={2}>
          <Popconfirm
            title={"Видалити позицію?"}
            onCancel={() => {
              return;
            }}
            onConfirm={async () => {
              props.removeProductFromDocument(props.productToChange);
              props.setIsModalChangeProductOpen(false);
            }}
          >
            <Button size="large">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Col>

        <Col span={12} offset={10}>
          <Button
            size="large"
            style={{ width: "90%" }}
            key="submit"
            type="primary"
            onClick={() => props.setIsModalChangeProductOpen(false)}
          >
            Закрити
          </Button>
        </Col>
      </Row>
    </>,
  ];

  return (
    <>
      <Modal
        title={
          <div style={{ width: "95%" }}>{props.productToChange?.title}</div>
        }
        style={{ top: "10px" }}
        open={props.isModalChangeProductOpen}
        maskClosable={false}
        onCancel={() => {
          props.setIsModalChangeProductOpen(false);
        }}
        footer={footer}
        afterClose={() => {}}
      >
        <TableEditingProductBarcodes
          productToChange={props.productToChange}
          deleteBarcodeHandler={props.deleteBarcodeHandler}
        ></TableEditingProductBarcodes>
        <Row
          style={{
            margin: "15px",
          }}
        >
          <Col span={18} style={{ textAlign: "center" }}></Col>
        </Row>
        <Space direction="horizontal"></Space>
      </Modal>
    </>
  );
}
