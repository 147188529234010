import { message } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Footer from "../../components/documents/invoices/edit/Footer";
import Main from "../../components/documents/invoices/create/Main";
import { getRecord } from "../../db/dexie-db/functions";
import {
  getDocumentProductsWithoutDeleted,
  getRecordsForTable,
} from "../../db/documents-funtions";
import BottomPageTitle from "../../components/header/BottomPageTitle";
import { TABLES } from "../../db/constants/tables";
import {
  DOCUMENTS_EDITING_TITLES,
  DOCUMENT_OPEN_ACTIONS,
} from "../../components/documents/_CONSTANTS/constants";
import { useAuth } from "../../hook/useAuth";
import { getServiceSettingsObject } from "../../api/settings/settings";
import { getDefaultInvoiceColumns } from "../../components/documents/manage-documents/table-components/documents-columns";

export default function EditInvoice() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const invoiceSettings = getServiceSettingsObject(user.settings, "invoice");
  const defaultInvoiceColumns = getDefaultInvoiceColumns(invoiceSettings);
  const params = useParams();
  const id = params.id;
  const [document, setDocument] = useState();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [settings, setSettings] = useState(invoiceSettings);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const doc = await getRecord(TABLES.INVOICES, Number(id));
    if (!doc) {
      message.error("Помилка отримання даних документа");
    }
    const products = doc.products;
    setDocument(doc);
    const badProducts = products.filter((prod) => !prod.title || !prod.guid);
    if (badProducts.length) {
      message.error(
        "Помилка отримання товарів з документу, перевірте правильність вигрузки!"
      );
      return navigate(`/invoices`);
    }
    setProducts(getRecordsForTable(products));
    setLoading(false);
  };

  const removeProductFromDocument = async (product) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      product,
      null,
      DOCUMENT_OPEN_ACTIONS.EDIT
    );
    setProducts(arrayWithoutDeletedProduct);
  };

  // const setProductQty = async (record, enteredQty) => {
  //   return setProductQuantity(
  //     products,
  //     record,
  //     enteredQty,
  //     null,
  //     DOCUMENT_OPEN_ACTIONS.EDIT
  //   );
  // };

  // const handleChangeQty = async (record, value) => {
  //   handleChangeProductQty(
  //     record,
  //     value,
  //     removeProductFromDocument,
  //     setProductQty,
  //     setProducts
  //   );
  // };

  const afterChangeProductQty = (newProduct) => {
    setProducts((prevProducts) => {
      const productToChange = prevProducts.find(
        (product) => product.guid === newProduct.guid
      );

      if (productToChange) productToChange.qty = newProduct.qty;
      return prevProducts;
    });
  };

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_OPEN_ACTIONS.EDIT}
        document={document}
        settings={settings}
        removeProductFromDocument={removeProductFromDocument}
        columns={defaultInvoiceColumns}
        afterChangeProductQty={afterChangeProductQty}
      ></Main>
      <Footer settings={settings} setSettings={setSettings}></Footer>
      <BottomPageTitle
        title={DOCUMENTS_EDITING_TITLES.EDITING_INVOICE}
      ></BottomPageTitle>
    </>
  );
}
