import { Dropdown, Modal, Space, Tabs, message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SaveOutlined, DownOutlined, StopOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  addRecord,
  clearObjectStore,
  updateRecord,
} from "../../../../db/dexie-db/functions";
import { TABLES } from "../../../../db/constants/tables";
import { DOCUMENT_OPEN_ACTIONS, TABS_TITLES } from "../../_CONSTANTS/constants";
import { generateDocBarcode } from "../../../../db/documents-funtions";
import TableProducts from "./TableProducts";

const Main = (props) => {
  const navigate = useNavigate();

  const [loadingSaveButton, setLoadingSaveButton] = useState(false);

  const action = props?.action;
  const documentForEdit = props?.document;

  const handleMenuClick = async () => {
    Modal.confirm({
      title: "Не зберігати?",
      onCancel: () => {
        return;
      },
      onOk: async () => {
        const cleared = await clearObjectStore(TABLES.BARCODING);
        if (!cleared) {
          return message.error("Помилка очищення поточного документу");
        }
        props?.setProducts([]);
        navigate(-1);
      },
      okText: "Ні",
    });
  };

  const items = [
    {
      label: (
        <Space>
          <StopOutlined />
          Скасувати
        </Space>
      ),
      key: "1",

      onClick: handleMenuClick,
    },
  ];

  const handleButtonClick = async () => {
    if (props.products.length === 0) {
      setLoadingSaveButton(false);
      return message.error("Додайте товари");
    }

    setLoadingSaveButton(true);
    switch (action) {
      case DOCUMENT_OPEN_ACTIONS.EDIT:
        if (!documentForEdit || !action) {
          setLoadingSaveButton(false);
          return message.error("Помилка взагалі не реальна");
        }

        const updatedDocument = {
          ...props.document,
          updated: dayjs(),
          exported: "false",
          products: props.products,
          id: props.document.id,
        };
        const updated = await updateRecord(
          TABLES.BARCODING,
          Number(updatedDocument.id),
          updatedDocument
        );
        setLoadingSaveButton(false);
        if (!updated) {
          return message.error("Помилка оновлення документа");
        }
        const cleared = await clearObjectStore(props.dbTable);
        if (!cleared) {
          return message.error("Помилка очищення поточного документа");
        }

        message.success("Оновлено!");
        break;
      case DOCUMENT_OPEN_ACTIONS.CREATE:
        const newDocument = {
          date: dayjs().format("DD.MM.YYYY"),
          created: dayjs(),
          updated: dayjs(),
          exported: "false",
          products: props.products,
          docBarcode: generateDocBarcode(),
        };

        const added = await addRecord(TABLES.BARCODING, newDocument);
        if (!added) {
          setLoadingSaveButton(false);
          return message.error("Помилка збереження документу");
        }
        const clearedCurrentOrder = await clearObjectStore(props.dbTable);
        if (!clearedCurrentOrder) {
          return message.error("Помилка очищення поточного документа");
        }
        message.success("Збережено");
        setLoadingSaveButton(false);
        break;
      default:
        break;
    }

    return navigate("/barcoding");
  };

  const operations = (
    <>
      <Dropdown.Button
        loading={loadingSaveButton}
        size="middle"
        icon={<DownOutlined />}
        menu={{
          items,
        }}
        onClick={handleButtonClick}
      >
        <SaveOutlined /> Зберегти
      </Dropdown.Button>
    </>
  );

  const OperationsSlot = {
    right: operations,
  };

  const deftabs = [
    {
      label: TABS_TITLES.PRODUCTS,
      key: "1",
      children: (
        <TableProducts
          loading={props.loading}
          products={props.products}
          setProducts={props.setProducts}
          handleChangeQty={props.handleChangeQty}
          removeProductFromDocument={props.removeProductFromDocument}
          settings={props.settings}
          deleteBarcodeHandler={props.deleteBarcodeHandler}
        ></TableProducts>
      ),
    },
  ];

  return (
    <>
      <Tabs
        // onChange={onTablClickFunction}
        // centered
        tabBarStyle={{ marginBottom: "5px" }}
        tabBarExtraContent={OperationsSlot}
        defaultActiveKey="1"
        // activeKey={activeKey}
        size={"middle"}
        items={deftabs}
      />
    </>
  );
};
export default Main;
