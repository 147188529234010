import React from "react";
import { Dropdown } from "antd";
import {
  handleExportTodayDocuments,
  handleExportUnexported,
} from "../../../../db/documents-funtions";
import { CloudUploadOutlined } from "@ant-design/icons";

export default function ExportDocumentsComponent(props) {
  const exportItems = [
    {
      label: "Відправити сьогоднішні",
      key: "1",
      onClick: () =>
        handleExportTodayDocuments(
          props.dbTable,
          props.setDocumentsToExport,
          props.setDocumentsToExportOriginal,
          props.setIsModalExportOpen,
          props.getDocumentsForExport,
          props.setLoadingExportButton
        ),
    },
    {
      label: "Відправити невідправлені",
      key: "2",
      onClick: () =>
        handleExportUnexported(
          props.dbTable,
          props.setDocumentsToExport,
          props.setDocumentsToExportOriginal,
          props.setIsModalExportOpen,
          props.getDocumentsForExport,
          props.setLoadingExportButton
        ),
    },
  ];

  return (
    <>
      <Dropdown.Button
        disabled={props.loadingExportButton}
        loading={props.loadingExportButton}
        onClick={() => {
          handleExportUnexported(
            props.dbTable,
            props.setDocumentsToExport,
            props.setDocumentsToExportOriginal,
            props.setIsModalExportOpen,
            props.getDocumentsForExport,
            props.setLoadingExportButton
          );
        }}
        size="large"
        menu={{ items: exportItems }}
      >
        <CloudUploadOutlined />
      </Dropdown.Button>
    </>
  );
}
