import React, { createContext, useEffect, useState } from "react";
import { useAuth } from "../hook/useAuth";
import { getUserSetting } from "../api/settings/settings";

export const ThemeContext = createContext(null);

export default function ThemeProvider({ children }) {
  const { user } = useAuth();
  let userUseDarkMode = false;
  if (user)
    userUseDarkMode = getUserSetting("darkMode", user.settings, "global");

  const [useDarkMode, setUseDarkMode] = useState(userUseDarkMode);

  const toggleThemeMode = (value) => {
    setUseDarkMode(value);
  };

  useEffect(() => {
    toggleThemeMode(userUseDarkMode);
  }, []);

  const value = { useDarkMode, toggleThemeMode };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
}
