import { getServer } from "../../utils/Environment";
import Cookies from "universal-cookie";
import { getDeviceInfoData } from "./MediaDevicesInfo";

const server = getServer();

export async function loginNew(username, password, token) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({
      username,
      password,
      token,
      action: "loginNew",
    }),
  });

  return await responce.json();
}

export const getStoredToken = () => {
  const cookies = new Cookies();
  return cookies.get("_auth_token");
};

export async function logOut(userId, token) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ userId, token, action: "logout" }),
  });

  return await responce.json();
}

export async function autoLogin(user, token) {
  const deviceData = await getDeviceInfoData();
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({
      user,
      token,
      action: "autoLogin",
      deviceData,
    }),
  });

  return await responce.json();
}
