import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { message } from "antd";
import CheckPriceFooter from "../../components/documents/check-price/check-price-create/CheckPriceFooter";
import Main from "../../components/documents/check-price/check-price-create/Main";
import ModalBarcodeCameraReader from "../../components/camera-barcode-reader/ModalBarcodeCameraReader";
import { getRecord } from "../../db/dexie-db/functions";
import {
  getDocumentProductsWithoutDeleted,
  getRecordsForTable,
} from "../../db/documents-funtions";
import { setNewProductToTable } from "../../db/products-functions";
import {
  DOCUMENTS_EDITING_TITLES,
  DOCUMENT_OPEN_ACTIONS,
} from "../../components/documents/_CONSTANTS/constants";
import ModalEnterProducts from "../../components/documents/manage-documents/modal-enter-product/ModalEnterProducts";
import ModalEnterProductBodyAddon from "../../components/documents/check-price/ModalEnterProductBodyAddon";
import ModalEnterProductFooterAddon from "../../components/documents/check-price/ModalEnterProductFooterAddon";
import { TABLES } from "../../db/constants/tables";
import BottomPageTitle from "../../components/header/BottomPageTitle";
import { getServiceSettingsObject } from "../../api/settings/settings";
import { useAuth } from "../../hook/useAuth";
import { servicesSettingsTitles } from "../../components/documents/_CONSTANTS/settings";
import { getCheckPriceColumns } from "../../components/documents/manage-documents/table-components/documents-columns";

const DOCUMENT_TITLE = DOCUMENTS_EDITING_TITLES.EDITING_CHECK_PRICE;
const DOCUMENT_ACTION = DOCUMENT_OPEN_ACTIONS.EDIT;
const CURRENT_DOCUMENT_TABLE = undefined;

export default function CheckPriceEdit(props) {
  const { user } = useAuth();
  const checkPriceSettings = getServiceSettingsObject(
    user.settings,
    servicesSettingsTitles.CHECK_PRICE
  );

  const params = useParams();
  const id = params.id;

  const [isModalProductOpen, setIsModalProductOpen] = useState(false);
  const [isModalCameraBarcodeReaderOpen, setIsModalCameraBarcodeReaderOpen] =
    useState(false);
  const [settings, setSettings] = useState(checkPriceSettings);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [document, setDocument] = useState();
  const [lastEnteredProducts, setLastEnteredProducts] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const doc = await getRecord(TABLES.CHECK_PRICE, Number(id));
      if (!doc) {
        return message.error("Помилка отримання документа №" + id);
      }
      setProducts(getRecordsForTable(doc.products));
      setDocument(doc);
      setLoading(false);
    };

    getData();
  }, [id]);

  const removeProductFromDocument = async (product) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      product,
      CURRENT_DOCUMENT_TABLE,
      DOCUMENT_ACTION
    );
    setProducts(arrayWithoutDeletedProduct);
  };

  const checkPriceColumns = getCheckPriceColumns(checkPriceSettings);

  const afterChangeProductQty = (newProduct) => {
    setProducts((prevProducts) => {
      const productToChange = prevProducts.find(
        (product) => product.guid === newProduct.guid
      );

      if (productToChange) productToChange.qty = newProduct.qty;
      return prevProducts;
    });
  };

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_OPEN_ACTIONS.EDIT}
        document={document}
        afterChangeProductQty={afterChangeProductQty}
        removeProductFromDocument={removeProductFromDocument}
        settings={settings}
        columns={checkPriceColumns}
      ></Main>
      {isModalProductOpen && (
        <ModalEnterProducts
          action={DOCUMENT_ACTION}
          dbTable={CURRENT_DOCUMENT_TABLE}
          products={products}
          setProducts={setProducts}
          lastEnteredProducts={lastEnteredProducts}
          isModalProductOpen={isModalProductOpen}
          setIsModalProductOpen={setIsModalProductOpen}
          setLastEnteredProducts={setLastEnteredProducts}
          serviceTitle={DOCUMENT_TITLE}
          BodyAddon={ModalEnterProductBodyAddon}
          FooterAddon={ModalEnterProductFooterAddon}
          columns={checkPriceColumns}
          afterChangeProductQty={afterChangeProductQty}
        ></ModalEnterProducts>
      )}
      {isModalCameraBarcodeReaderOpen && (
        <ModalBarcodeCameraReader
          action={DOCUMENT_ACTION}
          dbTable={CURRENT_DOCUMENT_TABLE}
          products={products}
          setProducts={setProducts}
          setNewProductToTable={setNewProductToTable}
          isModalCameraBarcodeReaderOpen={isModalCameraBarcodeReaderOpen}
          setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
          focusInputQty={false}
          serviceTitle={DOCUMENT_TITLE}
        ></ModalBarcodeCameraReader>
      )}
      <CheckPriceFooter
        setIsModalProductOpen={setIsModalProductOpen}
        setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
        settings={settings}
        setSettings={setSettings}
      ></CheckPriceFooter>
      <BottomPageTitle title={DOCUMENT_TITLE}></BottomPageTitle>
    </>
  );
}
