import {
  BarcodeOutlined,
  PlusOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { FloatButton } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function Footer(props) {
  const navigate = useNavigate();
  return (
    <>
      <FloatButton
        icon={<RollbackOutlined />}
        type="primary"
        style={{
          left: 24,
        }}
        onClick={() => {
          navigate("/");
        }}
      />
      {props.useCheckingOrdersBarcodes ? (
        <>
          <FloatButton
            type="primary"
            onClick={() => {
              props.setIsModalCameraBarcodeReaderUniversalOpen(true);
            }}
            icon={<BarcodeOutlined />}
          />
        </>
      ) : (
        <FloatButton
          type="primary"
          onClick={() => navigate("create")}
          icon={<PlusOutlined />}
        />
      )}
    </>
  );
}
