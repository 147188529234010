import React, { useState, useEffect, useRef } from "react";
import { Modal, Tree, message, Checkbox, Button, Row, Col } from "antd";
import { listToTree } from "../../../utils/list-to-tree";
import { getAllRecords, getRecords } from "../../../db/dexie-db/functions";
import { db } from "../../../db/dexie-db/db";
import { getUniqueArrayOfObjects } from "../../../utils/array-functions";
import { TABLES } from "../../../db/constants/tables";
import {
  getProductsWithIndexes,
  getProductsWithTimeAdded,
  getSortedProductsByTimeAdded,
} from "../../../db/products-functions";
const { confirm } = Modal;

const { DirectoryTree } = Tree;

function ModalFoldersChoosing(props) {
  const [categories, setCategories] = useState();
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCheckBoxByStockChecked, setIsCheckBoxByStockChecked] =
    useState(false);

  const shouldClearTableCheckBox = useRef();

  useEffect(() => {
    async function getParentCategories() {
      const categories = await getAllRecords(TABLES.CATEGORIES);
      const categoriesTree = listToTree(categories);
      setCategories(categoriesTree);
    }
    getParentCategories();
  }, []);

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
  };
  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue);
  };

  const onExpand = async (keys, info) => {
    setExpandedKeys(keys);
  };

  const modalBody = (
    <>
      <Checkbox
        ref={shouldClearTableCheckBox}
        id={"clear-table"}
        defaultChecked={true}
      >
        Очистити таблицю перед заповненням
      </Checkbox>
    </>
  );

  const getProductsInStock = (allProductsArraysByCategories) => {
    const productsArraysByCategories = allProductsArraysByCategories.filter(
      (arr) => arr.length !== 0
    );

    const allProductsFromCategories = productsArraysByCategories.reduce(
      (acc, cur) => acc.concat(cur),
      []
    );

    const productsFromCategories = getUniqueArrayOfObjects(
      allProductsFromCategories,
      "guid"
    );

    const productsWithStockQty = productsFromCategories.filter(
      (prod) => prod.stockQty !== 0
    );

    return productsWithStockQty;
  };

  const setProductsToTable = async (products) => {
    const productsWithTimeAdded = getProductsWithTimeAdded(products);
    const sortedProducts = getSortedProductsByTimeAdded(productsWithTimeAdded);
    const sortedProductsWithIndexes = getProductsWithIndexes(sortedProducts);
    await db.CurrentRevision.bulkPut(sortedProductsWithIndexes);
    props.setProducts(sortedProductsWithIndexes);
  };

  const setDataToTable = async () => {
    if (checkedKeys.length === 0 && !isCheckBoxByStockChecked) {
      return message.info("Виберіть категорії!");
    }
    setLoading(true);

    const arrayKeys = isCheckBoxByStockChecked
      ? (await getAllRecords(TABLES.CATEGORIES)).map(
          (category) => category.code
        )
      : checkedKeys;

    const allProductsArraysByCategories = await Promise.all(
      arrayKeys.map(async (key) => {
        const productsFromCategory = await getRecords(
          TABLES.PRODUCTS,
          "parentCode",
          key
        );
        return productsFromCategory;
      })
    );

    const productsWithStockQty = getProductsInStock(
      allProductsArraysByCategories
    );

    setLoading(false);
    if (productsWithStockQty.length === 0) {
      return message.error("Немає товарів у вибраних категоріях!");
    }
    const currentRevisionProducts = await getAllRecords(
      TABLES.CURRENT_REVISION
    );
    if (currentRevisionProducts.length > 0) {
      return confirm({
        title: "У ревізії вже є товари",
        content: modalBody,
        onOk: async () => {
          const shouldClearTable =
            shouldClearTableCheckBox.current.input.checked;
          const productsToTable = shouldClearTable
            ? productsWithStockQty
            : getUniqueArrayOfObjects(
                [...currentRevisionProducts, ...productsWithStockQty],
                "guid"
              );
          try {
            if (shouldClearTable) {
              await db.CurrentRevision.clear();
            }
            await setProductsToTable(productsToTable);
          } catch (error) {
            return message.error("Помилка очищення таблиці!");
          }
          props.setIsModalChooseCategoriesOpen(false);
        },
      });
    }
    // const productsToTable = getRecordsForTable(productsWithStockQty);
    try {
      await setProductsToTable(productsWithStockQty);
      props.setIsModalChooseCategoriesOpen(false);
    } catch (error) {
      return message.error("Помилка запису товарів у таблицю!");
    }
  };

  const onChangeBottomCheckBox = (e) => {
    setIsCheckBoxByStockChecked(e.target.checked);
  };

  const modalFooter = [
    <>
      <Row>
        <Col span={16} style={{ textAlign: "left" }}>
          {categories?.length ? (
            <Checkbox
              checked={isCheckBoxByStockChecked}
              onChange={onChangeBottomCheckBox}
              id="by-stock"
            >
              По залишках
            </Checkbox>
          ) : (
            "Немає доступних категорій"
          )}
        </Col>
        <Col span={8}>
          <Button
            key="ok"
            type="primary"
            loading={loading}
            onClick={() =>
              categories?.length
                ? setDataToTable()
                : props.setIsModalChooseCategoriesOpen(false)
            }
          >
            {categories?.length ? "Гаразд" : "Закрити"}
          </Button>
        </Col>
      </Row>
    </>,
  ];

  return (
    <Modal
      title={"Товари з категорій:"}
      open={props.isModalChooseCategoriesOpen}
      onCancel={() => props.setIsModalChooseCategoriesOpen(false)}
      style={{ top: 10 }}
      afterClose={() => {
        setCheckedKeys([]);
        setIsCheckBoxByStockChecked(false);
      }}
      footer={modalFooter}
    >
      <DirectoryTree
        checkable
        expandedKeys={expandedKeys}
        onCheck={onCheck}
        checkedKeys={checkedKeys}
        style={{ textAlign: "left" }}
        multiple
        defaultExpandParent={false}
        onSelect={onSelect}
        onExpand={onExpand}
        selectedKeys={selectedKeys}
        treeData={categories}
      />
    </Modal>
  );
}

export default ModalFoldersChoosing;
