/**
 * Generates a check digit from a partial EAN13.
 *
 * https://www.gs1.org/services/how-calculate-check-digit-manually
 *
 * @param {string} barcode - 12 digit EAN13 barcode without the check digit
 */
export function checkDigitEAN13(barcode) {
  const sum = barcode
    .split("")
    .map((n, i) => n * (i % 2 ? 3 : 1)) // alternate between multiplying with 3 and 1
    .reduce((sum, n) => sum + n, 0); // sum all values

  const roundedUp = Math.ceil(sum / 10) * 10; // round sum to nearest 10

  const checkDigit = roundedUp - sum; // subtract round to sum = check digit

  return checkDigit;
}
